import * as atlas from 'azure-maps-rest';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {AzureMapsRestService} from '../azure-maps-rest.service';
import SearchFuzzyResponse = atlas.Models.SearchFuzzyResponse;

export const mockFuzzyResponse: SearchFuzzyResponse[] = [
    {
        results: [{
            type: 'POI',
            id: 'NL/POI/p0/348679',
            score: 2.16001,
            info: 'searc:t:528009005792822-NL',
            poi: {
                name: 'Bosdrift'
            },
            address: {
                streetNumber: '96',
                streetName: 'Bosdrift',
                municipalitySubdivision: 'Hilversum',
                municipality: 'Hilversum',
                countrySubdivision: 'Noord-Holland',
                postalCode: '1215',
                extendedPostalCode: '1215AN',
                countryCode: 'NL',
                country: 'Nederland',
                countryCodeISO3: 'NLD',
                freeformAddress: 'Bosdrift 96, 1215AN, Hilversum',
                localName: 'Hilversum'
            },
            position: {
                lat: 52.21281,
                lon: 5.16418
            },
            viewport: {
                topLeftPoint: {
                    lat: 52.21371,
                    lon: 5.16271
                },
                btmRightPoint: {
                    lat: 52.21191,
                    lon: 5.16565
                }
            }
        }],
    }
];

@Injectable()
export class AzureMapsRestServiceMock implements AzureMapsRestService {
    data = mockFuzzyResponse;

    constructor() {
    }

    findFuzzy(addressLin: string, maxResults: number): Observable<SearchFuzzyResponse> {
        return of(this.data[0]);
    }

    reverseSearch(coordinate: atlas.Models.Coordinate): Observable<atlas.Models.SearchAddressReverseResponse> {
        return undefined;
    }
}
