import {Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {AppStoreItem} from '../../models/app-store';
import {ProjectAppStoreItem, SecondaryApplication} from '../../models/application';
import {AppStoreItemService} from '../../services/app-store-item.service';
import {kebabCase} from '../../utils/kebab-case';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../environments/environment';
import {lastValueFrom} from 'rxjs';

@Component({
    selector: 'app-project-app-store-card',
    templateUrl: './project-app-store-card.component.html'
})
export class ProjectAppStoreCardComponent implements OnChanges {
    @Input() app: ProjectAppStoreItem = null;
    @Input() btnLabel = 'Meer info';
    appStoreItem: AppStoreItem | undefined;
    dependencies: AppStoreItem[] = [];
    multipleDependenciesTooltip = '';

    @Output() appClick = new EventEmitter<ProjectAppStoreItem>();

    kebabCase = kebabCase;

    constructor(
        @Inject('AppStoreItemService') private appStoreItemService: AppStoreItemService,
        private toastr: ToastrService,
    ) {}

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.app) {
            this.appStoreItem = await lastValueFrom<AppStoreItem>(this.appStoreItemService.getSingleRelation(this.app._links.appStoreItem));

            if (this.appStoreItem) {
                this.dependencies = await lastValueFrom<AppStoreItem[]>(this.appStoreItemService.getCollectionRelation(this.appStoreItem._links.dependencies, 'app-store-items'));
            }
        }
    }

    showOpenAppButton() {
        return this.app.type.match(/(trimbleConnect|bouwpas|teams|projectRisicoMonitor|projectSight)/) || this.app.secondaryType === 'Addon';
    }

    openApp(app: ProjectAppStoreItem) {
        if (app.type === 'trimbleConnect') window.open(`https://web.connect.trimble.com/projects/${app.applicationNumber}`, '_blank');
        if (app.type === 'bouwpas') window.open(`${environment.bouwpasUrl}${app.applicationNumber}`, '_blank');
        if (app.type === 'teams') {
            lastValueFrom<string>(this.appStoreItemService.getTeamsUrl(this.app.applicationNumber)).then( result => {
                if (result === null || result.length === 0) {
                    this.toastr.warning('Geen Teams web url gevonden voor id ' + this.app.applicationNumber + '!')
                } else {
                    window.open(result)
                }
            }).catch(error => {
                    console.error('Error occurred while retrieving teams web url', error);
                    this.toastr.error('Fout opgetreden tijdens ophalen Teams Web URL');
                }
            )
        }
        if (app.type === 'projectRisicoMonitor') window.open(`${environment.prmUrl}${app.applicationNumber}/step/1`, '_blank');
        if (app.type === 'projectSight') window.open(`${environment.projectSightUrl}?orgid=${app.portfolioId}&projid=${app.applicationNumber}`, '_blank');
        if (app.secondaryType === 'Addon') {
            const secApp = this.app as SecondaryApplication;
            window.open(`${secApp.url}`, '_blank');
        }
    }
}
