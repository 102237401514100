import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-external-redirect',
    template: '',
})
export class OpenTableauTabComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit(): void {
        // Redirect to the external URL
        window.open('https://tableau.volkerwessels.com/#/views/BICT14-ProjectenPortaal/ProjectenPortaal?:iid=1', '_blank');
    }
}
