import {Component, Input} from '@angular/core';
import {appStoreItemStatusTranslation} from '../../models/app-store-item-status';

@Component({
    selector: 'app-app-store-status-label',
    templateUrl: './app-store-status-label.component.html'
})
export class AppStoreStatusLabelComponent {
    @Input() status: string;

    readonly appStatusTranslation = appStoreItemStatusTranslation;
}
