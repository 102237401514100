import {forkJoin, from, Observable} from 'rxjs';
import {TemplateResponse} from '../models/template-response';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BouwpasContractor} from '../models/bouwpas-contractor';
import {concatMap, map} from 'rxjs/operators';

export interface TemplateService {
    getTemplates(kvwNumbers: Array<string>): Observable<TemplateResponse>;
}

@Injectable()
export class TemplateServiceImpl implements TemplateService {

    public constructor(private http: HttpClient) {}

    getTemplates(kvwNumbers: string[] | null): Observable<TemplateResponse> {
        if (kvwNumbers && kvwNumbers.length > 0) {
            const observables: Observable<TemplateResponse>[] = kvwNumbers.map((kvwNumber) => {
                const params = { folderName: kvwNumber };
                return this.http.get<TemplateResponse>(`/api/v1/templates`, { params });
            });
            return from(observables).pipe(
                concatMap((observable) => observable)
            );
        } else {
            return new Observable<TemplateResponse>(observer => {
                observer.next();
                observer.complete();
            });
        }
    }
}
