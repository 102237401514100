import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {ProjectSightService} from '../project-sight.service';
import {ProjectSightPortfolio} from '../../models/project-sight.portfolio';

@Injectable()
export class ProjectSightServiceMock implements ProjectSightService {

    getPortfolios(projectId: number): Observable<ProjectSightPortfolio[]> {
        return of([
            {
                id: 'b288604a-4961-4fdf-b3fc-eb99c1c4309b',
                name: 'ProjectSight Portfolio Koenen',
                users: [
                    {
                        id: 'piet@gmail.com',
                        name: 'Piet Koenen',
                    }
                ]
            },
            {
                id: 'edc70c79-f028-4257-bd9d-e5b1649ec00c',
                name: 'Portfolio 2 ETC Koenen',
                users: [
                    {
                        id: 'piet@gmail.com',
                        name: 'Piet Koenen',
                    }
                ]
            }
        ])
    }
}
