import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MigrationService {
    constructor(protected http: HttpClient) {
    }

    migrateCompanyNames() {
        return this.http.post<string>('/api/v1/migrations/company-names', null, {responseType: 'text' as 'json'});
    }
}
