import {Component, Inject} from '@angular/core';
import {AbstractControl, FormControlStatus, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {VwuiModalRef} from '@recognizebv/vwui-angular';
import {AppStoreItemService} from '../../services/app-store-item.service';
import {lastValueFrom, of, timer} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import {AppStoreItem} from '../../models/app-store';

@Component({
    selector: 'app-app-store-modal',
    templateUrl: './app-store-modal.component.html'
})
export class AppStoreModalComponent {
    form = new UntypedFormGroup({
        title: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)], [this.validateInternalTitleNotTaken.bind(this)])
    });
    submitPending = false;

    constructor(
        @Inject('AppStoreItemService') private appStoreItemService: AppStoreItemService,
        private toast: ToastrService,
        public modalRef: VwuiModalRef,
    ) {}

    async createApp() {
        if (this.form.pending) {
            await lastValueFrom<FormControlStatus>(this.form.statusChanges.pipe(
                filter(status => status !== 'PENDING'),
                take(1)
            ));
        } else if (!this.form.valid) {
            this.form.markAllAsTouched();
            if (this.form.get('title').hasError('required')) {
                this.toast.error('Titel is verplicht');
            }
            throw new Error('Couldn\'t save form, it\'s invalid');
        } else if (this.submitPending) {
            throw new Error('Couldn\'t save form, it\'s pending a submission');
        }

        try {
            this.submitPending = true;
            const createdApp = await lastValueFrom<AppStoreItem>(this.appStoreItemService.create({
                ...this.form.value,
                internalTitle: this.form.controls.title.value.toLowerCase(),
                secondaryType: 'Extension',
                type: 'Secondary'
            }));

            this.modalRef.close(createdApp);
        } catch (e) {
            if (e instanceof HttpErrorResponse && e.status === 400 && e.error.errors[0].message === 'Already in use') {
                this.toast.error('Er bestaat al een app met deze interne titel')
            } else {
                console.error('createApp error', e);
                this.toast.error('Applicatie toevoegen mislukt')
            }
        } finally {
            this.submitPending = false;
        }
    }

    private validateInternalTitleNotTaken(control: AbstractControl) {
        return timer(300).pipe(
            switchMap(() => {
                if (!control.value) {
                    return of(null);
                }

                return this.appStoreItemService.existsByInternalTitle(control.value.toLowerCase())
            }),
            map(exists => exists ? {internalTitleExists: true} : null)
        );
    }
}
