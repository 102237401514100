import {ProjectAttributeValue} from '../models/mdm/project-attribute';
import {map} from 'rxjs/operators';
import {ProjectAttributeResponse} from '../models/mdm/project-attribute-response';
import {Observable} from 'rxjs';

export function unwrapProjectAttributeResponse() {
    return (source: Observable<ProjectAttributeResponse>) => source.pipe(
        map((response: ProjectAttributeResponse) => response.projectkenmerken[0].waarden ?? [] as ProjectAttributeValue[]),
    )
}

export function transformProjectAttributesToSelectOptions() {
    return (source: Observable<ProjectAttributeValue[]>) => source.pipe(
        map(it => sortDataByParentChildRelation(it)),
    );
}

export function transformProjectUsageFunctionsToSelectOptions() {
    return (source: Observable<ProjectAttributeValue[]>) => source.pipe(
        map((projectAttributes) => projectAttributes.sort((a, b) => a.volgorde - b.volgorde)),
        map((projectAttributes) => projectAttributes.map(it => ({key: it.id, value: it.waarde})))
    );
}

export function transformProjectSubUsageFunctionsToSelectOptions() {
    return (source: Observable<ProjectAttributeValue[]>) => source.pipe(
        map((projectAttributes) => projectAttributes.sort((a, b) => a.waarde.localeCompare(b.waarde))),
        map((projectAttributes) => projectAttributes.map(it => ({key: it.id, value: it.waarde})))
    );
}

export function transformProjectUsageFunctionResponseToSelectOptions() {
    return (source: Observable<ProjectAttributeResponse>) => source.pipe(
        unwrapProjectAttributeResponse(),
        transformProjectUsageFunctionsToSelectOptions(),
    );
}

export function transformProjectAttributeResponseToSelectOptions() {
    return (source: Observable<ProjectAttributeResponse>) => source.pipe(
        unwrapProjectAttributeResponse(),
        transformProjectAttributesToSelectOptions(),
    );
}

function sortDataByParentChildRelation(data: ProjectAttributeValue[]): {key: string, value: string}[] {
    const sortedList = [];
    const parentIdToChild = {};

    data.forEach(item => {
        if (item.parent && item.parent.length > 0) {
            item.parent.forEach(parentId => {
                if (!parentIdToChild[parentId]) {
                    parentIdToChild[parentId] = [];
                }
                parentIdToChild[parentId].push(item);
            });
        }
    });

    function sortByVolgorde(a: ProjectAttributeValue, b: ProjectAttributeValue) {
        return a.volgorde - b.volgorde;
    }

    function addItemToList(item: ProjectAttributeValue) {
        if (parentIdToChild[item.id]) {
            parentIdToChild[item.id].sort(sortByVolgorde).forEach(child => {
                addItemToList({...child, waarde: `${item.waarde} - ${child.waarde}`});
            });
        } else {
            if (item.geldig_tot === null || item.geldig_tot >= new Date(Date.now())) {
                sortedList.push({key: item.id, value: item.waarde});
            }
        }
    }

    data
        .filter(item => !item.parent || item.parent.length === 0)
        .sort(sortByVolgorde)
        .forEach(item => {
            addItemToList(item);
        });

    return sortedList;
}
