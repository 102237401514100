import {Component, Inject} from '@angular/core';
import {ProjectLabelService} from '../../services/project-label.service';
import {BehaviorSubject, combineLatest, lastValueFrom} from 'rxjs';
import {mapTo, shareReplay, switchMap} from 'rxjs/operators';
import {Paginator} from '../../utils/paginator';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {ProjectLabelModalComponent} from '../../components/project-label-modal/project-label-modal.component';
import {ProjectLabel} from '../../models/project-label';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-project-labels',
    templateUrl: './project-labels.component.html'
})
export class ProjectLabelsComponent {
    modalOpen: boolean;

    refresh$ = new BehaviorSubject<void>(null);

    paginator$ = combineLatest([
        this.refresh$,
    ]).pipe(
        switchMap(([_]) => {
            const paginator = new Paginator(page => {
                return this.labelService.findAll({
                        page: `${page}`
                    }, {
                        field: 'name',
                        direction: 'asc'
                    }
                );
            });
            /** Wait for first page of results before returning the paginator. This prevents flash of empty content */
            return paginator.content$.pipe(
                mapTo(paginator)
            );
        }),
        shareReplay(1)
    );

    constructor(
        @Inject('ProjectLabelService') private labelService: ProjectLabelService,
        private modalService: VwuiModalService,
        private toastr: ToastrService,
    ) {
    }

    openLabelModal(label?: ProjectLabel) {
        this.modalOpen = true;
        const modal = this.modalService.open(ProjectLabelModalComponent, {data: {label}});

        modal.afterClosed.subscribe(it => {
            this.modalOpen = false;
            this.refresh$.next(null);
        }, () => {
            this.modalOpen = false;
        });
    }

    async delete(label: ProjectLabel) {
        try {
            await lastValueFrom<void>(this.labelService.delete(label));
            this.toastr.success('Projectlabel verwijderd');
        } catch (ex) {
            console.error('Error while deleting projectlabel', ex);
            this.toastr.error('Verwijderen mislukt');
        }
        this.refresh$.next(null);
    }
}
