import {combineLatest, Observable, of} from 'rxjs';
import {catchError, concatMap, map, retry, scan, switchMap, takeWhile} from 'rxjs/operators';
import {PageResponse} from '../models/page-response';

export function filteredInfiniteScrollObservable<T, F>(
    filterParams: Observable<F>,
    loadMoreSubject: Observable<any>,
    loadFunction: (page: number, filter: F) => Observable<PageResponse<T>>
): Observable<T[]> {
    return filterParams.pipe(
        switchMap((switchParams: any) => {
            return combineLatest([of(switchParams), loadMoreSubject]).pipe(
                map<[F, void], [F, number]>(([params]) => [params, 0]),
                scan<[F, number]>(([previousParams, previousPage], [currentParams, _]) => [currentParams, previousPage + 1]),
                concatMap<[F, number], Observable<PageResponse<T>>>(([params, page]) => loadFunction(page, params)),
                retry(3), // Retry retrieving at most 3 times
                takeWhile<PageResponse<T>>(pageResponse => {
                    // Take data while last page hasn't been reached yet
                    return pageResponse.page.number === 0 || pageResponse.page.number + 1 < pageResponse.page.totalPages;
                }, true),
                // Only return items, not the other paginated properties
                map<PageResponse<T>, T[]>((it) => it.content),
                scan<T[], T[]>((acc, items) => [...acc, ...items], []),
            );
        }),
        catchError((err) => {
            // Catch error to prevent observable finishing / error-ing
            console.error('Failed retrieving data:', err);
            return of([]);
        }),
    );
}

export function nonPaginatedFilteredInfiniteScrollObservable<T, F>(
    filterParams: Observable<F>,
    loadMoreSubject: Observable<any>,
    loadFunction: (page: number, filter: F) => Observable<T[]>
): Observable<T[]> {
    return filterParams.pipe(
        switchMap((switchParams: any) => {
            return combineLatest([of(switchParams), loadMoreSubject]).pipe(
                map<[F, void], [F, number]>(([params]) => [params, 0]),
                scan<[F, number]>(([previousParams, previousPage], [currentParams, _]) => [currentParams, previousPage + 1]),
                concatMap<[F, number], Observable<T[]>>(([params, page]) => loadFunction(page, params)),
                retry(3), // Retry retrieving at most 3 times
                takeWhile<T[]>(pageResponse => {
                    // Take data until the list is empty
                    return pageResponse.length > 0;
                }, true),
                scan<T[], T[]>((acc, items) => [...acc, ...items], []),
            );
        }),
        catchError((err) => {
            // Catch error to prevent observable finishing / error-ing
            console.error('Failed retrieving data:', err);
            return of([]);
        }),
    );
}
