export function dateFormat(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    };

    return date.toLocaleDateString('nl-NL', options);
}

export function dateFormatFromString(date: string): string {
    if (date === 'null' || date === '') {
        return ''
    }
    const formattedDate = new Date(date.split('[')[0]);
    return dateFormat(formattedDate);
}
