import {Component, forwardRef, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ImageInputValue} from '@recognizebv/vwui-core';
import {ImageCropModalComponent} from '../image-crop-modal/image-crop-modal.component';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {ImageService} from '../../services/image.service';
import {ToastrService} from 'ngx-toastr';
import {RestLink} from '../../models/rest/rest-link';
import {Image} from '../../models/image';
import {awaitModalClosed} from '../../utils/modal';
import {base64ToFile, ImageCroppedEvent} from 'ngx-image-cropper';
import {Project} from '../../models/project';
import {ProjectService} from '../../services/project.service';
import {AuthorizationService} from '../../services/authorization.service';
import {lastValueFrom, Subscription} from 'rxjs';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageUploadComponent),
            multi: true
        }
    ],
})
export class ImageUploadComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() project: Project;
    disabled = false;
    value: RestLink<Image> = null;
    imageControl: UntypedFormControl;

    private onChange: (value: RestLink<Image>) => void;
    private onTouched: () => void;
    private subscriptions: Subscription[] = [];

    constructor(
        @Inject('ImageService') private imageService: ImageService,
        @Inject('ProjectService') private projectService: ProjectService,
        @Inject('AuthorizationService') private authorizationService: AuthorizationService,
        private toastr: ToastrService,
        private httpClient: HttpClient,
        private modalService: VwuiModalService,
    ) {
    }

    ngOnInit(): void {
        this.imageControl = new UntypedFormControl(null);

        this.subscriptions.push(
            this.authorizationService.canUpdateDeleteProject$(this.project).subscribe(canUpdateOrCreate => {
                if (!canUpdateOrCreate) {
                    this.imageControl.disable();
                }
            })
        );

        this.imageControl.valueChanges.subscribe((value: ImageInputValue) => {
            if (value) {
                this.openImageCrop(value.file);
            } else {
                this.onChange(null);
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    registerOnChange(fn: any): void {
        this.onChange = (imageLink) => {
            this.updatePreview(imageLink);
            fn(imageLink);
        };
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (isDisabled) {
            this.imageControl.disable();
        } else {
            this.imageControl.enable();
        }
    }

    writeValue(imageLink: RestLink<Image>): void {
        if (this.value) {
            window.URL.revokeObjectURL(this.value.href);
        }

        this.value = imageLink;
        this.updatePreview(imageLink);
    }

    clear() {
        this.value = null;
        this.onTouched();
        this.onChange(this.value);
    }

    async openImageCrop(imageFile: File) {
        const modal = this.modalService.open(ImageCropModalComponent, {data: {imageFile}});

        const cropResult = await awaitModalClosed<ImageCroppedEvent>(modal);
        if (cropResult.base64) {
            this.uploadImage(base64ToFile(cropResult.base64));
        }
    }

    async uploadImage(imageFile: File | Blob) {
        try {
            const fileUpload = await this.imageService.postImage(imageFile);
            this.onChange(fileUpload._links.self);

            if (fileUpload._links.self.href !== this.project._links.image.href) {
                await lastValueFrom<RestLink<Image>>(this.projectService.replaceRelation(this.project._links.image, fileUpload._links.self));
            }

        } catch (error) {
            console.error(error);
            this.imageControl.setValue(null);
            this.toastr.error('Uploaden van de afbeelding is mislukt.');
        }
    }

    private async updatePreview(imageLink: RestLink<Image>) {
        try {
            if (imageLink) {
                const image = await lastValueFrom<Image>(this.imageService.getImage(imageLink));
                const imageData = await lastValueFrom<Blob>(this.imageService.getImageData(image));

                this.imageControl.setValue({
                    ...this.imageControl.value,
                    previewSrc: window.URL.createObjectURL(imageData)
                }, {emitEvent: false});
            } else {
                this.imageControl.setValue(null, {emitEvent: false});
            }
        } catch (e) {
            if (e instanceof HttpErrorResponse && e.status === 404) {
                return;
            }
            throw e;
        }
    }
}
