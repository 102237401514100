import {Component, EventEmitter, Inject, Input, OnDestroy, Output} from '@angular/core';
import {Project} from '../../models/project';
import {BehaviorSubject, combineLatest, Observable, of, Subscription} from 'rxjs';
import {catchError, map, shareReplay, skip, switchMap} from 'rxjs/operators';
import {ProjectService} from '../../services/project.service';
import {ProjectHistory} from '../../models/projectHistory';
import {projectHistoryPropertyTranslations} from '../../models/projectHistoryPropertyTranslations';
import {createEmptyPageResponse} from '../../models/page-response';
import {InfiniteScrollPaginator} from '../../utils/infinite-scroll-paginator';
import {dateFormat, dateFormatFromString} from '../../utils/date';

@Component({
    selector: 'app-project-history-tab',
    templateUrl: './project-history-tab.component.html'
})
export class ProjectHistoryTabComponent implements OnDestroy {
    @Input() readonly = false
    @Output() projectUpdated = new EventEmitter<void>();

    project$ = new BehaviorSubject<Project>(null);
    refresh$ = new BehaviorSubject<void>(null);

    logs$: Observable<InfiniteScrollPaginator<ProjectHistory>> = combineLatest([
        this.project$,
        this.refresh$,
    ]).pipe(
        switchMap(([project]) => {

            const paginator = new InfiniteScrollPaginator(page => {
                return this.projectService.history(
                    project,
                    page
                ).pipe(
                    catchError(() => of(createEmptyPageResponse<ProjectHistory>())),
                )
            });

            /** Wait for first page of results before returning the paginator. This prevents flash of empty content */
            return paginator.content$.pipe(
                map(() => paginator)
            );
        }),
        shareReplay(1)
    );

    subscription: Subscription[];

    constructor(
        @Inject('ProjectService') private projectService: ProjectService,
    ) {
        this.subscription = [
            this.refresh$.pipe(
                skip(1),
            ).subscribe(_ => this.projectUpdated.emit())
        ];
    }

    ngOnDestroy(): void {
        this.subscription.forEach(sub => sub.unsubscribe());
    }

    @Input()
    set project(project: Project) {
        this.project$.next(project);
    }

    propertyValueTranslation(value: string, field: string,): string {
        switch (field) {
            case 'endDate':
            case 'startDate':
                return dateFormatFromString(value);
            default:
                switch (value) {
                    case 'null':
                        return ''
                    default:
                        return value;
                }
        }
    }

    translationFieldName(fieldName: string): string {
        return projectHistoryPropertyTranslations[fieldName] ?? fieldName;
    }

    protected readonly dateFormat = dateFormat;
}
