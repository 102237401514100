import {RestCollection} from './rest/rest-collection';

export interface PageResponse<T> {
    content: T[];
    page: RestCollection<T>['page'];
}

export interface PageableResponse<T> {
    content: T[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    pageable: Pageable;
    size: number;
    sort: Sort;
    totalElements: number;
    totalPages: number;
}

export interface Pageable {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: Sort;
    unpaged: boolean;
}

export interface Sort {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
}

export function createEmptyPageResponse<T>(): PageResponse<T> {
    return {
        content: [],
        page: {
            number: 0,
            size: 0,
            totalElements: 0,
            totalPages: 1
        }
    };
}

export function createPageResponseOf<T>(content: T[]): PageResponse<T> {
    return {
        content,
        page: {
            number: 0,
            size: 0,
            totalElements: 0,
            totalPages: 1
        }
    };
}

export function PageableToPage<T>(response: PageableResponse<T>): RestCollection<T>['page'] {
    return {
        size: response.pageable.pageSize,
        totalElements: response.totalElements,
        totalPages: response.totalPages,
        number: response.number,
    };
}
