import {Component} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';

@Component({
  selector: 'app-delete-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent {
    title: string;
    description: string;
    confirmButton: string;

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{title: string, description: string, confirmButton: string}>
    ) {
        this.title = modalConfig.data.title;
        this.description = modalConfig.data.description;
        this.confirmButton = modalConfig.data.confirmButton;
    }

    async confirm() {
        try {
            this.modalRef.close(true);
        } catch (e) {
            console.error('Deleting project failed: ', e);
        }
    }
}
