import {Injectable} from '@angular/core';
import {GraphService} from '../graph.service';
import {Observable, of} from 'rxjs';
import {GraphUsersPageResponse} from '../../models/graph-users-page-response';
import {GraphCompanyDetailsResponse} from '../../models/company-page-response';

export const mockGraphUsersPageResponse: GraphUsersPageResponse = {
    value: [
        {
            businessPhones: ['0615667624'],
            companyName: 'Recognize',
            displayName: 'Helder Goncalves',
            userPrincipalName: 'h.goncalves@recognize.nl'
        },
        {
            businessPhones: ['0612345678'],
            companyName: 'Recognize',
            displayName: 'Willem Slaghekke',
            userPrincipalName: 'w.slaghekke@recognize.nl'
        }
    ]
};

export const mockGraphCompanyDetailsResponse: GraphCompanyDetailsResponse = {
    name: 'Recognize',
    kvwNumbers: ["1234", "1235"]
};

@Injectable()
export class GraphServiceMock implements GraphService {
    companyDetails = mockGraphCompanyDetailsResponse;
    userData = mockGraphUsersPageResponse;

    constructor() {
    }

    findUsers(name: string): Observable<GraphUsersPageResponse> {
        return of(this.userData);
    }

    findUsersFromNextLink(url: string): Observable<GraphUsersPageResponse> {
        return of(this.userData);
    }

    getCompanyDetails(): Observable<GraphCompanyDetailsResponse> {
        return of({...this.companyDetails});
    }
}
