<vwui-modal-header>Afbeelding bijsnijden</vwui-modal-header>
<vwui-modal-body>
    <image-cropper
        [maintainAspectRatio]="true"
        [aspectRatio]="1"
        [imageFile]="imageFile"
        [format]="format"
        (loadImageFailed)="imageLoadingFailed()"
    ></image-cropper>
</vwui-modal-body>
<vwui-modal-footer>
    <vwui-button class="is-light" (click)="modalRef.close()">Annuleren</vwui-button>
    <vwui-button class="is-secondary" (click)="finish()">Opslaan</vwui-button>
</vwui-modal-footer>
