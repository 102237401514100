import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {EMPTY} from 'rxjs';
import {NavisionApplicationService} from '../navision-application.service';
import {NAVApplication} from '../../models/application';

@Injectable()
export class NavisionApplicationServiceMock implements NavisionApplicationService {
    constructor(protected httpClient: HttpClient) {
    }

    remove(app: NAVApplication) {
        return EMPTY;
    }
}
