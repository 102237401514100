<vwui-header>
    App Beheer
    <vwui-button *ngIf="canCreateProject$ | async" slot="action" icon="vwui-add" class="mt-1"
                 [disabled]="modalOpen"
                 (click)="openAppStoreModal()">Applicatie toevoegen
    </vwui-button>
</vwui-header>

<ng-container *ngIf="paginator$ | async as paginator">
    <ng-container *ngIf="paginator.content$ | async as content">
        <vwui-grid [fillItems]="true" *ngIf="content.length > 0; else emptyList">
            <h3 slot="grid-title">
                Applicaties
                <vwui-badge class="grid-badge">{{paginator.totalElements}}</vwui-badge>
            </h3>

            <app-pagination slot="grid-pagination" [paginator]="paginator"></app-pagination>
            <app-app-store-card *ngFor="let item of content" [app]="item"
                                (appClick)="navigateToAppDetail($event)"></app-app-store-card>
        </vwui-grid>
    </ng-container>
</ng-container>

<ng-template #emptyList>
    <vwui-empty-state messageTitle="Applicaties toevoegen"
                      message="Er zijn nog geen applicaties toegevoegd aan de AppStore">
        <vwui-button *ngIf="canCreateProject$ | async" slot="button" icon="vwui-add"
                     (click)="openAppStoreModal()">Applicatie toevoegen
        </vwui-button>
    </vwui-empty-state>
</ng-template>
