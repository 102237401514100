import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {AuthorizationService} from './authorization.service';

@Injectable()
export class AuthorizationGuardService implements CanActivate {

    constructor(
        @Inject('AuthorizationService') private authorizationService: AuthorizationService
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        try {
            return true;
        } catch (e) {
            console.error('Error in AuthorizationGuardService occurred', e);
        }

        return false;
    }
}
