import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';

export interface SearchFacet {
    key: string,
    value: string,
}

export interface SearchFacetGroupCollection {
    title: string,
    items: SearchFacetGroup[],
}

export interface SearchFacetGroup {
    field: string,
    title: string,
    options: SearchFacet[],
    default: SearchFacet[] | null,
}

@Component({
    selector: 'app-project-list-filter',
    templateUrl: './project-list-filter.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProjectListFilterComponent),
            multi: true
        }
    ]
})
export class ProjectListFilterComponent implements ControlValueAccessor, OnInit {
    @Input() facets$: Observable<SearchFacetGroupCollection[]>
    @Input() defaultFiltersApplied$: BehaviorSubject<boolean>

    numFilters$ = new BehaviorSubject<number>(0);
    form = new FormGroup({});
    overlayOpen = false;
    private lastWrittenValue: { [key: string]: string[] } = {};
    private onChange: (value: { [key: string]: string[] }) => void = () => {
    };
    private onTouched: () => void = () => {
    };

    constructor() {
        this.form.valueChanges.subscribe(value => {
            this.onChange(value);
            this.onTouched();
            this.numFilters$.next(this.countFilters());
        });
    }

    ngOnInit(): void {
        this.facets$.subscribe(facets => {
            // Remove facets not available in new facets from form
            Object.keys(this.form.controls)
                .filter(key => -1 === facets.findIndex(collection => collection.items.find(facet => facet.field === key)))
                .forEach(key => this.form.removeControl(key))

            facets.forEach(collection => {
                collection.items.forEach(facet => {
                    if (!this.form.contains(facet.field)) {
                        const value = facet.default.map((f) => f.key);
                        this.form.registerControl(facet.field, new FormControl(
                            this.lastWrittenValue[facet.field] || value
                        ))
                        this.writeValue({[facet.field]: value})
                    }
                })
            })

            this.onChange(this.form.value)
            this.onTouched()
            this.numFilters$.next(this.countFilters())
            this.defaultFiltersApplied$.next(true)
        })
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.lastWrittenValue = obj;
        this.form.patchValue(obj, {emitEvent: false});
    }

    countFilters(): number {
        return Object.values<string[]>(this.form.value).filter((value) => value.length > 0).length
    }

    resetFilters() {
        window.location.href = '/projects'
    }
}
