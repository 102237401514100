import {ItemSelectComponent} from './item-select.component';
import {Component, EventEmitter, forwardRef, Inject, Input, Output} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {createPageResponseOf, PageResponse} from '../../models/page-response';
import {TemplateService} from '../../services/template.service';
import {catchError, filter as rxFilter, map, switchMap, tap} from 'rxjs/operators';
import {Project} from '../../models/project';

@Component({
    selector: 'app-template-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TemplateSelectComponent),
            multi: true
        }
    ]
})
export class TemplateSelectComponent extends ItemSelectComponent<string, {}> {

    // tslint:disable-next-line:variable-name
    private _project: Subject<Project> = new BehaviorSubject<Project>(null);
    @Output() receivedTemplates = new EventEmitter<string[]>();

    constructor(
        @Inject('TemplateService') private templateService: TemplateService
    ) {
        super();

        this.placeholder = 'Selecteer een template';
    }

    @Input() set project(project: Project) {
        this._project.next(project);
    }

    getFilter(): Observable<{}> {
        return of({});
    }

    getLabelText(item: string): string {
        return item === 'default' ? 'BVGO Aannemers' : item;
    }

    loadMorePages(page: number, filter: { term: string }): Observable<PageResponse<string>> {
        return this._project.pipe(
            rxFilter(value => value !== null),
            switchMap((project) => {
                return this.templateService.getTemplates([project.kvwNumber].concat(project.bouwcombinatieKvwNumbers)).pipe(
                    map(response => response.availableTemplates),
                    catchError((error) => {
                        console.error('Error occurred while retrieving templates', error);

                        return of(['default']);
                    })
                );
            }),
            tap(templates => this.receivedTemplates.emit(templates)),
            map(templates => createPageResponseOf(templates))
        );
    }
}
