import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Project} from '../../models/project';
import {ProjectService} from '../project.service';
import {AbstractRestServiceMock} from './abstract-rest.service.mock';
import {RestHttpParams, SortParams} from '../abstract-rest.service';
import {createPageResponseOf, PageResponse} from '../../models/page-response';
import {userPreference} from './project-user-preferences.service.mock';
import {mockProjects} from './data/projects';
import {AppStoreItem} from 'src/app/models/app-store';
import {CheckDependenciesResponse} from '../../models/check-dependencies-response';
import {SearchFacetGroupCollection} from '../../components/project-list-filter/project-list-filter.component';
import {map} from 'rxjs/operators';
import {ProjectLabel} from '../../models/project-label';
import {ProjectHistory} from '../../models/projectHistory';

@Injectable()
export class ProjectServiceMock extends AbstractRestServiceMock<Project> implements ProjectService {
    constructor() {
        super(mockProjects);
    }

    //todo
    history(project: Project): Observable<PageResponse<ProjectHistory>> {
        throw new Error('Method not implemented.');
    }

    checkDependencies(project: Project, app: AppStoreItem): Observable<CheckDependenciesResponse> {
        return of({satisfied: app.internalTitle !== 'dependencyCheckFailed', unmetDependencies: []});
    }

    create(createItem: Pick<Project, any>): Observable<Project> {
        return of(this.data[0]);
    }

    searchProjects(searchFunction: string, params?: RestHttpParams, sort?: SortParams): Observable<PageResponse<Project>> {
        if (searchFunction === 'findRecent') {
            return of(createPageResponseOf(this.data));
        }

        const filter = params.filter as any;
        const search = filter.search ? filter.search[0] : ''

        let projects = this.filterProjectsBySearchTerm(search);

        const status = filter.status || [];
        const finalized = status.includes('finalized')
        if (status.length === 1) {
            projects = projects.filter(item => item.finalized === finalized);
        }

        const visibility = filter.visibility || [];
        const hidden = visibility.includes('hidden')
        if (visibility.length === 1) {
            projects = projects.filter(item => item.hidden === hidden);
        }

        return of(createPageResponseOf(projects));
    }

    findRecent(params?: RestHttpParams, sort?: SortParams): Observable<PageResponse<Project>> {
        return this.searchProjects('findRecent', params, sort).pipe(
            map(it => ({
                ...it,
                content: it.content
            }))
        );
    }

    findProjectsWithCompany(page: number, filter: object, sort?: SortParams): Observable<PageResponse<Project>> {
        const active = (filter as any).active !== 'false';
        const inactive = (filter as any).inactive === 'true'
        const search: string = (filter as any).search ? (filter as any).search[0] : ''

        return this.searchProjects('findProjectsWithCompany', {filter: {search, ...filter} as any as string}, sort).pipe(
            map(it => ({
                ...it,
                content: it.content.filter(item => {
                    return userPreference === undefined && active
                        || userPreference.project.id !== item.id && active
                        || (userPreference.project.id === item.id && userPreference.hidden === inactive)
                })
            }))
        );
    }

    facets(): Observable<SearchFacetGroupCollection[]> {
        const facets = [
            {
                title: 'Applicaties',
                items: [
                    {
                        field: 'projectType',
                        title: 'Werkmethode',
                        options: [
                            {
                                key: 'Demolition',
                                value: 'Sloop'
                            },
                            {
                                key: 'Expansion',
                                value: 'Uitbreiding'
                            },
                            {
                                key: 'Maintenance',
                                value: 'Onderhoud'
                            },
                            {
                                key: 'NewConstruction',
                                value: 'Nieuwbouw'
                            },
                            {
                                key: 'Renovation',
                                value: 'Renovatie'
                            },
                            {
                                key: 'Restoration',
                                value: 'Restauratie'
                            },
                            {
                                key: 'Transformation',
                                value: 'Herbestemming / Transformatie'
                            },
                            {
                                key: 'Other',
                                value: 'Overige / Diverse en aanverwanten'
                            }
                        ],
                        default: []
                    },
                    {
                        field: 'operationalProjectStatus',
                        title: 'Operationele projectstatus',
                        options: [
                            {
                                key: 'Acquisitie',
                                value: 'Acquisitie'
                            },
                            {
                                key: 'In_ontwikkeling',
                                value: 'In ontwikkeling'
                            },
                            {
                                key: 'In_voorbereiding',
                                value: 'In voorbereiding'
                            },
                            {
                                key: 'In_uitvoering',
                                value: 'In uitvoering'
                            },
                            {
                                key: 'Opgeleverd',
                                value: 'Opgeleverd'
                            },
                            {
                                key: 'In_onderhoud',
                                value: 'In onderhoud'
                            }
                        ],
                        default: []
                    },
                    {
                        field: 'status',
                        title: 'Status',
                        options: [
                            {
                                key: 'active',
                                value: 'Actief'
                            },
                            {
                                key: 'finalized',
                                value: 'Afgesloten'
                            }
                        ],
                        default: [
                            {
                                key: 'active',
                                value: 'Actief'
                            }
                        ]
                    },
                    {
                        field: 'visibility',
                        title: 'Zigtbaarheid',
                        options: [
                            {
                                key: 'visible',
                                value: 'Zichtbaar'
                            },
                            {
                                key: 'hidden',
                                value: 'Verborgen'
                            }
                        ],
                        default: [
                            {
                                key: 'visible',
                                value: 'Zichtbaar'
                            }
                        ]
                    },
                    {
                        field: 'labels',
                        title: 'Labels',
                        options: [],
                        default: []
                    },
                    {
                        field: 'connectedApps',
                        title: 'Applicaties gekoppeld',
                        options: [
                            {
                                key: '1',
                                value: 'Trimble Connect'
                            },
                            {
                                key: '2',
                                value: 'Navision'
                            },
                            {
                                key: '3',
                                value: 'Teams'
                            },
                            {
                                key: '5',
                                value: 'Bouwpas'
                            },
                            {
                                key: '6',
                                value: 'Project Risico Monitor'
                            }
                        ],
                        default: []
                    },
                    {
                        field: 'unconnectedApps',
                        title: 'Applicaties niet gekoppeld',
                        options: [
                            {
                                key: '1',
                                value: 'Trimble Connect'
                            },
                            {
                                key: '2',
                                value: 'Navision'
                            },
                            {
                                key: '3',
                                value: 'Teams'
                            },
                            {
                                key: '5',
                                value: 'Bouwpas'
                            },
                            {
                                key: '6',
                                value: 'Project Risico Monitor'
                            }
                        ],
                        default: []
                    }
                ]
            }
        ];
        return of(facets);
    }

    getByApplicationNumber(id: number): Observable<Project> {
        console.log('getByApplicationNumber', id)
        return of(mockProjects[0]);
    }

    deleteLabel(project: Project): Observable<Project> {
        return of(project);
    }

    replaceLabel(project: Project, label: ProjectLabel): Observable<Project> {
        return of(project);
    }

    private filterProjectsBySearchTerm(search) {
        return this.data.filter(item =>
            item.name.toLowerCase().includes(search) ||
            item.city.toLowerCase().includes(search) ||
            item.companyName.toLowerCase().includes(search) ||
            item.code.toLowerCase().includes(search));
    }
}
