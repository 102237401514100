import {forkJoin, Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BouwpasTemplate} from '../models/bouwpas-template';
import {BouwpasContractor} from '../models/bouwpas-contractor';
import {map} from 'rxjs/operators';

export interface BouwpasService {
    getContractorsByKvw(kvwNumbers: string[]): Observable<BouwpasContractor[]>;
    getTemplates(): Observable<BouwpasTemplate[]>;
}

@Injectable()
export class BouwpasServiceImpl implements BouwpasService {
    public constructor(private http: HttpClient) {
    }

    getContractorsByKvw(kvwNumbers: string[] | null): Observable<BouwpasContractor[]> {
        if (kvwNumbers && kvwNumbers.length > 0) {
            const observables: Observable<BouwpasContractor[]>[] = kvwNumbers.map((kvwNumber) => {
                const params = { kvwNumbers: kvwNumber };
                return this.http.get<BouwpasContractor[]>('/api/v1/bouwpas/contractors', { params });
            });

            return forkJoin(observables).pipe(
                map((results) => results.reduce(
                    (acc, cur) => acc.concat(cur), [])
                )
            );
        } else {
            return new Observable<BouwpasContractor[]>(observer => {
                observer.next([]);
                observer.complete();
            });
        }
    }

    getTemplates(): Observable<BouwpasTemplate[]> {
        return this.http.get<BouwpasTemplate[]>(`/api/v1/bouwpas/templates`);
    }
}

