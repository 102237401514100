import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Project} from '../models/project';
import {ExistingTrimbleConnect} from '../models/existing-trimble-connect';

@Injectable()
export class TrimbleConnectApplicationService {
    constructor(protected httpClient: HttpClient) {
    }

    add(trimbleConnect: ExistingTrimbleConnect) {
        return this.httpClient.post(`/api/v1/migrations/applications/trimble`, trimbleConnect);
    }

    remove(project: Project, trimbleId: string) {
        return this.httpClient.delete(`/api/v1/migrations/applications/trimble/${project.id}/${trimbleId}`)
    }
}
