import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef, VwuiModalService} from '@recognizebv/vwui-angular';
import {Project} from '../../models/project';
import {FormControl, FormGroup, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {
    Application,
    applicationStatusTranslation,
    BouwpasApplication,
    NAVApplication,
    PrmApplication, ProjectSightApplication,
    SecondaryApplication,
    TeamsApplication,
    TrimbleApplication
} from '../../models/application';
import {ProjectAppStoreItemService} from '../../services/project-app-store-item.service';
import {UserService} from '../../services/user.service';
import {EMPTY, lastValueFrom, Observable, of, Subscription, timer} from 'rxjs';
import {expand, switchMap} from 'rxjs/operators';
import {RestUtils} from '../../utils/rest-utils';
import {kebabCase} from '../../utils/kebab-case';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';
import {awaitModalClosed} from '../../utils/modal';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {ExplorerAppActivation} from '../../models/explorer-app-activation';
import {ProjectService} from '../../services/project.service';

@Component({
    selector: 'app-project-app-store-detail-modal',
    templateUrl: './project-app-store-detail-modal.component.html'
})
export class ProjectAppStoreDetailModalComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    app: Application;
    project: Project;
    form: UntypedFormGroup = new UntypedFormGroup({});
    explorerAppActivations$: Observable<ExplorerAppActivation[]> = EMPTY;
    projectSightParentProject$: Observable<Project> = EMPTY;

    readonly statusTranslation = applicationStatusTranslation;
    kebabCase = kebabCase;

    private deleteConfirmData = {
        title: 'Applicatie ontkoppelen',
        description: 'Weet u zeker dat u deze applicatie wilt ontkoppelen?',
        confirmButton: 'Ontkoppelen'
    };

    constructor(
        public modalRef: VwuiModalRef,
        @Inject('ProjectAppStoreItemService') private projectAppStoreItemService: ProjectAppStoreItemService,
        @Inject('ProjectService') private projectService: ProjectService,
        @Inject('UserService') private userService: UserService,
        modalParams: VwuiModalConfig<{ appInstance: Application,project: Project }>,
        private modalService: VwuiModalService,
        private toastr: ToastrService,
    ) {
        this.project = modalParams.data.project;
        this.patchApp(modalParams.data.appInstance);
    }

    patchApp(app: Application) {
        this.app = app;
        if (app && app.secondaryType === 'TrimbleExplorer') {
            this.explorerAppActivations$ = this.projectAppStoreItemService.getTrimbleExplorerActivations(
                +RestUtils.getIdFromSelfLink(app._links.self)
            );
        } else {
            this.explorerAppActivations$ = EMPTY;
        }

        if (this.app.type === 'trimbleConnect') {
            const trimble = this.app as TrimbleApplication;
            this.form = new UntypedFormGroup({
                email: new UntypedFormControl(trimble.email),
                applicationNumber: new UntypedFormControl(trimble.applicationNumber),
                status: new UntypedFormControl(this.statusTranslation[trimble.status])
            })
        } else if (this.app.type === 'nav') {
            const nav = this.app as NAVApplication;
            this.form = new FormGroup({
                navAdmin: new FormControl(nav.navAdministration),
                applicationNumber: new FormControl(nav.applicationNumber),
                status: new FormControl(this.statusTranslation[nav.status])
            })
        } else if (this.app.type === 'teams') {
            const teams = this.app as TeamsApplication;
            this.form = new FormGroup({
                owner: new FormControl(teams.ownerName),
                template: new FormControl(teams.template),
                applicationNumber: new FormControl(teams.applicationNumber),
                status: new FormControl(this.statusTranslation[teams.status])
            })
        } else if (this.app.type === 'bouwpas') {
            const bouwpas = this.app as BouwpasApplication;
            this.form = new FormGroup({
                owner: new FormControl(bouwpas.ownerName),
                applicationNumber: new FormControl(bouwpas.applicationNumber),
                contractor: new FormControl(bouwpas.contractor?.contractorName),
                template: new FormControl(bouwpas.template),
                navApplication: new FormControl(bouwpas.navApplicationName),
                status: new FormControl(this.statusTranslation[bouwpas.status])
            })
        }  else if (this.app.type === 'projectRisicoMonitor') {
            const prm = this.app as PrmApplication;
            this.form = new FormGroup({
                owner: new FormControl(prm.ownerName),
                applicationNumber: new FormControl(prm.applicationNumber),
                status: new FormControl(this.statusTranslation[prm.status])
            })
        }  else if (this.app.type === 'projectSight') {
            const projectSight = this.app as ProjectSightApplication;
            if(null !== projectSight.copyFromProject) {
                this.projectSightParentProject$ = this.projectService.getByApplicationNumber(projectSight.copyFromProject);
            }
            this.form = new FormGroup({
                applicationNumber: new FormControl(projectSight.applicationNumber),
                status: new FormControl(this.statusTranslation[projectSight.status]),
                portfolioId: new FormControl(projectSight.portfolioId),
                projectAdmin: new FormControl(projectSight.projectAdmin),
                trimbleConnectTemplate: new FormControl(projectSight.trimbleConnectTemplate),
                copyFromProject: new FormControl(projectSight.copyFromProject),
                copyProjectSettings: new FormControl(projectSight.copyProjectSettings),
                copyUsers: new FormControl(projectSight.copyUsers),
                trimbleConnectProjectId: new FormControl(projectSight.trimbleConnectProjectId),
            })
        } else if (this.app.type === 'secondary' && this.app.navisionAdministration) {
            this.form = new FormGroup({
                navision: new FormControl(
                    `${this.app.navisionAdministration} - ${this.app.navisionApplicationNumber}`
                )
            });
        } else if(this.app.type === 'secondary' ) {
            const secondary = this.app as SecondaryApplication
            this.form = new FormGroup({url: new FormControl(secondary.url)})
        }
    }

    ngOnInit() {
        this.subscriptions.push(
            of(this.app).pipe(
                expand(app => {
                    if (app.status === 'CREATED') {
                        return timer(60 * 1000).pipe(
                            switchMap(() => this.projectAppStoreItemService.get(+RestUtils.getIdFromSelfLink(app._links.self)))
                        );
                    } else {
                        return EMPTY;
                    }
                })
            ).subscribe(app => {
                this.patchApp(app as Application);
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }

    isSecondary(app: Application): SecondaryApplication | null {
        return app.type === 'secondary' ? app : null;
    }

    async deleteApplication() {
        const deleteModal = this.modalService.open(ConfirmModalComponent, {
            data: {...this.deleteConfirmData}
        });
        const result = await awaitModalClosed<boolean>(deleteModal);

        if (result) {
            try {
                await lastValueFrom<void>(this.projectAppStoreItemService.delete(this.app));
                this.toastr.success('Applicatie ontkoppeld.');
            } catch (err) {
                if (err instanceof HttpErrorResponse && err.status === HttpStatusCode.Conflict) {
                    console.error('Conflict', err);
                    this.toastr.error('Verwijderen niet mogelijk, er zijn nog afhankelijke applicaties actief')
                } else {
                    console.error('Delete failed', err);
                    this.toastr.error('Applicatie ontkoppelen mislukt.');
                }
            }
            this.modalRef.close(true);
        }
    }
}
