import {Injectable} from '@angular/core';
import {CreateProjectMember, ProjectMember} from '../../models/project-member';
import {AbstractRestServiceMock} from './abstract-rest.service.mock';
import {ProjectMemberService} from '../project-member.service';
import {ProjectRole} from '../../models/project-role';
import {Observable, of} from 'rxjs';

export const mockProjectMembers: ProjectMember[] = [
    {
        role: ProjectRole.Owner,
        _links: {
            self: {
                href: '/api/v1/project-members/1'
            },
            user: {
                href: '/api/v1/users/1'
            },
            project: {
                href: '/api/v1/projects/1'
            }
        }
    },
    {
        role: ProjectRole.ProjectLeader,
        _links: {
            self: {
                href: '/api/v1/project-members/1'
            },
            user: {
                href: '/api/v1/users/1'
            },
            project: {
                href: '/api/v1/projects/1'
            }
        }
    }
];

@Injectable()
export class ProjectMemberServiceMock extends AbstractRestServiceMock<ProjectMember, CreateProjectMember> implements ProjectMemberService {
    constructor() {
        super(mockProjectMembers);
    }

    create(createItem: Pick<ProjectMember, any>): Observable<ProjectMember> {
        return createItem.role === ProjectRole.Owner ? of(this.data[0]) : of(this.data[1]);
    }
}
