<ng-select [compareWith]="compare"
           [items]="items$ | async"
           [hideSelected]="true"
           [loading]="loading"
           [typeahead]="onInput"
           (scrollToEnd)="scrollToEnd()"
           [formControl]="formControl"
           [multiple]="false"
           [placeholder]="placeholder"
           [clearable]="true"
           [addTag]="addTag"
           appendTo="body"
>
    <ng-template ng-label-tmp let-item="item">
        <span>{{ getLabelText(item) }}</span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <span>{{ getLabelText(item) }}</span>
    </ng-template>
</ng-select>
