import {RestItem} from './rest/rest-item';
import {RestLink} from './rest/rest-link';
import {Project} from './project';
import {TrimbleUser} from './trimble-user';
import {AppStoreItem} from './app-store';
import {User} from './user';

export interface ProjectAppStoreItem extends RestItem<Application> {
    id: number;
    applicationNumber: string;
    status: 'CREATED' | 'FAILED' | 'COMPLETED';
    esbError?: string;
    type: string;
    title: string;
    secondaryType: 'TrimbleViewer' | 'TrimbleExplorer' | 'Extension' | 'Addon';
    description: string;
    appType: string;
    createdAt: Date;
    iconSas?: string;
    _links: {
        self: RestLink<Application>;
        project?: RestLink<Project>;
        appStoreItem: RestLink<AppStoreItem>
    };
    portfolioId?: string;
}

export interface SecondaryApplication extends ProjectAppStoreItem {
    type: 'secondary';
    navisionAdministration: string | null;
    navisionApplicationNumber: string | null;
    url: string | null;
    _links: ProjectAppStoreItem['_links'] & {
        navisionApp: RestLink<NAVApplication> | null;
    };
}

export interface TrimbleApplication extends ProjectAppStoreItem {
    type: 'trimbleConnect';
    email: string;
    existingApplication: boolean;
    _links: ProjectAppStoreItem['_links'] & {
        trimbleUser?: RestLink<TrimbleUser>;
    };
}

export interface NAVApplication extends ProjectAppStoreItem {
    type: 'nav';
    existingApplication: boolean;
    navAdministration: string;
}

export interface TeamsApplication extends ProjectAppStoreItem {
    type: 'teams';
    ownerName: string;
    template: string | null;
    existingApplication: boolean;
    _links: ProjectAppStoreItem['_links'] & {
        owner: RestLink<User>;
    };
}

export interface BouwpasApplication extends ProjectAppStoreItem {
    type: 'bouwpas';
    contractor: {
        contractorName: string;
        contractorId: number;
    };
    navApplicationName: string;
    ownerName: string;
    template: string;
    templateId: number;
    _links: ProjectAppStoreItem['_links'] & {
        owner: RestLink<User>;
        navApplication: RestLink<NAVApplication>;
    };
}

export interface PrmApplication extends ProjectAppStoreItem {
    type: 'projectRisicoMonitor';
    ownerName: string;
    _links: ProjectAppStoreItem['_links'] & {
        owner: RestLink<User>;
    };
}

export interface ProjectSightApplication extends ProjectAppStoreItem {
    type: 'projectSight';
    portfolioId: string;
    projectAdmin: string;
    trimbleConnectTemplate: string;
    copyFromProject: number | null;
    copyProjectSettings: boolean | null;
    copyUsers: boolean | null;
    trimbleConnectProjectId?: string;
}

export type Application = SecondaryApplication
    | TrimbleApplication
    | NAVApplication
    | TeamsApplication
    | BouwpasApplication
    | PrmApplication
    | ProjectSightApplication;

export type BaseCreateOmit = 'id'| 'title' | 'icon' | 'secondaryType' | 'defaultActive' | 'description' | 'appType' | 'createdAt' | '_links';
export interface BaseCreateApplication {
    project: string;
    appStoreItem: string;
}

export interface CreateTrimbleApplication extends BaseCreateApplication,
    Omit<TrimbleApplication, BaseCreateOmit> {
    email: string;
    template: string;
    existingApplication: boolean;
}

export interface CreateNAVApplication extends BaseCreateApplication, Omit<NAVApplication, BaseCreateOmit> {
}

export interface CreateBouwpasApplication extends BaseCreateApplication,
    Omit<BouwpasApplication, BaseCreateOmit | 'ownerName' | 'navApplicationName'> {
    owner: string;
    navApplication: string;
    existingApplication: boolean;
}

export interface CreatePrmApplication extends BaseCreateApplication,
    Omit<PrmApplication, BaseCreateOmit | 'ownerName'> {
    owner: string;
    existingApplication: boolean;
}

export interface CreateProjectSightApplication extends BaseCreateApplication,
    Omit<ProjectSightApplication, BaseCreateOmit> {
    portfolioId: string;
    projectAdmin: string;
    trimbleConnectTemplate: string;
    copyFromProject: number | null;
    copyProjectSettings: boolean | null;
    copyUsers: boolean | null;

}

export interface CreateTeamsApplication extends BaseCreateApplication,
    Omit<TeamsApplication, BaseCreateOmit | 'ownerName'> {
    owner: string;
    existingApplication: boolean;
}

export type CreateApplication = CreateTrimbleApplication
    | CreateNAVApplication
    | CreateBouwpasApplication
    | CreateTeamsApplication
    | CreateProjectSightApplication
    | CreatePrmApplication;

export interface ProjectAppStoreItemWithProject {
    projectId: number;
    projectName: string;

    projectAppStoreItemId: number;
    applicationNumber: string;
}

export const applicationStatusTranslation = {
    CREATED: 'Wordt aangemaakt',
    FAILED: 'Gefaald',
    COMPLETED: 'Actief'
};

export const applicationTypeTranslation = {
    trimbleConnect: 'Trimble Connect',
    nav: 'Navision'
};

export const applicationTypeImageName = {
    trimbleConnect: 'trimble-connect',
    nav: 'microsoft-dynamics-nav'
};
