import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ProjectLabel} from '../models/project-label';
import {Observable} from 'rxjs';
import {AbstractRestService, AbstractRestServiceInterface} from './abstract-rest.service';
import {RestCollection} from '../models/rest/rest-collection';

export interface ProjectLabelService extends AbstractRestServiceInterface<ProjectLabel> {
    getActive(): Observable<RestCollection<ProjectLabel>>
}

@Injectable()
export class ProjectLabelServiceImpl extends AbstractRestService<ProjectLabel> implements ProjectLabelService {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, 'projectLabels', 'projectLabels');
    }

    getActive(): Observable<RestCollection<ProjectLabel>> {
        return this.httpClient.get<RestCollection<ProjectLabel>>(`/api/v1/${this.resourcePath}/search/findAllByActiveTrue`)
    }
}
