import {RestItem} from './rest/rest-item';

export interface ProjectLabel extends RestItem<ProjectLabel> {
    id: number | undefined;
    name: string;
    color: string;
    active: boolean;
    used: boolean | undefined;
}

export const labelColors = [
    {
        label: 'Blauw',
        color: '#008cbc'
    },
    {
        label: 'Lichtgrijs',
        color: '#e1e5f1'
    },
    {
        label: 'Wit',
        color: '#ffffff'
    },
    {
        label: 'Oranje',
        color: '#ffab00'
    },
    {
        label: 'Rood',
        color: '#ff5640'
    },
    {
        label: 'Grijs',
        color: '#a6afca'
    }
];

export const noLabelColor = '#36b37a';
