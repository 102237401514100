import {Directive, ElementRef, Inject, Input, OnDestroy} from '@angular/core';
import {AuthorizationService} from '../services/authorization.service';
import {Subscription} from 'rxjs';
import {Project} from '../models/project';

@Directive({
    selector: '[appShowIfCanUpdateProject]'
})
export class ShowWhenCanUpdateProjectDirective implements OnDestroy {

    @Input() project: Project;
    private subscriptions: Subscription[] = [];

    constructor(
        private element: ElementRef,
        @Inject('AuthorizationService') private authorizationService: AuthorizationService,
    ) {
        this.subscriptions.push(
            this.authorizationService.canUpdateDeleteProject$(this.project).subscribe(canUpdateOrDeleteProject => {
                if (!canUpdateOrDeleteProject) {
                    this.element.nativeElement.style.display = 'none';
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
