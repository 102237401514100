import {Injectable} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {AccountInfo} from '@azure/msal-common';
import {filterMsalAccounts} from '../utils/filter-msal.accounts';

export interface MsalAuthWrapperService {
    getAccount(): AccountInfo;
}

@Injectable()
export class MsalAuthWrapperServiceImpl implements MsalAuthWrapperService {
    constructor(
        private authService: MsalService
    ) {
    }

    getAccount(): AccountInfo {
        return filterMsalAccounts(this.authService.instance.getAllAccounts())[0];
    }
}
