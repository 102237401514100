import {Injectable} from '@angular/core';
import {AuthorizationService} from '../authorization.service';
import {Role} from '../../models/role';
import {lastValueFrom, Observable, of} from 'rxjs';
import {parseJwtPayload} from '../../utils/jwt';
import {Project} from '../../models/project';

@Injectable()
export class AuthorizationServiceMock implements AuthorizationService {
    isSuperAdmin$: Observable<boolean>;
    isBedrijfsAdmin$: Observable<boolean>;
    roles$: Observable<Role[]>;
    canCreateProject$: Observable<boolean>;

    constructor() {
        const accessToken = localStorage.getItem('accessToken');
        const idToken = parseJwtPayload(accessToken);

        const roles = idToken && idToken.roles ? idToken.roles : [];

        this.roles$ = of(roles);
        this.isSuperAdmin$ = of(roles.includes(Role.superAdmin));
        this.canCreateProject$ = of(roles.includes(Role.trimbleManager));
    }

    canUpdateDeleteProject$(project: Project): Observable<boolean> {
        return this.canCreateProject$;
    }
}
