import {ItemSelectComponent} from './item-select.component';
import {Component, forwardRef, Inject, Input} from '@angular/core';
import {Observable, of} from 'rxjs';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {activationTypeTranslations, AppStoreItemActivationType} from '../../models/app-store';
import {AppStoreItemService} from '../../services/app-store-item.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-activation-type-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ActivationTypeSelectComponent),
            multi: true
        }
    ]
})
export class ActivationTypeSelectComponent extends ItemSelectComponent<AppStoreItemActivationType, {}> {
    @Input() excludedOptions: string[] = [];

    constructor(
        @Inject('AppStoreItemService') private appStoreItemService: AppStoreItemService,
    ) {
        super();

        this.paginated = false;
        this.placeholder = 'Selecteer een activatie type';
    }

    getFilter(): Observable<{}> {
        return of({});
    }

    getLabelText(item: AppStoreItemActivationType): string {
        return activationTypeTranslations[item];
    }

    loadMoreItems(filter: any): Observable<AppStoreItemActivationType[]> {
        return this.appStoreItemService.listActivationTypes().pipe(
            map(it => it.filter(item => !this.excludedOptions.includes(item)))
        );
    }

    compare(a: AppStoreItemActivationType, b: AppStoreItemActivationType): boolean {
        if (!a || !b) {
            return false;
        }

        return a === b;
    }
}
