import {ItemSelectComponent} from './item-select.component';
import {Component, forwardRef, Inject} from '@angular/core';
import {Observable, of} from 'rxjs';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {map, shareReplay} from 'rxjs/operators';
import {BouwpasService} from '../../services/bouwpas.service';
import {BouwpasTemplate} from '../../models/bouwpas-template';

@Component({
    selector: 'app-bouwpas-template-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BouwpasTemplateSelectComponent),
            multi: true
        }
    ]
})
export class BouwpasTemplateSelectComponent extends ItemSelectComponent<BouwpasTemplate, {}> {
    constructor(
        @Inject('BouwpasService') private bouwpasService: BouwpasService
    ) {
        super();

        this.paginated = false;
        this.placeholder = 'Selecteer een template';
    }

    private readonly contractors$ = this.bouwpasService.getTemplates().pipe(
        shareReplay({bufferSize: 1, refCount: false})
    )

    getFilter(): Observable<{}> {
        return of({});
    }

    getLabelText({shortDescription}: BouwpasTemplate): string {
        return shortDescription;
    }

    loadMoreItems(page: number, filter: { term: string }): Observable<BouwpasTemplate[]> {
        return this.contractors$.pipe(
            map(response => {
                return this.filterBySearchTerm(response, filter);
            }));
    }

    private filterBySearchTerm(items: BouwpasTemplate[], filter: { term: string }) {
        return items.filter(it => {
            const term = filter.term?.toLowerCase() ?? '';
            return this.getLabelText(it).toLowerCase().includes(term);
        });
    }
}
