import {Component, ViewChild} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {ImageCropperComponent} from 'ngx-image-cropper';
import {ToastrService} from 'ngx-toastr';
import {OutputFormat} from 'ngx-image-cropper/lib/interfaces/cropper-options.interface';

interface ModalParams {
    imageFile: File;
}

@Component({
    selector: 'app-image-crop-modal',
    templateUrl: './image-crop-modal.component.html'
})
export class ImageCropModalComponent {
    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

    imageFile: File;
    format: OutputFormat | undefined;

    constructor(
        public modalRef: VwuiModalRef,
        private toastr: ToastrService,
        modalParams: VwuiModalConfig<ModalParams>
    ) {
        this.imageFile = modalParams.data.imageFile;
        this.format = modalParams.data.imageFile.type === 'image/png' ? 'png' : 'jpeg';
    }

    async finish() {
        try {
            const cropResult = await this.imageCropper.crop();
            this.modalRef.close(cropResult);
        } catch (error) {
            console.error(error);
            this.modalRef.close(null);
        }
    }

    imageLoadingFailed() {
        this.toastr.error('Fout bij het inladen van de afbeelding.');
        this.modalRef.close(null);
    }
}
