import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {RestItem} from '../models/rest/rest-item';
import {RestLink} from '../models/rest/rest-link';
import {Observable, of} from 'rxjs';
import {PageResponse} from '../models/page-response';
import {RestCollection, RestRelationShip} from '../models/rest/rest-collection';
import {catchError, map} from 'rxjs/operators';
import {kebabCase} from '../utils/kebab-case';
import {CustomHttpParameterCodec} from '../utils/custom-http-parameter-codec';

export type RestItemOrRestLink<T> = RestItem<T> | RestLink<T>;

export interface RestHttpParams {
    [param: string]: string;
}

export interface SortParams {
    field: string;
    direction: string;
}

export interface AbstractRestServiceInterface<T extends RestItem<T>, C = Partial<Omit<T, '_links'>>> {
    get(id: number): Observable<T>;

    create(createItem: C): Observable<T>;

    patch(restItem: Required<RestItem<T>> & Partial<T>): Observable<T>;

    delete(restItem: T): Observable<void>;

    findAll(params?: RestHttpParams, sort?: SortParams): Observable<PageResponse<T>>;

    getSingleRelation<L extends RestLink<T>>(relation: L): Observable<T>;

    getCollectionRelation<L extends RestLink<T>>(relation: L, key?: string): Observable<T[]>;

    replaceRelation<L extends RestLink<T>>(relation: L, ...values: Array<RestItemOrRestLink<T>>): Observable<L>;

    addRelation<L extends RestLink<T>>(relation: L, ...values: Array<RestItemOrRestLink<T>>): Observable<L>;

    deleteRelation<L extends RestLink<any>>(relation: L): Observable<void>;
}

export abstract class AbstractRestService<T extends RestItem<T>, C = Partial<Omit<T, '_links'>>>
    implements AbstractRestServiceInterface<T, C> {

    protected constructor(
        protected httpClient: HttpClient,
        protected resourceName: string,
        protected resourcePath?: string
    ) {
        if (resourcePath === undefined) {
            this.resourcePath = kebabCase(resourceName);
        }
    }

    get(id: number): Observable<T> {
        return this.httpClient.get<T>(`/api/v1/${this.resourcePath}/${id}`);
    }

    create(createItem: C): Observable<T> {
        return this.httpClient.post<T>(`/api/v1/${this.resourcePath}`, createItem);
    }

    patch(restItem: Required<RestItem<T>> & Partial<T>): Observable<T> {
        return this.httpClient.patch<T>(restItem._links.self.href, restItem);
    }

    delete(restItem: T): Observable<void> {
        return this.httpClient.delete<void>(restItem._links.self.href);
    }

    findAll(params: RestHttpParams = {}, sort?: SortParams): Observable<PageResponse<T>> {
        return this.httpClient.get<RestCollection<T>>(`/api/v1/${this.resourcePath}`, {
            params: new HttpParams({
                fromObject: {
                    ...params,
                    ...(sort ? {sort: sort.field + ',' + sort.direction} : {}),
                },
                encoder: new CustomHttpParameterCodec()
            })
        }).pipe(map(it => ({
            content: it._embedded[this.resourceName],
            page: it.page
        })));
    }

    public getSingleRelation<L extends RestLink<T>>(relation: L): Observable<T> {
        return this.httpClient.get<T>(this.removeLinkTemplate(relation.href)).pipe(
            catchError(e => {
                if (e instanceof HttpErrorResponse && e.status === 404) {
                    return of(null);
                }
                throw e;
            })
        );
    }

    public getCollectionRelation<L extends RestLink<T>>(relation: L, key?: string): Observable<T[]> {
        const href = this.removeLinkTemplate(relation.href);
        const relationKey = key || kebabCase(href.substr(href.lastIndexOf('/') + 1));

        return this.httpClient.get<RestRelationShip<T>>(href).pipe(
            map(it => it._embedded[relationKey])
        );
    }

    public replaceRelation<L extends RestLink<T>>(
        relation: L,
        ...values: Array<RestItemOrRestLink<T>>
    ) {
        const href = this.removeLinkTemplate(relation.href);
        return this.httpClient.put<any>(href, this.toUriList(values), {
            headers: {'Content-Type': 'text/uri-list'}
        });
    }

    public addRelation<L extends RestLink<T>>(
        relation: L,
        ...values: Array<RestItemOrRestLink<T>>
    ) {
        return this.httpClient.post<any>(relation.href, this.toUriList(values), {
            headers: {'Content-Type': 'text/uri-list'}
        });
    }

    public deleteRelation<L extends RestLink<any>>(
        relation: L
    ) {
        return this.httpClient.delete<void>(relation.href);
    }

    protected toUriList(values: Array<RestItemOrRestLink<any>>) {
        return values.map(it => this.getUri(it)).join('\r\n');
    }

    protected getUri(restItemOrRestLink: RestItemOrRestLink<any>) {
        if (restItemOrRestLink.hasOwnProperty('href')) {
            return (restItemOrRestLink as RestLink<T>).href;
        } else {
            return (restItemOrRestLink as RestItem<T>)._links.self.href;
        }
    }

    protected removeLinkTemplate(link: string) {
        let cleanedLink = link;
        const templateIndex = link.indexOf('{');
        if (templateIndex !== -1) {
            cleanedLink = cleanedLink.substr(0, templateIndex);
        }
        return cleanedLink;
    }
}
