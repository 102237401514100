import {NotificationService} from '../notification.service';
import {Observable, of} from 'rxjs';
import {Notification} from '../../models/notification';

export class NotificationServiceMock implements NotificationService {
    acknowledge(id: number): Observable<void> {
        return of(null);
    }

    list(): Observable<Notification[]> {
        return of([]);
    }
}
