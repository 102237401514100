import {Project} from '../../../models/project';

export const mockProjects: Project[] = [
    {
        id: 1,
        name: 'Zeeburgereiland',
        description: "desc",
        commercialName: null,
        code: '10001',
        startDate: new Date('2019-05-23'),
        endDate: new Date('2020-02-27'),
        active: true,
        postCode: '1095 MP',
        houseNumber: '43',
        street: 'Eef Kamerbeekstraat',
        city: 'Amsterdam',
        latitude: 52.3737093,
        longitude: 4.9620473,
        companyName: 'Recognize',
        clientName: 'VWTelecom',
        finalized: false,
        hasApplications: false,
        trimbleActivated: false,
        hidden: false,
        projectLabel: null,
        typeGUID: ['Demolition'],
        buildingClassificationGUID: [],
        buildingSubClassificationGUID: [],
        sustainabilityClassGUID: [],
        vwConceptGUID: [],
        operationalProjectStatusGUID: ["568dd1ed-c9b9-4871-bd89-0a9927e196c7"],
        _links: {
            self: {href: '/api/v1/projects/1'}
        },
        kvwNumber: '0022',
        bouwcombinatieKvwNumbers: []
    },
    {
        id: 70,
        name: 'MorgenWonen',
        description: "desc",
        commercialName: null,
        code: '10070',
        startDate: new Date('2019-11-15'),
        endDate: new Date('2019-12-15'),
        active: true,
        postCode: '1311WQ',
        houseNumber: '1',
        street: 'Eef Straat',
        city: 'Nieuwegein',
        latitude: 52.3737093,
        longitude: 4.9620473,
        companyName: 'VWTelecom',
        clientName: 'VWTelecom',
        finalized: false,
        hasApplications: false,
        trimbleActivated: false,
        hidden: true,
        projectLabel: null,
        typeGUID: ['Transformatie'],
        buildingClassificationGUID: [],
        buildingSubClassificationGUID: [],
        sustainabilityClassGUID: [],
        vwConceptGUID: [],
        operationalProjectStatusGUID: ["568dd1ed-c9b9-4871-bd89-0a9927e196c7"],
        _links: {
            self: {href: '/api/v1/projects/70'}
        },
        kvwNumber: null,
        bouwcombinatieKvwNumbers: []
    }
];
