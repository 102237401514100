import {Pipe, PipeTransform} from '@angular/core';
import {AppStoreItem, appTypeTranslation, secondaryTypeTranslation} from '../models/app-store';

@Pipe({
    name: 'appStoreItemType'
})
export class AppStoreItemTypePipe implements PipeTransform {
    transform(app: AppStoreItem): string {
        if (!app) {
            return '';
        }

        return app.type === 'Secondary'
            ? secondaryTypeTranslation[app.secondaryType]
            : appTypeTranslation[app.type];
    }
}
