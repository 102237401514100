<div *ngIf="app" class="item-card">
    <div class="item-card__header"
         [class.item-card__header--light-bg]="app.appType === 'Secondary' && app.secondaryType !== 'Addon'"
         [class.item-card__header--gray-bg]="app.appType === 'Secondary' && app.secondaryType === 'Addon'">
        <div class="item-card__icon">
            <div *ngIf="app.iconSas" class="app-store-icon">
                <img [src]="app.iconSas">
            </div>
        </div>

        <div>
            <div class="item-card__title"
                 [class.item-card__title--black]="app.appType === 'Secondary'">{{app.title}}</div>
            <div class="item-card__subtitle"
                 [class.item-card__subtitle--blue]="app.appType === 'Secondary'">
                {{ app | projectAppStoreItemType }}
            </div>
        </div>

        <div *ngIf="app?.status === 'CREATED'" class="item-card__top-right">
            <vwui-spinner class="spinner--transparent"></vwui-spinner>
        </div>

        <div *ngIf="app?.status === 'FAILED'" class="item-card__top-right">
            <vwui-tooltip position="top" [label]="app?.esbError ?? 'Applicatie aanmaken is mislukt'">
                <vwui-icon iconName="vwui-info-circle"></vwui-icon>
            </vwui-tooltip>
        </div>


    </div>
    <div class="item-card__content">
        <div class="item-card__buttons">
            <vwui-button icon="link" *ngIf="showOpenAppButton()" class="item-card__button" [block]="true" (click)="openApp(app)">
                Open app
            </vwui-button>
            <vwui-button [id]="'detail-' + kebabCase(app.title)" class="item-card__button" [block]="true"
                         (click)="appClick.emit(app)">{{btnLabel}}</vwui-button>
        </div>
    </div>
</div>
