<vwui-card>
    <vwui-card-header>Kenmerken</vwui-card-header>
    <vwui-card-body layout="half">
        <form [formGroup]="form">
            <vwui-form-group inputId="type" label="Werksoort" class="required">
                <ng-select [items]="types$ | async" [multiple]="true" bindLabel="value" bindValue="key" placeholder="Selecteer een werksoort"
                           formControlName="typeGUID" appendTo="body"></ng-select>
            </vwui-form-group>
            <vwui-form-group inputId="buildingClassification" label="Gebruiksfunctie">
                <ng-select [items]="usageFunctions$ | async" bindLabel="value" bindValue="key"
                           placeholder="Selecteer een gebruiksfunctie" formControlName="buildingClassificationGUID"
                           [multiple]="true" appendTo="body"></ng-select>
            </vwui-form-group>
            <vwui-form-group inputId="buildingSubClassification" label="Sub-Gebruiksfunctie">
                <ng-select [items]="availableSubClassifications$ | async" groupBy="buildingSubClassificationGUID" bindLabel="value" bindValue="key"
                           placeholder="Selecteer een subcategorie" formControlName="buildingSubClassificationGUID"
                           [multiple]="true" appendTo="body">
                </ng-select>
            </vwui-form-group>
            <vwui-form-group inputId="vwConcept" label="VolkerWessels Concept">
                <ng-select [items]="vwConcepts$ | async" [multiple]="true" bindLabel="value" bindValue="key" appendTo="body"
                           placeholder="Selecteer een VolkerWessels concept" formControlName="vwConceptGUID"></ng-select>
            </vwui-form-group>
            <vwui-form-group inputId="sustainabilityClass" label="Duurzaamheid classificatie">
                <ng-select [items]="sustainabilityRatings$ | async" [multiple]="true" bindLabel="value" bindValue="key" appendTo="body"
                           placeholder="Selecteer een classificatie" formControlName="sustainabilityClassGUID"></ng-select>
            </vwui-form-group>
            <div class="d-flex w-100 mb-3" *ngIf="!readonly">
                <vwui-button
                    type="submit"
                    slot="action"
                    class="is-secondary ml-auto"
                    (click)="save()"
                    [disabled]="!form.valid || submitPending"
                >
                    Opslaan
                </vwui-button>
                <vwui-button
                    type="submit"
                    slot="action"
                    class="is-secondary ml-2"
                    (click)="saveAndContinue()"
                    [disabled]="!form.valid || submitPending"
                    [loading]="submitPending"
                >
                    Opslaan en verder
                </vwui-button>
            </div>
        </form>
    </vwui-card-body>
</vwui-card>
