import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Image, ImageSize} from '../models/image';
import {RestLink} from '../models/rest/rest-link';
import {lastValueFrom, Observable} from 'rxjs';

export interface ImageService {
    postImage(imageFile: File | Blob): Promise<Image>;

    getImage(link: RestLink<Image>): Observable<Image>;

    getImageData(image: Image, size?: ImageSize): Observable<Blob>;
}

@Injectable()
export class ImageServiceImpl implements ImageService {
    constructor(private httpClient: HttpClient) {
    }

    postImage(imageFile: File | Blob) {
        const formData = new FormData();

        formData.append('image', imageFile, '');

        return lastValueFrom<Image>(this.httpClient.post<Image>('/api/v1/images', formData));
    }

    getImage(link: RestLink<Image>): Observable<Image> {
        return this.httpClient.get<Image>(link.href);
    }

    getImageData(image: Image, size?: ImageSize): Observable<Blob> {
        return this.httpClient.get(image.href, {
            responseType: 'blob',
            params: size ? {size} : undefined,
            headers: {Accept: 'application/octet-stream'}
        });
    }
}
