import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectService} from '../../services/project.service';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {UserService} from '../../services/user.service';
import {ProjectMemberService} from '../../services/project-member.service';
import {ProjectRole} from '../../models/project-role';
import {ToastrService} from 'ngx-toastr';
import {GraphService} from '../../services/graph.service';
import {MsalAuthWrapperService} from '../../services/msal-auth-wrapper.service';
import {validatePostcode} from '../../utils/validators';
import {noLabelColor, ProjectLabel} from '../../models/project-label';
import {BehaviorSubject, combineLatest, lastValueFrom} from 'rxjs';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {ProjectLabelService} from '../../services/project-label.service';
import {GraphCompanyDetailsResponse} from '../../models/company-page-response';
import {User} from '../../models/user';
import {Project} from '../../models/project';
import {ProjectMember} from '../../models/project-member';
import {RestLink} from '../../models/rest/rest-link';

@Component({
    selector: 'app-create-project-modal',
    templateUrl: './create-project-modal.component.html'
})
export class CreateProjectModalComponent {
    form = new FormGroup({
        name: new FormControl('', Validators.required),
        company: new FormControl(null, Validators.required),
        postCode: new FormControl(null, [Validators.required, validatePostcode]),
        houseNumber: new FormControl(),
        street: new FormControl(),
        city: new FormControl(null, [Validators.required]),
        latitude: new FormControl({value: null, disabled: true}, [Validators.required]),
        longitude: new FormControl({value: null, disabled: true}, [Validators.required]),
        projectLabel: new FormControl(null)
    });
    submitPending = false;
    labelSelectorOpen = false;
    refresh$ = new BehaviorSubject<void>(null);
    noLabelColor = noLabelColor;
    initialLocation: { latitude: number; longitude: number } | undefined;

    projectLabels$ = combineLatest([
        this.refresh$,
    ]).pipe(
        switchMap((_) => {
            return this.projectLabelService.getActive()
        }),
        map(response => response._embedded.projectLabels),
        shareReplay(1)
    );

    constructor(
        @Inject('ProjectService') private projectService: ProjectService,
        @Inject('ProjectMemberService') private projectMemberService: ProjectMemberService,
        @Inject('ProjectLabelService') private projectLabelService: ProjectLabelService,
        @Inject('GraphService') private graphService: GraphService,
        @Inject('UserService') private userService: UserService,
        @Inject('MsalAuthWrapperService') private msalAuthWrapperService: MsalAuthWrapperService,
        public modalRef: VwuiModalRef,
        private toast: ToastrService,
        modalParams: VwuiModalConfig<{ latitude: number, longitude: number }>,
    ) {
        if (modalParams.data) {
            const {latitude, longitude} = modalParams.data;
            this.initialLocation = {latitude: +latitude, longitude: +longitude};
        }
    }

    async submit() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            this.toast.error('Projectnaam, locatie en onderneming zijn verplicht');
            throw new Error('Couldn\'t save form, it\'s invalid');
        } else if (this.submitPending) {
            throw new Error('Couldn\'t save form, it\'s pending a submission');
        }

        try {
            this.submitPending = true;

            const authenticatedUser = this.msalAuthWrapperService.getAccount();
            const graphCompany = await lastValueFrom<GraphCompanyDetailsResponse>(this.graphService.getCompanyDetails());
            const user = await lastValueFrom<User>(this.userService.findByEmailOrCreate({
                email: authenticatedUser.username,
                companyName: graphCompany.name,
                displayName: authenticatedUser.name
            }));

            const {projectLabel, company, ...project} = this.form.getRawValue();
            const createdProject = await lastValueFrom<Project>(this.projectService.create({
                ...project,
                kvwNumber: company.kvwNumber
            }));

            await lastValueFrom<ProjectMember>(this.projectMemberService.create({
                role: ProjectRole.Owner,
                user: user && user._links.self.href,
                project: createdProject && createdProject._links.self.href
            }));

            if (this.form.controls.projectLabel.value) {
                await lastValueFrom<RestLink<ProjectLabel>>(this.projectService.replaceRelation(
                    createdProject._links.projectLabel,
                    this.form.controls.projectLabel.value._links.self
                ));
            }

            this.modalRef.close(createdProject.id);
        } catch (e) {
            console.error('Creating project failed: ', e);
            this.toast.error('Aanmaken van project mislukt.');
        } finally {
            this.submitPending = false;
        }
    }

    setLabel(label: ProjectLabel) {
        this.form.controls.projectLabel.patchValue(label);
        this.labelSelectorOpen = false;
    }

    toggleLabelSelector() {
        this.labelSelectorOpen = !this.labelSelectorOpen;
    }
}
