<div class="detail-modal-header detail-modal-header--light-bg">
    <vwui-modal-header>
        <div class="d-flex">
            <div class="item-card__icon">
                <div *ngIf="app.iconSas" class="app-store-icon">
                    <img [src]="app.iconSas">
                </div>
            </div>
            <div>
                <vwui-breadcrumb>
                    <span>Appstore</span>
                </vwui-breadcrumb>
                <vwui-breadcrumb>
                    <span>{{ app.title }}</span>
                </vwui-breadcrumb>
                <h3>{{app.title}}</h3>
            </div>
        </div>
    </vwui-modal-header>
</div>

<vwui-modal-body>
    <div *ngIf="!dependenciesSatisfied" class="status-box mb-4">
        <vwui-icon iconName="alert-circle" class="status-box__icon status-box__icon--inactive"></vwui-icon>
        <span class="status-box__text">
            Om gebruik te maken van deze applicatie moet je eerst
            <ng-container *ngFor="let missingDependency of missingDependencies; let last=last; let i=index;">
                {{ missingDependency }}<ng-container *ngIf="(i+2) < missingDependencies.length; else lastConcat">, </ng-container>
                <ng-template #lastConcat><ng-container *ngIf="!last"> en </ng-container></ng-template>
            </ng-container>
            activeren
        </span>
    </div>

    <vwui-layout type="split">
        <div slot="split-left">
            <h3>Omschrijving</h3>
            <div [innerHTML]="app.description"></div>
        </div>

        <div slot="split-right" [formGroup]="form" class="app-details-form">
            <h3 class="mb-2">{{ app.secondaryType === 'Addon' ? 'Applicatie toevoegen' : 'Applicatie activeren'}}</h3>
            <div class="mb-2">{{ app.secondaryType === 'Addon' ? 'Klik op ‘opslaan’ en de applicatie is klaar om gebruikt te worden.' : 'Klik op ‘activeren’ en de applicatie is klaar om gebruikt te worden.'}}</div>

            <vwui-form-group *ngIf="form.contains('navision')" label="Navision">
                <app-navision-application-select
                    [project]="project"
                    formControlName="navision">
                </app-navision-application-select>
            </vwui-form-group>
            <vwui-form-group *ngIf="app.secondaryType === 'Addon'"  label="URL" [required]="true">
                <vwui-input inputId="url" placeholder="https://www.voorbeeld.com" formControlName="url"></vwui-input>
            </vwui-form-group>
            <vwui-button
                id="activate"
                class="is-primary"
                [disabled]="!form.valid || activateAppPending || !dependenciesSatisfied"
                (click)="activateApp()"
            >
                {{app.secondaryType === 'Addon' ? 'Opslaan' : 'Activeren'}}
            </vwui-button>
        </div>
    </vwui-layout>
</vwui-modal-body>

<vwui-modal-footer>
    <vwui-button class="is-light" (click)="modalRef.close()">Venster sluiten</vwui-button>
</vwui-modal-footer>

