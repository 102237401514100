import {Component, Inject, OnDestroy} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {lastValueFrom, Subscription} from 'rxjs';
import {labelColors, ProjectLabel} from '../../models/project-label';
import {ProjectLabelService} from '../../services/project-label.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-project-label-modal',
    templateUrl: './project-label-modal.component.html'
})
export class ProjectLabelModalComponent implements OnDestroy {
    private subscriptions: Subscription[] = [];

    readonly colors = labelColors;

    label: ProjectLabel;
    submitPending = false;

    form = new UntypedFormGroup({
        name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255)]),
        color: new UntypedFormControl(null, [Validators.required]),
        active: new UntypedFormControl(false, [Validators.required])
    })

    constructor(
        public modalRef: VwuiModalRef,
        @Inject('ProjectLabelService') private labelService: ProjectLabelService,
        private toastr: ToastrService,
        modalParams: VwuiModalConfig<{ label: ProjectLabel }>
    ) {
        this.label = modalParams.data.label;
        this.form.patchValue(this.label);
    }

    async save() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            throw new Error('Couldn\'t save form, it\'s invalid');
        } else if (this.submitPending) {
            throw new Error('Couldn\'t save form, it\'s pending a submission');
        }

        try {
            this.submitPending = true;
            if (this.label) {
                await lastValueFrom<ProjectLabel>(this.labelService.patch(
                    {
                        ...this.label,
                        ...this.form.value
                    }
                ));
            } else {
                await lastValueFrom<ProjectLabel>(this.labelService.create(this.form.value));
            }
            this.toastr.success('Projectlabel opgeslagen');
        } catch (ex) {
            console.error('Error while saving label', ex);
            this.toastr.error('Opslaan mislukt');
        } finally {
            this.submitPending = false;
        }

        this.modalRef.close();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions.length = 0;
    }
}
