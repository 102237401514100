import {Directive, ElementRef, HostBinding, OnInit} from '@angular/core';
import {animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style} from '@angular/animations';

@Directive({
    selector: '[appFade]'
})
export class FadeDirective implements OnInit {
    @HostBinding('style.visibility')
    private visibility = 'hidden';

    private factory: AnimationFactory;
    private player: AnimationPlayer|undefined;

    constructor(
        private builder: AnimationBuilder,
        private el: ElementRef
    ) {
        this.factory = builder.build([
            style({ opacity: 0 }),
            animate(100, style({ opacity: 1 }))
        ]);
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.player) {
                this.player.destroy();
            }

            this.player = this.factory.create(this.el.nativeElement);
            this.visibility = 'visible';

            this.player.play();
        }, 0)
    }
}
