import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {Project} from '../../models/project';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {validatePostcode} from '../../utils/validators';
import {BehaviorSubject, lastValueFrom, Subject} from 'rxjs';
import {AzureMapsRestService} from '../../services/azure-maps-rest.service';
import {ProjectService} from '../../services/project.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthorizationService} from '../../services/authorization.service';

@Component({
    selector: 'app-project-location-tab',
    templateUrl: './project-location-tab.component.html'
})
export class ProjectLocationTabComponent {
    _project: Project;
    readonly$: Subject<boolean> = new BehaviorSubject(false);
    @Output() projectUpdated = new EventEmitter<void>();

    form: UntypedFormGroup = new UntypedFormGroup({
        postCode: new UntypedFormControl(null, [Validators.required, validatePostcode]),
        houseNumber: new UntypedFormControl(),
        street: new UntypedFormControl(),
        city: new UntypedFormControl(null, [Validators.required]),
        latitude: new UntypedFormControl({value: null, disabled: true}, [Validators.required]),
        longitude: new UntypedFormControl({value: null, disabled: true}, [Validators.required]),
    });
    submitPending = false;

    constructor(
        @Inject('AzureMapsRestService') private azureMapsRest: AzureMapsRestService,
        @Inject('ProjectService') private projectService: ProjectService,
        @Inject('AuthorizationService') private authorizationService: AuthorizationService,
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
    ) {}

    @Input()
    set project(project: Project) {
        this._project = project;
        this.form.patchValue(project);
    }

    @Input()
    set readonly(readonly: boolean) {
        this.readonly$.next(readonly);
        if (readonly) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    async patchProject() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            throw new Error('Couldn\'t save form, it\'s invalid');
        } else if (this.submitPending) {
            throw new Error('Couldn\'t save form, it\'s pending a submission');
        }

        try {
            this.submitPending = true;
            const {projectLabel, ...projectData} = this._project;

            await lastValueFrom<Project>(this.projectService.patch({
                ...projectData,
                ...this.form.getRawValue()
            }));
            this.toastr.success('Opgeslagen');
            this.projectUpdated.emit();

            return true;
        } catch (error) {
            console.error('Unable to save project', error);
            this.toastr.error('Opslaan mislukt.');
            return false;
        } finally {
            this.submitPending = false;
        }
    }

    async save() {
        await this.patchProject();
    }

    async saveAndContinue() {
        if (await this.patchProject()) {
            await this.router.navigate([], {relativeTo: this.route, queryParams: {tab: 'features'}});
        }
    }
}
