import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Project} from '../../models/project';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {lastValueFrom, Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {ProjectAppStoreItemService} from '../../services/project-app-store-item.service';
import {AppStoreItem} from '../../models/app-store';
import {CreateBouwpasApplication, ProjectAppStoreItem} from '../../models/application';
import {ProjectMemberService} from '../../services/project-member.service';
import {UserService} from '../../services/user.service';
import {User} from '../../models/user';

@Component({
    selector: 'app-application-bouwpas-modal',
    templateUrl: './application-bouwpas-modal.component.html'
})
export class ApplicationBouwpasModalComponent {
    form = new FormGroup({
        owner: new FormControl(null, [Validators.required]),
        contractor: new FormControl(null, [Validators.required]),
        template: new FormControl(null, [Validators.required]),
        navApplication: new FormControl(null, [Validators.required]),
        projectId: new FormControl(null),
        status: new FormControl(null),
        applicationNumber: new FormControl(''),
        existingApplication: new FormControl('false'),
    })

    project: Project;
    subscriptions: Subscription[] = [];
    app: AppStoreItem;
    submitPending = false;

    constructor(
        @Inject('ProjectAppStoreItemService') private projectAppStoreItemService: ProjectAppStoreItemService,
        @Inject('ProjectMemberService') private projectMemberService: ProjectMemberService,
        @Inject('UserService') private userService: UserService,
        private toastr: ToastrService,
        public modalRef: VwuiModalRef,
        modalParams: VwuiModalConfig<{ project: Project, app: AppStoreItem }>
    ) {
        this.project = modalParams.data.project;
        this.app = modalParams.data.app;
        this.createForm()
    }

    private createForm() {
        this.subscriptions.push(
            this.form.get('existingApplication').valueChanges.subscribe( existingApplication => {
                // make the validation conditional based on if it is an existing application
                if (existingApplication === 'true') {
                    this.form.controls.template.removeValidators([Validators.required]);
                    this.form.controls.owner.removeValidators([Validators.required]);
                    this.form.controls.applicationNumber.setValidators([Validators.required]);
                    this.form.controls.contractor.removeValidators([Validators.required]);
                } else {
                    this.form.controls.applicationNumber.removeValidators([Validators.required]);
                    this.form.controls.template.setValidators([Validators.required]);
                    this.form.controls.owner.setValidators([Validators.required]);
                    this.form.controls.contractor.setValidators([Validators.required]);
                }

                this.form.controls.applicationNumber.updateValueAndValidity();
                this.form.controls.template.updateValueAndValidity();
                this.form.controls.owner.updateValueAndValidity();
                this.form.controls.contractor.updateValueAndValidity();
            }),
            this.form.get('applicationNumber').valueChanges.subscribe( applicationNumber => {
                try {
                    const parsedUrl = new URL(applicationNumber);
                    const projectId = parsedUrl.pathname.match(/projects\/(\d+)/)[1];
                    this.form.controls.applicationNumber.setValue(projectId)
                } catch (err) {
                    // Unable to parse URL, so leave the value as is
                }
            })
        )
    }

    async save() {
        try {
            this.submitPending = true;

            const formValue = this.form.value;
            let user;
            if (formValue.existingApplication === 'false') {
                const companyName = formValue.owner.companyName || 'Onbekend';
                user = await lastValueFrom<User>(this.userService.findByEmailOrCreate({
                    email: formValue.owner.userPrincipalName,
                    displayName: formValue.owner.displayName || '',
                    companyName
                }));
            }

            const bouwpasApplication: CreateBouwpasApplication = {
                status: (formValue.existingApplication === 'true') ? 'COMPLETED' : 'CREATED',
                type: 'bouwpas',
                owner: (formValue.existingApplication === 'false') ? user._links.self.href : null,
                template: formValue.template?.shortDescription,
                templateId: formValue.template?.projectId,
                applicationNumber: this.form.value.applicationNumber,
                existingApplication: this.form.value.existingApplication === 'true',
                contractor: {
                    contractorId: formValue.contractor?.contractorId,
                    contractorName: formValue.contractor?.contractorName,
                },
                project: this.project._links.self.href,
                navApplication: formValue.navApplication._links.self.href,
                appStoreItem: this.app._links.self.href
            };

            await lastValueFrom<ProjectAppStoreItem>(this.projectAppStoreItemService.create(bouwpasApplication));

            this.modalRef.close(true);
        } catch (error) {
            let errorMessage = 'Opslaan mislukt.';
            if (error instanceof HttpErrorResponse) {
                if (error.status === 409) {
                    errorMessage = `Opslaan mislukt, dit type app is al geactiveerd voor dit project.`;
                } else
                if (error.status === 400) {
                    if (error.error.message) {
                        errorMessage = `Opslaan mislukt, ${error.error.message}`;
                    }
                } else if (error.status === 504) {
                    errorMessage = 'Opslaan mislukt, kon geen verbinding maken met de ESB.';
                }
            }

            console.error('Error occurred while saving application', error);
            this.toastr.error(errorMessage);
        } finally {
            this.submitPending = false;
        }
    }
}
