import {ItemSelectComponent} from './item-select.component';
import {Component, forwardRef, Inject, Input} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {Project} from '../../models/project';
import {BouwpasService} from '../../services/bouwpas.service';
import {BouwpasContractor} from '../../models/bouwpas-contractor';

@Component({
    selector: 'app-bouwpas-contractor-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BouwpasContractorSelectComponent),
            multi: true
        }
    ]
})
export class BouwpasContractorSelectComponent extends ItemSelectComponent<BouwpasContractor, {}> {

    constructor(
        @Inject('BouwpasService') private bouwpasService: BouwpasService
    ) {
        super();

        this.paginated = false;
        this.placeholder = 'Selecteer een bedrijf of bouwcombinatie';
    }

    @Input() set project(value: Project) {
        if (this.projectSubject.value !== value) {
            this.writeValue(null);
            this.refresh();
        }

        this.projectSubject.next(value);
    }

    private projectSubject = new BehaviorSubject<Project>(null);
    private readonly contractors$ = this.projectSubject.pipe(
        switchMap(project => {
            if (!project) {
                return of([])
            }
            return this.bouwpasService.getContractorsByKvw([project.kvwNumber].concat(project.bouwcombinatieKvwNumbers))
        }),
        shareReplay({bufferSize: 1, refCount: false})
    )

    getFilter(): Observable<{}> {
        return of({});
    }

    getLabelText({contractorName, isBouwCombi}: BouwpasContractor): string {
        if (isBouwCombi) {
            return `${contractorName} (combi)`;
        }

        return contractorName;
    }

    loadMoreItems(page: number, filter: { term: string }): Observable<BouwpasContractor[]> {
        if (!this.projectSubject) {
            return of([]);
        }

        return this.contractors$.pipe(
            map(response => {
                return this.filterBySearchTerm(response, filter);
            }));
    }

    private filterBySearchTerm(items: BouwpasContractor[], filter: { term: string }) {
        return items.filter(it => {
            const term = filter.term?.toLowerCase() ?? '';
            return this.getLabelText(it).toLowerCase().includes(term);
        });
    }
}
