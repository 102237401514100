import {Injectable} from '@angular/core';
import {AbstractRestServiceMock} from './abstract-rest.service.mock';
import {Application, CreateApplication, ProjectAppStoreItem, ProjectAppStoreItemWithProject} from '../../models/application';
import {ProjectAppStoreItemService} from '../project-app-store-item.service';
import {Observable, of} from 'rxjs';
import {RestLink} from '../../models/rest/rest-link';
import {Project} from '../../models/project';

export const mockApplications: Application[] = [];

@Injectable()
export class ProjectAppStoreItemMock extends AbstractRestServiceMock<ProjectAppStoreItem> implements ProjectAppStoreItemService {
    constructor() {
        super(mockApplications);
    }

    private applicationFinished = false;

    create(createItem: CreateApplication): Observable<ProjectAppStoreItem> {
        const randomId = parseInt('' + (Math.random() * 1000), 10);

        if (createItem.type === 'trimbleConnect') {
            const application: Application = {
                id: randomId,
                type: 'trimbleConnect',
                secondaryType: null,
                applicationNumber: createItem.applicationNumber,
                status: 'CREATED',
                email: 'test@test.nl',
                title: 'Trimble Connect',
                appType: 'Primary',
                description: 'Korte omschrijving',
                existingApplication: false,
                createdAt: new Date(),
                _links: {
                    self: {href: '/api/v1/applications/' + randomId},
                    appStoreItem: {href: createItem.appStoreItem}
                }
            };
            this.data.push(application);
            return of(application);
        } else if (createItem.type === 'nav') {
            const application: Application = {
                id: randomId,
                type: 'nav',
                status: 'CREATED',
                secondaryType: null,
                existingApplication: true,
                navAdministration: createItem.navAdministration,
                applicationNumber: createItem.applicationNumber,
                title: 'Navision',
                appType: 'Primary',
                description: 'Korte omschrijving',
                createdAt: new Date(),
                _links: {
                    self: {href: `/api/v1/applications/${randomId}`},
                    appStoreItem: {href: createItem.appStoreItem}
                }
            };
            this.data.push(application);

            return of(application);
        } else if (createItem.type === 'bouwpas') {
            const application: Application = {
                id: randomId,
                type: 'bouwpas',
                status: 'CREATED',
                secondaryType: null,
                title: 'Bouwpas',
                appType: 'Primary',
                description: 'Korte omschrijving',
                createdAt: new Date(),
                navApplicationName: createItem.navApplication,
                ownerName: createItem.owner,
                contractor: createItem.contractor,
                template: createItem.template,
                templateId: createItem.templateId,
                applicationNumber: null,
                _links: {
                    self: {href: `/api/v1/applications/${randomId}`},
                    owner: {href: createItem.owner},
                    navApplication: {href: createItem.navApplication},
                    appStoreItem: {href: createItem.appStoreItem}
                }
            };
            this.data.push(application);

            return of(application);
        }  else if (createItem.type === 'projectRisicoMonitor') {
            const application: Application = {
                id: randomId,
                type: 'projectRisicoMonitor',
                status: 'CREATED',
                secondaryType: null,
                title: 'Project Risico Monitor',
                appType: 'Primary',
                description: 'Korte omschrijving',
                createdAt: new Date(),
                ownerName: createItem.owner,
                applicationNumber: null,
                _links: {
                    self: {href: `/api/v1/applications/${randomId}`},
                    owner: {href: createItem.owner},
                    appStoreItem: {href: createItem.appStoreItem}
                }
            };
            this.data.push(application);

            return of(application);
        }
    }


    getCollectionRelation<L extends RestLink<Application>>(relation: L): Observable<ProjectAppStoreItem[]> {
        if (this.applicationFinished) {
            this.data.forEach(app => app.status = 'COMPLETED');
        } else {
            this.applicationFinished = true;
        }
        return super.getCollectionRelation(relation);
    }

    countByProject(project: Project) {
        return of(this.data.length);
    }

    getApplicationsBelongingToMyProjects(type: string, status: string): Observable<ProjectAppStoreItemWithProject[]> {
        return of([]);
    }

    getTrimbleExplorerActivations(appId: number): Observable<any> {
        return of([]);
    }
}
