import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {VwuiModalConfig, VwuiModalRef, VwuiModalService} from '@recognizebv/vwui-angular';
import {AppStoreItem} from '../../models/app-store';
import {Project} from '../../models/project';
import {AppStoreItemService} from '../../services/app-store-item.service';
import {ApplicationTrimbleModalComponent} from '../application-trimble-modal/application-trimble-modal.component';
import {ToastrService} from 'ngx-toastr';
import {ProjectAppStoreItemService} from '../../services/project-app-store-item.service';
import {ProjectAppStoreItem} from '../../models/application';
import {RestUtils} from '../../utils/rest-utils';
import {ProjectService} from '../../services/project.service';
import {HttpErrorResponse} from '@angular/common/http';
import {UrlMatcher} from '../../utils/regex-validators';
import {lastValueFrom} from 'rxjs';
import {CheckDependenciesResponse} from '../../models/check-dependencies-response';

@Component({
    selector: 'app-app-store-activate-modal',
    templateUrl: './app-store-activate-modal.component.html'
})
export class AppStoreActivateModalComponent implements OnInit {
    app: AppStoreItem;
    project: Project;
    form: UntypedFormGroup = new UntypedFormGroup({
        url: new FormControl('')
    });
    activateAppPending = false;
    dependencies: AppStoreItem[] = [];
    navisionApps: ProjectAppStoreItem[] = [];
    dependenciesSatisfied = true;
    missingDependencies: string[] = [];

    @Output() appClick = new EventEmitter<AppStoreItem>();

    constructor(
        public modalRef: VwuiModalRef,
        private modalService: VwuiModalService,
        @Inject('AppStoreItemService') private appStoreItemService: AppStoreItemService,
        @Inject('ProjectAppStoreItemService') private projectAppStoreItemService: ProjectAppStoreItemService,
        @Inject('ProjectService') private projectService: ProjectService,
        modalParams: VwuiModalConfig<{ app: AppStoreItem, project: Project, dependencies: AppStoreItem[] }>,
        private toast: ToastrService
    ) {
        this.app = modalParams.data.app;
        this.project = modalParams.data.project;
        this.dependencies = modalParams.data.dependencies;
        this.createForm();
    }

    async createForm() {
        this.navisionApps = (await (lastValueFrom<ProjectAppStoreItem[]>(
            this.projectAppStoreItemService.getCollectionRelation(this.project._links.projectAppStoreItems))))
            .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
            .filter(app => app.type === 'nav') || [];

        if (this.dependencies.some(dep => dep.internalTitle === 'navision')) {
            this.form.addControl('navision', new UntypedFormControl(null, [Validators.required]));
        }

        if (this.app.secondaryType === 'Addon') {
            this.form.get('url').setValidators([Validators.pattern(UrlMatcher),Validators.required]);
        } else {
            this.form.get('url').setValidators([Validators.pattern(UrlMatcher)]);
        }
    }

    async ngOnInit() {
        const [checkDependencies, navisionApps] = await Promise.all([
            lastValueFrom<CheckDependenciesResponse>(this.projectService.checkDependencies(this.project, this.app)),
            lastValueFrom<ProjectAppStoreItem[]>(this.projectAppStoreItemService.getCollectionRelation(this.project._links.projectAppStoreItems))
        ]);
        const {satisfied, unmetDependencies} = checkDependencies

        this.dependenciesSatisfied = satisfied;
        this.missingDependencies = unmetDependencies;

        if (this.form.contains('navision')) {
            this.navisionApps = navisionApps
                .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
                .filter(app => app.type === 'nav');

            this.form.get('navision').setValue(this.navisionApps[0]);
        }
    }

    async openTrimble() {
        const app = await lastValueFrom<AppStoreItem>(this.appStoreItemService.getTrimble());
        this.modalService.open(ApplicationTrimbleModalComponent, {
            modalClass: 'modal-lg',
            closeOnBackdropClick: true,
            data: {app, project: this.project}
        });
    }

    async activateApp() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            this.toast.error('Projectnaam, locatie en onderneming zijn verplicht');
            throw new Error('Couldn\'t save form, it\'s invalid');
        } else if (this.activateAppPending) {
            throw new Error('Couldn\'t save form, it\'s pending a submission');
        }

        try {
            this.activateAppPending = true;
            const activationData = {
                projectId: this.project.id,
                navAppId: null,
                url: null
            }
            if (this.form.contains('navision')) {
                activationData.navAppId = +RestUtils.getIdFromSelfLink(this.form.controls.navision.value._links.self)
            }
            activationData.url = this.form.controls.url.value
            await lastValueFrom<void>(this.appStoreItemService.activateForProject(this.app, activationData));
            this.modalRef.close(true);
        } catch (error) {
            console.error('activating app failed', error);
            if (error instanceof HttpErrorResponse && error.status === 409) {
                this.toast.error(`Deze app is al geactiveerd voor dit project.`);
            } else if (error instanceof HttpErrorResponse && error.error.message) {
                this.toast.error(`App activeren mislukt: ${error.error.message}`);
            } else {
                this.toast.error(`App activeren mislukt`);
            }
        } finally {
            this.activateAppPending = false;
        }
    }
}
