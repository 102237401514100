import {Component, forwardRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, ControlValueAccessor, UntypedFormArray, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SearchFacetGroup} from '../project-list-filter/project-list-filter.component';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-search-filter-group-simple',
    templateUrl: './search-filter-group-simple.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchFilterGroupSimpleComponent),
            multi: true
        }
    ]
})
export class SearchFilterGroupSimpleComponent implements ControlValueAccessor, OnChanges {
    @Input() group!: SearchFacetGroup;

    formArray = new UntypedFormArray([]);

    private updatingArray = false;

    limit$ = new BehaviorSubject<number>(5);
    private controls$ = new BehaviorSubject<AbstractControl[]>([]);
    limitedControls$ = combineLatest([
        this.limit$,
        this.controls$,
    ]).pipe(
        map(([limit, controls]) => {
            if (limit === 0) {
                return controls
            }
            return controls.slice(0, limit)
        }),
    );
    shouldShowMore$ = combineLatest([
        this.limit$,
        this.controls$,
    ]).pipe(
        map(([limit, controls]) => {
            return limit > 0 && controls.length > limit
        })
    )

    private onChange: (value: string[]) => void = () => {
    };
    private onTouched: () => void = () => {
    };

    constructor() {
        this.formArray.valueChanges.subscribe(value => {
            if (!this.updatingArray && this.onChange) {
                this.onChange(this.internalValueToValue(value));
                if (this.onTouched) {
                    this.onTouched();
                }
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        try {
            this.updatingArray = true;
            const backupValue = this.internalValueToValue(this.formArray.value);
            this.formArray.clear();
            for (const option of this.group.options) {
                this.formArray.push(new UntypedFormControl(false));
            }
            this.writeValue(backupValue);
        } finally {
            this.updatingArray = false;
        }

        if (this.formArray.controls.length) {
            this.controls$.next(this.formArray.controls);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(value: string[]): void {
        const internalValue = this.formArray.controls.map(() => false);
        let missingOptions = false;
        value.forEach(it => {
            const index = this.group.options.findIndex(option => option.key === it);
            if (index === -1) {
                console.warn(`couldn't write value '${it}', not found in options`);
                missingOptions = true;
            }
            internalValue[index] = true;
        });

        this.formArray.setValue(internalValue, {emitEvent: false});
        if (missingOptions) {
            setTimeout(() => {
                this.formArray.patchValue([]);
            }, 0);
        }
    }

    private internalValueToValue(arrayValue: boolean[]): string[] {
        return arrayValue
            .map((checked, index) => {
                const option = this.group.options[index];
                return (checked && option) ? option.key : null;
            })
            .filter<string>((it): it is string => it !== null);
    }
}
