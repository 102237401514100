import {Injectable} from '@angular/core';
import {AbstractRestServiceMock} from './abstract-rest.service.mock';
import {AppStoreItem, AppStoreItemActivationType} from '../../models/app-store';
import {AppActivationData, AppStoreItemService} from '../app-store-item.service';
import {mockAppStoreItemList} from './data/app-store-item-list';
import {EMPTY, Observable, of} from 'rxjs';
import {AppStoreItemStatus} from '../../models/app-store-item-status';
import {RestHttpParams, SortParams} from '../abstract-rest.service';
import {createPageResponseOf, PageResponse} from '../../models/page-response';
import {RestCollection} from '../../models/rest/rest-collection';
import {mockApplications} from './project-app-store-item.service.mock';
import {RestLink} from '../../models/rest/rest-link';

@Injectable()
export class AppStoreItemServiceMock extends AbstractRestServiceMock<AppStoreItem> implements AppStoreItemService {
    constructor() {
        super(mockAppStoreItemList);
    }

    listActivationTypes(): Observable<AppStoreItemActivationType[]> {
        return of(['Manual'])
    }

    findAll(params?: RestHttpParams, sort?: SortParams): Observable<PageResponse<AppStoreItem>> {
        return of(createPageResponseOf(
            this.data.sort((a, b) => {
                if (a.type === b.type) {
                    return a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1;
                } else {
                    return a.type > b.type ? -1 : 1;
                }
            })
        ))
    }

    existsByInternalTitle(internalTitle: string): Observable<boolean> {
        return of(
            mockAppStoreItemList.find(app => app.internalTitle === internalTitle) !== undefined
        )
    }

    countUsage(appStoreItemId: number): Observable<number> {
        return of(0);
    }

    getAvailableDependencies(): Observable<RestCollection<AppStoreItem>> {
        return of({
            _embedded: {
                dependencies: mockAppStoreItemList.filter(app => app.type === 'Primary'),
            },
            page: {
                size: 3,
                totalElements: 3,
                totalPages: 1,
                number: 1,
            }
        });
    }

    create({title}: Pick<AppStoreItem, any>): Observable<AppStoreItem> {
        const id = this.data.length + 1;
        const item: AppStoreItem = {
            id,
            internalTitle: (title as string).toLowerCase(),
            title,
            url: null,
            status: AppStoreItemStatus.Concept,
            secondaryType: null,
            activationType: 'Manual',
            type: 'Secondary',
            _links: {
                self: {href: `/api/v1/app-store-item/${id}`}
            }
        };

        this.data.push(item);

        return of(item);
    }

    searchAppStoreItems(searchFunction: string, params?: RestHttpParams, sort?: SortParams): Observable<PageResponse<AppStoreItem>> {
        return of(createPageResponseOf(this.data));
    }

    getTrimble(): Observable<AppStoreItem> {
        return of(null);
    }

    activateForProject(appStoreItem: AppStoreItem, data: AppActivationData): Observable<void> {
        mockApplications.push(
            {
                id: 1,
                title: appStoreItem.title,
                status: 'COMPLETED',
                type: 'secondary',
                secondaryType: appStoreItem.secondaryType,
                description: appStoreItem.description,
                _links: {
                    self: null,
                    project: {href: `/projects/${data.projectId}`},
                    appStoreItem: appStoreItem._links.self,
                    navisionApp: {href: `mockNavisionLink-${data.navAppId}`}
                },
                applicationNumber: '',
                appType: 'Secondary',
                navisionAdministration: null,
                navisionApplicationNumber: null,
                url: null,
                createdAt: new Date(2021, 6, 10)
            }
        )
        return EMPTY;
    }

    getTeamsUrl(id: string): Observable<string | null> {
        throw new Error('Method not implemented.');
    }

    getCollectionRelation<L extends RestLink<AppStoreItem>>(relation: L, key?: string): Observable<AppStoreItem[]> {
        console.debug(`Invoked "getCollectionRelation" method of ${this.constructor.toString().match(/\w+/g)[1]}`, relation);
        return of([]);
    }

}
