<form [formGroup]="form" (click)="labelSelectorOpen = false">
    <vwui-modal-header>
        Nieuw project
    </vwui-modal-header>
    <vwui-modal-body>
        <vwui-form-group label="Projectnaam" inputId="name" class="required">
            <div class="d-flex">
                <vwui-input class="flex-grow-1" inputId="name" formControlName="name" placeholder="Naam" autofocus></vwui-input>
                <div (click)="toggleLabelSelector(); $event.stopPropagation();$event.preventDefault()">
                    <div class="label-box">
                        <vwui-icon iconName="dot"
                                   [style.color]="form.controls.projectLabel.value?.color || noLabelColor"
                                   class="label-box__vwui-icon small-icon"></vwui-icon>
                    </div>
                </div>
            </div>
            <div *ngIf="labelSelectorOpen"
                 class="label-selector">
                <ng-container *ngIf="projectLabels$ | async as labels">
                    <div
                        class="label-selector__label mr-1 ml-1 mt-2 mb-2 pr-3"
                        (click)="setLabel(null)">
                        <span class="d-flex">
                            <vwui-icon class="ml-1 mr-2 tiny-icon text-vwui-success" iconName="dot"></vwui-icon>
                            <span>Productie</span>
                            <vwui-icon *ngIf="!form.controls.projectLabel.value"
                                       class="ml-auto mt-1 small-icon transparent"
                                       iconName="checkmark"></vwui-icon>
                        </span>
                    </div>
                    <div *ngFor="let label of labels"
                         class="label-selector__label mr-1 ml-1 mb-2 pr-3"
                         (click)="setLabel(label)">
                        <span class="d-flex align-items-center pr-1">
                            <vwui-icon class="ml-1 mr-2 tiny-icon mb-2" iconName="dot"
                                       [style.color]="label.color"></vwui-icon>
                            <span>{{label.name}}</span>
                            <vwui-icon
                                *ngIf="form.controls.projectLabel.value?._links.self.href === label._links.self.href"
                                class="ml-auto transparent small-icon" iconName="checkmark"></vwui-icon>
                        </span>
                    </div>
                </ng-container>
            </div>
        </vwui-form-group>
        <vwui-form-group label="Onderneming" inputId="company" class="required">
            <app-company-select formControlName="company"></app-company-select>
        </vwui-form-group>
        <vwui-form-group label="Locatie" class="required">
            <app-project-location-form-group
                class="project-location-form-group--no-actions"
                [initialLocation]="initialLocation"
            >
            </app-project-location-form-group>
        </vwui-form-group>
    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modalRef.close()">Annuleren</vwui-button>
        <vwui-button
            class="is-secondary"
            [disabled]="submitPending"
            [loading]="submitPending"
            (click)="submit()"
        >
            Toevoegen
        </vwui-button>
    </vwui-modal-footer>
</form>
