import {Component, Inject, OnDestroy} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {lastValueFrom, Subscription} from 'rxjs';
import {VwuiModalConfig, VwuiModalRef, VwuiModalService} from '@recognizebv/vwui-angular';
import {Project} from '../../models/project';
import {CreateTrimbleApplication, ProjectAppStoreItem} from '../../models/application';
import {HttpErrorResponse} from '@angular/common/http';
import {ProjectAppStoreItemService} from '../../services/project-app-store-item.service';
import {ToastrService} from 'ngx-toastr';
import {AppStoreItem} from '../../models/app-store';

export interface ApplicationTrimbleModalResult {
    template: string;
    project: Project;
    email: string;
}

@Component({
    selector: 'app-application-trimble-modal',
    templateUrl: './application-trimble-modal.component.html'
})
export class ApplicationTrimbleModalComponent implements OnDestroy {
    form = new UntypedFormGroup({
        template: new UntypedFormControl(null, Validators.required),
        email: new UntypedFormControl(null, Validators.email),
        applicationNumber: new UntypedFormControl(''),
        existingApplication: new UntypedFormControl('false'),
    });
    project: Project;
    submitPending = false;
    showTemplates = false;
    app: AppStoreItem;

    private subscriptions: Subscription[] = [];
    submitClicked = false;

    constructor(
        @Inject('ProjectAppStoreItemService') private applicationService: ProjectAppStoreItemService,
        private toastr: ToastrService,
        public modalRef: VwuiModalRef,
        public modalService: VwuiModalService,
        modalParams: VwuiModalConfig<{ project: Project, app: AppStoreItem }>
    ) {
        this.project = modalParams.data.project;
        this.app = modalParams.data.app;
        this.createForm();
    }

    private createForm() {
        this.subscriptions.push(
            this.form.get('applicationNumber').valueChanges.subscribe(applicationNumber => {
                try {
                    const parsedUrl = new URL(applicationNumber);
                    const searchParams = parsedUrl.searchParams;
                    let projectId = searchParams.get('projectId');
                    if (projectId == null) {
                        projectId = parsedUrl.pathname.match(/projects\/([a-zA-Z0-9-_]+)/)[1];
                    }
                    if (projectId) {
                        this.form.controls.applicationNumber.setValue(projectId);
                    }
                } catch (err) {
                    // Unable to parse URL, so leave the value as is
                }
            }),
            this.form.get('existingApplication').valueChanges.subscribe( existingApplication => {
                // make the validation conditional based on if it is an existing application
                if (existingApplication === 'true') {
                    this.form.controls.applicationNumber.setValidators([Validators.required]);
                    this.form.controls.template.clearValidators()
                } else {
                    this.form.controls.template.setValidators(Validators.required)
                    this.form.controls.applicationNumber.clearValidators();
                }
                this.form.controls.applicationNumber.updateValueAndValidity();
                this.form.controls.template.updateValueAndValidity();
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
    }

    async submit() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            this.toastr.error('Niet alle verplichte velden zijn (correct) ingevuld.');
            throw new Error('Couldn\'t save form, it\'s invalid');
        } else if (this.submitPending) {
            throw new Error('Couldn\'t save form, it\'s pending a submission');
        }

        try {
            this.submitPending = true;
            const trimbleApplication: CreateTrimbleApplication = {
                type: 'trimbleConnect',
                project: this.project._links.self.href,
                email: this.form.value.email,
                applicationNumber: this.form.value.applicationNumber,
                existingApplication: this.form.value.existingApplication === 'true',
                template: this.form.value.template,
                status: (this.form.value.existingApplication === 'true') ? 'COMPLETED' : 'CREATED',
                appStoreItem: this.app._links.self.href
            };
            lastValueFrom<ProjectAppStoreItem>(await this.applicationService.create(trimbleApplication));
            this.toastr.success('Opgeslagen');
            this.modalRef.close(true);
        } catch (error) {
            let errorMessage = 'Opslaan mislukt.';
            if (error instanceof HttpErrorResponse) {
                if (error.status === 409) {
                    errorMessage = `Opslaan mislukt, dit type app is al geactiveerd voor dit project.`;
                } else
                if (error.status === 400 && error.error.message) {
                    errorMessage = `Opslaan mislukt, ${error.error.message}`;
                }
                if (error.status === 504) {
                    errorMessage = 'Opslaan mislukt, kon geen verbinding maken met de ESB.';
                }
            }
            console.error('Error occurred while saving application', error);
            this.toastr.error(errorMessage);
        } finally {
            this.submitPending = false;
        }
    }

    onReceivedTemplates(templates: string[]) {
        if (templates.length > 1) {
            this.showTemplates = true;
        } else {
            this.showTemplates = false;
            this.form.get('template').setValue(templates[0] || 'default');
        }
    }
}
