import {RestItem} from './rest/rest-item';
import {AppStoreItemStatus} from './app-store-item-status';
import {RestLink} from './rest/rest-link';
import {Icon} from './icon';

export interface AppStoreItem extends RestItem<AppStoreItem> {
    id: number;
    internalTitle: string;
    title: string;
    url: string;
    description?: string;
    status: AppStoreItemStatus;
    secondaryType: 'TrimbleViewer' | 'TrimbleExplorer' | 'Extension' | 'Addon';
    activationType: AppStoreItemActivationType;
    iconSas?: string;
    type: 'Primary' | 'Secondary';
    _links: {
        self: RestLink<AppStoreItem>;
        dependencies?: RestLink<AppStoreItem>;
        icon?: RestLink<Icon>;
    };
}

export type AppStoreItemActivationType = 'Manual' | 'Automatic';

export const activationTypeTranslations = {
    Manual: 'Handmatig activeren',
    Automatic: 'Automatisch activeren'
};

export const appTypeTranslation = {
    Primary: 'Informatiesysteem',
    Secondary: 'Extensie'
};

export const secondaryTypeTranslation = {
    TrimbleViewer: 'Trimble Viewer',
    TrimbleExplorer: 'Trimble Verkenner',
    Extension: 'Extensie',
    Addon: 'Overige applicatie',
}
