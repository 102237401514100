import {Component} from '@angular/core';
import {VwuiModalRef} from '@recognizebv/vwui-angular';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html'
})
export class DisclaimerComponent {

  constructor(
      public modalRef: VwuiModalRef,
  ) { }

}
