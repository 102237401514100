import {Injectable} from '@angular/core';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppInsightsService {
    appInsights: ApplicationInsights;

    init() {
        if (environment.appInsightsKey) {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: environment.appInsightsKey,
                    enableAutoRouteTracking: true
                }
            });
            this.appInsights.loadAppInsights();
        }
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        if (!this.appInsights) {
            return;
        }

        this.appInsights.trackEvent({name}, properties);
    }

    logException(exception: Error, severityLevel?: number) {
        if (!this.appInsights) {
            return;
        }

        this.appInsights.trackException({exception, severityLevel});
    }
}
