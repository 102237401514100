import {ItemSelectComponent} from './item-select.component';
import {Component, forwardRef, Inject, Input} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {createPageResponseOf, PageResponse} from '../../models/page-response';
import {filter as rxFilter, map, switchMap, tap} from 'rxjs/operators';
import {Project} from '../../models/project';
import {ProjectSightService} from '../../services/project-sight.service';
import {ProjectSightPortfolio} from '../../models/project-sight.portfolio';

@Component({
    selector: 'app-project-sight-portfolio-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProjectSightPortfolioSelectComponent),
            multi: true
        }
    ]
})
export class ProjectSightPortfolioSelectComponent extends ItemSelectComponent<ProjectSightPortfolio, {}> {
    private _project: Subject<Project> = new BehaviorSubject<Project>(null);

    constructor(
        @Inject('ProjectSightService') private projectSightService: ProjectSightService
    ) {
        super();

        this.placeholder = 'Selecteer een portfolio';
    }

    @Input() set project(project: Project) {
        this._project.next(project);
    }

    getFilter(): Observable<{}> {
        return of({});
    }

    getLabelText(item: ProjectSightPortfolio): string {
        return item.name
    }

    loadMorePages(page: number, filter: { term: string }): Observable<PageResponse<ProjectSightPortfolio>> {
        return this._project.pipe(
            rxFilter(value => value !== null),
            switchMap((project: Project) => this.projectSightService.getPortfolios(project.id)),
            map((portfolios: ProjectSightPortfolio[]) => createPageResponseOf(portfolios)),
        )
    }
}
