import {Injectable} from '@angular/core';
import {AbstractRestService, AbstractRestServiceInterface, RestHttpParams, SortParams} from './abstract-rest.service';
import {HttpClient} from '@angular/common/http';
import {TrimbleUser} from '../models/trimble-user';
import {Observable} from 'rxjs';
import {PageResponse} from '../models/page-response';
import {RestCollection} from '../models/rest/rest-collection';
import {map} from 'rxjs/operators';

export interface TrimbleUserService extends AbstractRestServiceInterface<TrimbleUser> {
    search(search: string, params?: RestHttpParams, sort?: SortParams): Observable<PageResponse<TrimbleUser>>;
}

@Injectable()
export class TrimbleUserServiceImpl extends AbstractRestService<TrimbleUser> implements TrimbleUserService {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, 'trimbleUsers');
    }

    search(term: string, params?: RestHttpParams, sort?: SortParams): Observable<PageResponse<TrimbleUser>> {
        return this.httpClient.get<RestCollection<TrimbleUser>>(`/api/v1/${this.resourcePath}/search/all`, {
            params: {
                term,
                ...(params || {}),
                ...(sort ? {sort: sort.field + ',' + sort.direction} : {}),
            }
        }).pipe(map(it => ({
            content: it._embedded[this.resourceName],
            page: it.page
        })));
    }
}
