import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, take, tap} from 'rxjs/operators';
import {filteredInfiniteScrollObservable, nonPaginatedFilteredInfiniteScrollObservable} from '../../utils/pagination';
import {RestItem} from '../../models/rest/rest-item';
import {PageResponse} from '../../models/page-response';

@Component({
    template: ''
})
export abstract class ItemSelectComponent<T, F extends { [key: string]: any }> implements ControlValueAccessor, OnInit, OnDestroy {
    protected _paginated = true;

    placeholder: string;
    addTag: boolean | ((term: string) => T | Promise<T>) = false;

    formControl = new FormControl<T | null>(null);
    disabled = false;
    loading = false;
    onInput = new BehaviorSubject<string>('');
    loadMoreSubject = new BehaviorSubject<void>(null);
    items$: Observable<T[]>;
    itemsSubscription: Subscription;

    private onChange: (value: T) => void;
    private onTouched: () => void;
    private formSubscription: Subscription;

    scrollToEnd = () => this.loadMoreSubject.next(null);

    protected set paginated(paginated: boolean) {
        this._paginated = paginated;
        this.items$ = this.itemsObservable(paginated);

        if (this.itemsSubscription) {
            this.itemsSubscription.unsubscribe();
        }

        this.itemsSubscription = this.items$.pipe(take(1)).subscribe(items => {
            if (items.length === 1) {
                this.formControl.patchValue(items[0]);
            }
        });
    }

    constructor() {
        this.paginated = true;
    }

    loadMorePages(page: number, term: F & { term: string }): Observable<PageResponse<T>> {
        throw Error('Not implemented');
    }

    loadMoreItems(page: number, term: F & { term: string }): Observable<T[]> {
        throw Error('Not implemented');
    }

    abstract getLabelText(item: T): string;

    abstract getFilter(): Observable<F>;

    refresh() {
        this.items$ = this.itemsObservable(this.paginated);
    }

    compare(a: T, b: T): boolean {
        return a === b;
    }

    getSubText(item: T): string {
        return null;
    }

    registerOnChange(fn: (value: T) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.formControl.disable();
    }

    writeValue(value: T): void {
        this.formControl.patchValue(value);
    }

    ngOnInit(): void {
        this.formSubscription = this.formControl.valueChanges.subscribe((value) => {
            if (this.onChange) {
                this.onChange(value);
            }
            if (this.onTouched) {
                this.onTouched();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
    }

    private itemsObservable(paginated: boolean): Observable<T[]> {
        const searchAndFilterObservable = combineLatest([this.getFilter(), this.onInput.pipe(debounceTime(200), distinctUntilChanged())])
            .pipe(map(([filter, term]) => ({...filter, term})));

        if (paginated) {
            return filteredInfiniteScrollObservable(
                searchAndFilterObservable,
                this.loadMoreSubject,
                this.wrapLoading((page, filter) => this.loadMorePages(page, filter))
            );
        } else {
            return nonPaginatedFilteredInfiniteScrollObservable(
                searchAndFilterObservable,
                this.loadMoreSubject,
                this.wrapLoading((page, filter) => this.loadMoreItems(page, filter))
            );
        }
    }

    private wrapLoading<R>(loadingFunc: (page, filter) => Observable<R>) {
        this.loading = true;

        return (page, filter) => loadingFunc(page, filter).pipe(
            tap(() => this.loading = false)
        );
    }
}

export abstract class EntitySelectComponent<E extends RestItem<E>, F> extends ItemSelectComponent<E, F> {
    compare(a: E, b: E): boolean {
        if (!a || !b || !a._links || !b._links) {
            return false;
        }

        return a._links.self === b._links.self;
    }
}
