import {Directive, ElementRef, HostBinding, Inject, Input, OnDestroy} from '@angular/core';
import {AppStoreItem} from '../models/app-store';
import {AppStoreItemService} from '../services/app-store-item.service';
import {RestUtils} from '../utils/rest-utils';
import {of, Subject, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Directive({
    selector: '[appDisabledWhenAppStoreItemUsed]'
})
export class DisabledWhenAppStoreItemUsedDirective implements OnDestroy {

    @HostBinding('disabled') disabled = true;

    private subscription: Subscription[] = [];
    private changeSubject = new Subject<AppStoreItem|null>();

    constructor(
        private elementRef: ElementRef,
        @Inject('AppStoreItemService') private appStoreItemService: AppStoreItemService
    ) {
        this.subscription.push(
            this.changeSubject.pipe(
                switchMap(item => {
                    if (item !== null) {
                        const appItemId = +RestUtils.getIdFromSelfLink(item._links.self);
                        return this.appStoreItemService.countUsage(appItemId);
                    } else {
                        return of(0);
                    }
                })
            ).subscribe(usages => {
                this.disabled = usages > 0;
            })
        );
    }

    @Input() set appDisabledWhenAppStoreItemUsed(appStoreItem: AppStoreItem) {
        this.changeSubject.next(appStoreItem);
    };

    ngOnDestroy(): void {
        this.subscription.forEach(sub => sub.unsubscribe());
    }
}
