import {Company} from '../../models/company';
import {CompanyService} from '../company.service';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';

export const mockCompanies: Company[] = [
    {
        name: 'Recognize',
        kvwNumber: '1234',
        parentKvwNumbers: ['0008'],
        operationalClassification: 'Region/BU'
    },
    {
        name: 'Extra company',
        kvwNumber: '0022',
        parentKvwNumbers: [],
        operationalClassification: 'Company'
    }
];

@Injectable()
export class CompanyServiceMock implements CompanyService {
    listMine(): Observable<Company[]> {
        return of(mockCompanies);
    }

    listMineWithChildren(): Observable<Company[]> {
        return of(mockCompanies);
    }

    listAll(): Observable<Company[]> {
        return of(mockCompanies);
    }
}
