import {AppStoreItem} from '../../../models/app-store';
import {AppStoreItemStatus} from '../../../models/app-store-item-status';

export const mockAppStoreItemList: AppStoreItem[] = [
    {
        id: 2,
        internalTitle: 'afroepen',
        title: 'Afroepen',
        url: 'https://www.app-store-2.nl',
        secondaryType: null,
        status: AppStoreItemStatus.Concept,
        activationType: 'Manual',
        type: 'Secondary',
        _links: {
            self: {href: '/api/v1/app-store-item/2'}
        }
    },{
        id: 8,
        internalTitle: 'overig',
        title: 'Overig',
        url: 'https://www.test.nl',
        secondaryType: 'Addon',
        status: AppStoreItemStatus.Active,
        activationType: 'Manual',
        type: 'Secondary',
        _links: {
            self: {href: '/api/v1/app-store-item/8'}
        }
    },
    {
        id: 3,
        title: 'Navision',
        url: 'https://www.navision.nl',
        secondaryType: null,
        status: AppStoreItemStatus.Concept,
        activationType: 'Manual',
        internalTitle: 'navision',
        type: 'Primary',
        _links: {
            self: {href: '/api/v1/app-store-item/3'}
        }
    },
    {
        id: 1,
        title: 'Trimble Connect',
        internalTitle: 'trimble connect',
        url: 'https://www.trimble.nl',
        secondaryType: null,
        status: AppStoreItemStatus.Concept,
        activationType: 'Manual',
        type: 'Primary',
        _links: {
            self: {href: '/api/v1/app-store-item/1'}
        }
    },
    {
        id: 4,
        title: 'Bouwpas',
        internalTitle: 'bouwpas',
        url: 'https://www.bouwpas.nl',
        secondaryType: null,
        status: AppStoreItemStatus.Active,
        activationType: 'Manual',
        type: 'Primary',
        _links: {
            self: {href: '/api/v1/app-store-item/4'}
        }
    },
    {
        id: 5,
        title: 'Project Risico Monitor',
        internalTitle: 'PRM',
        url: 'https://prm.volkerwessels.app',
        secondaryType: null,
        status: AppStoreItemStatus.Active,
        activationType: 'Manual',
        type: 'Primary',
        _links: {
            self: {href: '/api/v1/app-store-item/5'}
        }
    },
];
