import {Directive, ElementRef, Inject, Input, OnDestroy} from '@angular/core';
import {AuthorizationService} from '../services/authorization.service';
import {Subscription} from 'rxjs';
import {Project} from '../models/project';

@Directive({
    selector: '[appReadonlyIfCannotManageProject]'
})
export class ReadonlyIfCannotManageProjectDirective implements OnDestroy {

    private subscriptions: Subscription[] = [];
    @Input() project: Project;

    constructor(
        private element: ElementRef,
        @Inject('AuthorizationService') private authorizationService: AuthorizationService,
    ) {
        this.subscriptions.push(
            this.authorizationService.canUpdateDeleteProject$(this.project).subscribe(canUpdateOrDeleteProject => {
                if (!canUpdateOrDeleteProject) {
                    this.element.nativeElement.readonly = true;
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
