import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import type * as atlasTypes from 'azure-maps-control';
import type {SymbolLayerOptions} from 'azure-maps-control';
import {environment} from '../../../environments/environment';
import * as atlasRest from 'azure-maps-rest';
import {AzureMapsRestService} from '../../services/azure-maps-rest.service';
import {AuthorizationService} from '../../services/authorization.service';
import {ProjectService} from '../../services/project.service';
import {BehaviorSubject, combineLatest, lastValueFrom, of, Subject} from 'rxjs';
import {debounceTime, filter, map, switchMap} from 'rxjs/operators';
import type {SpiderClusterManager as SpiderClusterManagerType} from '../../utils/spider/SpiderClusterManager';
import {createEmptyPageResponse} from '../../models/page-response';
import {Project} from '../../models/project';
import Coordinate = atlasRest.Models.Coordinate;
import SearchAddressReverseResult = atlasRest.Models.SearchAddressReverseResult;

let atlasPromise: Promise<typeof atlasTypes> = null;
const getAtlas = () => {
    if (atlasPromise === null) {
        atlasPromise = import('azure-maps-control');
    }
    return atlasPromise
}

@Component({
    selector: 'app-azure-map',
    templateUrl: './azure-map.component.html'
})
export class AzureMapComponent implements AfterViewChecked, OnDestroy {
    @Input() initialLocation: { latitude: number; longitude: number };

    @ViewChild('azureMap', {static: true}) private map: ElementRef;
    private azureMap: atlasTypes.Map;

    private initialCoordinates = [5.5, 52];
    private initialZoom = 10;
    private initialOverwritten = false;
    private popupTemplate = '<div class="p-2">{name}</div>';

    private marker: atlasTypes.HtmlMarker = null;
    private existingProjectMarkers: atlasTypes.HtmlMarker[] = [];

    private atlasSubject = new BehaviorSubject<typeof atlasTypes>(null);
    private viewCheckedSubject = new BehaviorSubject<any>(0);
    private moveEndSubject = new Subject<void>();

    private dataSource: atlasTypes.source.DataSource

    private onAfterChecked = combineLatest([this.atlasSubject.asObservable(), this.viewCheckedSubject.asObservable()]).pipe(
        filter(([atlas, _]) => atlas !== null)
    ).subscribe(async ([atlas]) => {
        if (!this.azureMap && this.map.nativeElement.offsetWidth > 0) {
            this.azureMap = new atlas.Map(this.map.nativeElement, {
                language: 'nl-NL',
                showLogo: false,
                authOptions: {
                    authType: atlas.AuthenticationType.subscriptionKey,
                    subscriptionKey: environment.azureMapsKey
                },
                center: this.initialCoordinates,
                zoom: this.initialZoom,
                disableTelemetry: true
            });
            this.azureMap.controls.add(new atlas.control.ZoomControl(), {
                position: atlas.ControlPosition.BottomRight
            });

            this.azureMap.events.add('moveend', () => {
                this.moveEndSubject.next();
            });

            await this.initializeLayers(atlas)

            if (await lastValueFrom<boolean>(this.authorizationService.canCreateProject$) && !this.disabled) {
                this.azureMap.events.add('click', e => {
                    const coordinate = {longitude: e.position[0], latitude: e.position[1]};
                    this.handleOnClick(coordinate, atlas);
                });
            }

            if (this.initialOverwritten) {
                const coordinate = {longitude: this.initialCoordinates[0], latitude: this.initialCoordinates[1]};
                this.handleOnClick(coordinate, atlas);

                this.replaceMarker(atlas, {
                    longitude: this.initialCoordinates[0],
                    latitude: this.initialCoordinates[1]
                });
            }
            setTimeout(() => this.azureMap.resize(), 200);
        }
    });

    private handleOnClick(coordinate: { latitude: number; longitude: number }, atlas) {
        this.azureMapsRest.reverseSearch(coordinate).subscribe(data => {
            const searchResult = data.addresses[0];
            if (searchResult) {
                this.clickedAddress.emit({
                    ...searchResult,
                    position: `${coordinate.latitude.toFixed(5)},${coordinate.longitude.toFixed(5)}`
                });
                this.replaceMarker(atlas, coordinate);
            }
        });
    }

    private onMoveEnd = combineLatest([
        this.atlasSubject.asObservable(),
        this.moveEndSubject.asObservable()
    ]).pipe(
        filter(([atlas]) => atlas !== null && this.azureMap !== null && !!this.dataSource),
        debounceTime(100),
        switchMap(([atlas]) => {
            if (this.azureMap.getCamera().zoom > 10) {
                const [longitude, latitude] = this.azureMap.getCamera().center;

                return this.projectService.searchProjects('findProjectsWithCompanyNearProject', {
                        projectId: this.projectId?.toString() || '0',
                        latitude: latitude.toString(),
                        longitude: longitude.toString(),
                        distance: '5',
                    },
                    {
                        field: 'city',
                        direction: 'desc'
                    }).pipe(
                    map(response => ({atlas, response}))
                )
            } else {
                return of({atlas, response: createEmptyPageResponse<Project>()})
            }
        })
    ).subscribe(({atlas, response}) => {
        this.dataSource.clear();
        this.dataSource.add(
            response.content.map(project => {
                return new atlas.data.Feature(new atlas.data.Point([project.longitude, project.latitude]), {
                    name: project.name,
                })
            }));
    });
    private spiderManager: SpiderClusterManagerType;

    @Input() set coordinates(coordinates: Coordinate) {
        if (coordinates && coordinates.longitude && coordinates.latitude) {
            const atlas = this.atlasSubject.value;

            if (this.azureMap && atlas !== null) {
                lastValueFrom<boolean>(this.authorizationService.canCreateProject$).then(canEditOrCreate => {
                    if (canEditOrCreate) {
                        this.replaceMarker(atlas, coordinates);
                    }
                });
                this.azureMap.setCamera({
                    center: [coordinates.longitude, coordinates.latitude],
                    zoom: 16
                });
            } else {
                this.initialCoordinates = [coordinates.longitude, coordinates.latitude];
                this.initialZoom = 16;
                this.initialOverwritten = true;
            }
        }
    }

    @Input() projectId: number;
    @Input() disabled = false;

    @Output() clickedAddress = new EventEmitter<SearchAddressReverseResult>();

    constructor(
        @Inject('AzureMapsRestService') private azureMapsRest: AzureMapsRestService,
        @Inject('AuthorizationService') private authorizationService: AuthorizationService,
        @Inject('ProjectService') private projectService: ProjectService,
    ) {
        getAtlas().then(atlas => this.atlasSubject.next(atlas));
    }

    private async initializeLayers(atlas: typeof atlasTypes) {
        this.azureMap.events.add('ready', async () => {
            this.dataSource = new atlas.source.DataSource(null, {
                cluster: true,
            });

            const otherProjectLayer = new atlas.layer.SymbolLayer(this.dataSource, undefined, {
                iconOptions: {
                    image: 'orange-marker'
                },
                filter: ['!', ['has', 'point_count']],
            });

            const clusterBubbleLayer = new atlas.layer.BubbleLayer(this.dataSource, undefined, {
                radius: 20,
                color: '#FFA500',
                strokeWidth: 0,
                // Only rendered data points which have a point_count property, which clusters do.
                filter: ['has', 'point_count']
            });

            await this.azureMap.imageSprite.createFromTemplate('orange-marker', 'marker', '#FFA500');

            const clusterCountLayer = new atlas.layer.SymbolLayer(this.dataSource, undefined, {
                iconOptions: {
                    image: 'none'
                },
                textOptions: {
                    textField: ['get', 'point_count_abbreviated'],
                    offset: [0, 0.4],
                    color: 'white'
                }
            } as SymbolLayerOptions)

            this.azureMap.sources.add(this.dataSource)

            this.azureMap.layers.add([
                clusterBubbleLayer,
                clusterCountLayer,
                otherProjectLayer
            ]);

            // This async import allows Webpack to to put all of azure maps in a separate lazy chunk
            (import('../../utils/spider/SpiderClusterManager').then(({SpiderClusterManager}) => {
                this.spiderManager = new SpiderClusterManager(
                    this.azureMap,
                    clusterBubbleLayer,
                    otherProjectLayer,
                    {minCircleLength: 45});

                // Popups need the spider feature layer generated by the spider cluster manager
                this.initializeMarkerPopups(atlas, otherProjectLayer);
            }))

            // Retrigger the retrieval of other projects in case they were retrieved before the map was ready
            this.moveEndSubject.next();
        });
    }

    ngOnDestroy(): void {
        if (this.spiderManager) {
            this.spiderManager.dispose();
            this.spiderManager = null;
        }
        if (this.azureMap) {
            this.azureMap.dispose();
            this.azureMap = null;
        }
        if (this.onMoveEnd) {
            this.onMoveEnd.unsubscribe();
        }
        if (this.onAfterChecked) {
            this.onAfterChecked.unsubscribe();
        }
    }

    async replaceMarker(atlas: typeof atlasTypes, coordinates: Coordinate) {
        if (this.marker !== null) {
            this.azureMap.markers.remove(this.marker);
        }
        this.marker = new atlas.HtmlMarker({
            color: '#008cbc',
            position: [coordinates.longitude, coordinates.latitude]
        });
        this.azureMap.markers.add(this.marker);
    }

    async ngAfterViewChecked() {
        this.viewCheckedSubject.next(this.viewCheckedSubject.value + 1);
    }

    private initializeMarkerPopups(atlas: typeof atlasTypes, pointLayer: atlasTypes.layer.SymbolLayer) {
        const popup = new atlas.Popup({
            pixelOffset: [0, -35],
            closeButton: false
        });
        const popupCallback = (event) => {
            if (event.shapes && event.shapes.length > 0) {
                // tslint:disable-next-line:no-string-literal
                const pointData = event.shapes[0]['data'];
                if (pointData.geometry.type !== 'Point') {
                    return;
                }

                popup.setOptions({
                    position: pointData.geometry.coordinates,
                    content: this.popupTemplate.replace(/{name}/g, pointData.properties.name),
                })

                popup.open(this.azureMap);
            }
        }

        this.azureMap.events.add('mouseenter', [pointLayer, this.spiderManager.spiderFeatureLayer], popupCallback);
        this.azureMap.events.add('mouseleave', [pointLayer, this.spiderManager.spiderFeatureLayer], () => {
            popup.close();
        });
    }
}
