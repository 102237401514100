import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ProjectAttributeResponse} from '../models/mdm/project-attribute-response';
import {Observable} from 'rxjs';
import {
    transformProjectAttributeResponseToSelectOptions,
    transformProjectSubUsageFunctionsToSelectOptions,
    transformProjectUsageFunctionResponseToSelectOptions,
    unwrapProjectAttributeResponse
} from '../utils/project-attributes';
import {map, shareReplay, tap} from 'rxjs/operators';

export interface MDMService {
    getOperationalProjectPhasesOptions(): Observable<{ key: string, value: string; }[]>;
    getSubUsageFunctionsOptions(parent: string[]): Observable<{ key: string, value: string; }[]>;
    getSustainabilityRatingsOptions(): Observable<{ key: string, value: string; }[]>;
    getUsageFunctionsOptions(): Observable<{ key: string, value: string; }[]>;
    getVolkerWesselsConceptsOptions(): Observable<{ key: string, value: string; }[]>;
    getWorkTypesOptions(): Observable<{ key: string, value: string; }[]>;
}

@Injectable()
export class MDMServiceImpl implements MDMService {
    operationalProjectPhases$ = this.http.get<ProjectAttributeResponse>('/api/v1/trimble/project-attributes/project-phases').pipe(
        shareReplay(1)
    );

    subUsageFunctions$ = this.http.get<ProjectAttributeResponse>('/api/v1/trimble/project-attributes/sub-usage-functions').pipe(
        shareReplay(1)
    );

    sustainabilityRatings$ = this.http.get<ProjectAttributeResponse>('/api/v1/trimble/project-attributes/sustainability-ratings').pipe(
        shareReplay(1)
    );

    usageFunctions$ = this.http.get<ProjectAttributeResponse>('/api/v1/trimble/project-attributes/usage-functions').pipe(
        shareReplay(1)
    );

    volkerWesselsConcepts$ = this.http.get<ProjectAttributeResponse>('/api/v1/trimble/project-attributes/volkerwessels-concepts').pipe(
        shareReplay(1)
    );

    workTypes$ = this.http.get<ProjectAttributeResponse>('/api/v1/trimble/project-attributes/work-types').pipe(
        shareReplay(1)
    );

    getOperationalProjectPhasesOptions() {
        return this.operationalProjectPhases$.pipe(
            transformProjectAttributeResponseToSelectOptions(),
        );
    }

    getSubUsageFunctionsOptions(parents: string[]) {
        return this.subUsageFunctions$.pipe(
            unwrapProjectAttributeResponse(),
            map(values =>
                values.filter(value => parents.some(parent => value.parent.includes(parent)))
            ),
            transformProjectSubUsageFunctionsToSelectOptions(),
        );
    }

    getSustainabilityRatingsOptions() {
        return this.sustainabilityRatings$.pipe(
            transformProjectAttributeResponseToSelectOptions()
        );
    }

    getUsageFunctionsOptions() {
        return this.usageFunctions$.pipe(
            transformProjectUsageFunctionResponseToSelectOptions()
        );
    }

    getVolkerWesselsConceptsOptions() {
        return this.volkerWesselsConcepts$.pipe(
            transformProjectAttributeResponseToSelectOptions()
        );
    }

    getWorkTypesOptions() {
        return this.workTypes$.pipe(
            transformProjectAttributeResponseToSelectOptions()
        );
    }

    constructor(private http: HttpClient) {
    }
}
