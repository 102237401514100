<vwui-header>
    Projectlabels beheren
    <vwui-button slot="action" icon="vwui-add" class="mt-1"
                 [disabled]="modalOpen"
                 (click)="openLabelModal()">Projectlabel toevoegen
    </vwui-button>
</vwui-header>

<div *ngIf="paginator$ | async as paginator;">
    <ng-container *ngIf="paginator.content$ | async as labels">
        <vwui-table tableTitle="Projectlabels" [compactable]="false">
            <app-pagination slot="table-pagination" [paginator]="paginator"></app-pagination>

            <vwui-table-row>
                <vwui-table-header>Naam</vwui-table-header>
                <vwui-table-header>Kleur</vwui-table-header>
                <vwui-table-header>Actief</vwui-table-header>
            </vwui-table-row>
            <vwui-table-row
                *ngFor="let label of labels"
                [clickable]="true"
                (click)="openLabelModal(label)"
            >
                <vwui-table-column>{{label.name}}</vwui-table-column>
                <vwui-table-column>
                    <vwui-icon class="small-icon" iconName="dot" [style.color]="label.color">
                    </vwui-icon>
                </vwui-table-column>
                <vwui-table-column>{{label.active ? 'Ja' : 'Nee'}}</vwui-table-column>
                <vwui-table-column class="d-flex">
                    <div class="ml-auto align-items-center d-flex">
                        <div *ngIf="!label.used" class="action-icon align-items-center">
                            <vwui-icon class="action-icon__vwui-icon medium-icon"
                                       (click)="delete(label); $event.stopPropagation()"
                                       iconName="vwui-trash">
                            </vwui-icon>
                        </div>
                        <div class="action-icon align-items-center ml-1">
                            <vwui-icon class="action-icon__vwui-icon medium-icon"
                                       iconName="vwui-edit">
                            </vwui-icon>
                        </div>
                    </div>
                </vwui-table-column>
            </vwui-table-row>
        </vwui-table>
    </ng-container>
</div>

