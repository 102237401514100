import {ItemSelectComponent} from './item-select.component';
import {Component, forwardRef, Inject} from '@angular/core';
import {Observable, of} from 'rxjs';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {NavisionAdministration} from '../../models/navision-administration';
import {NavisionAdministrationService} from '../../services/navision-administration.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-navision-administration-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NavisionAdministrationSelectComponent),
            multi: true
        }
    ]
})
export class NavisionAdministrationSelectComponent extends ItemSelectComponent<NavisionAdministration, {}> {
    constructor(
        @Inject('NavisionAdministrationService') private navisionAdministrationService: NavisionAdministrationService,
    ) {
        super();

        this.paginated = false;
        this.placeholder = 'Selecteer een Navision-administratie';
    }

    getFilter(): Observable<{}> {
        return of({});
    }

    getLabelText(item: NavisionAdministration): string {
        return item.administration;
    }

    loadMoreItems(page: number, filter: { term: string }): Observable<NavisionAdministration[]> {
        const skip = 100 * page;
        const take = 100;

        return this.navisionAdministrationService.list(skip, take).pipe(
            map(response => response.administrations)
        );
    }
}
