<div class="detail-modal-header">
    <vwui-modal-header>
        <div class="d-flex">
            <div class="item-card__icon">
                <div *ngIf="app.iconSas" class="app-store-icon">
                    <img [src]="app.iconSas">
                </div>
            </div>
            <div>
                <vwui-breadcrumb>
                    <span>Appstore</span>
                </vwui-breadcrumb>
                <vwui-breadcrumb>
                    <span>{{app.title}}</span>
                </vwui-breadcrumb>
                <h3>{{app.title}}</h3>
            </div>
        </div>
    </vwui-modal-header>
</div>

<vwui-modal-body>
    <vwui-layout type="split">
        <div slot="split-left">
            <h3>Omschrijving</h3>
            <div [innerHTML]="app.description"></div>
        </div>

        <div slot="split-right" [formGroup]="form" class="app-details-form">
            <h3 class="mb-2">Applicatie activeren</h3>
            <div class="mb-2">Vul onderstaande gegevens in en klik op ‘activeren’.</div>

            <vwui-form-group label="Portfolio">
                <app-project-sight-portfolio-select [project]="project" formControlName="portfolio"></app-project-sight-portfolio-select>
            </vwui-form-group>

            <vwui-form-group label="Projectadmin">
                <ng-select [items]="form.controls.portfolio.value?.users ?? []" bindLabel="name"
                           [notFoundText]="!form.controls.portfolio.value ? 'Selecteer eerst een portfolio' : 'Persoon niet bekend als teamlid in ProjectSight'"
                           placeholder="Selecteer een projectadmin" formControlName="projectAdmin" appendTo="body"></ng-select>
            </vwui-form-group>

            <vwui-form-group label="Trimble Connect Template">
                <app-template-select formControlName="trimbleConnectTemplate" [project]="project"></app-template-select>
            </vwui-form-group>

            <vwui-form-group label="ProjectSight sjabloon project (optioneel)">
                <app-project-app-store-item-select
                    placeholder="Selecteer een sjabloon project"
                    type="ProjectSightApplication"
                    status="COMPLETED"
                    formControlName="copyFromProject"></app-project-app-store-item-select>

                <div class="pt-3">
                    <vwui-checkbox formControlName="copyProjectSettings">Projectinstellingen</vwui-checkbox>
                </div>
                <div class="pt-2">
                    <vwui-checkbox formControlName="copyUsers">Gebruikers</vwui-checkbox>
                </div>
            </vwui-form-group>

            <vwui-button class="is-primary"
                         [disabled]="!form.valid || submitPending"
                         [loading]="submitPending"
                         (click)="save()">
                Activeren
            </vwui-button>
        </div>
    </vwui-layout>

</vwui-modal-body>

<vwui-modal-footer>
    <vwui-button class="is-light" (click)="modalRef.close()">Venster sluiten</vwui-button>
</vwui-modal-footer>
