import {Injectable} from '@angular/core';
import {AbstractRestServiceMock} from './abstract-rest.service.mock';
import {TrimbleUser} from '../../models/trimble-user';
import {TrimbleUserService} from '../trimble-user.service';
import {Observable, of} from 'rxjs';
import {RestHttpParams, SortParams} from '../abstract-rest.service';
import {createPageResponseOf, PageResponse} from '../../models/page-response';

export const mockTrimbleUsers: TrimbleUser[] = [
    {
        name: 'Trimble Piet',
        email: 'piet@trimble.com',
        _links: {
            self: {href: '/api/v1/trimble-users/1'}
        }
    },
    {
        name: 'Gijsje Radijsje',
        email: 'gijsje319@live.nl',
        _links: {
            self: {href: '/api/v1/trimble-users/2'}
        }
    },
];

let trimbleUserId = 3;

@Injectable()
export class TrimbleUserServiceMock extends AbstractRestServiceMock<TrimbleUser> implements TrimbleUserService {
    constructor() {
        super(mockTrimbleUsers);
    }

    create(createItem: TrimbleUser): Observable<TrimbleUser> {
        const trimbleUser = {
            ...createItem,
            _links: {
                self: {href: '/api/v1/trimble-users/' + trimbleUserId++}
            }
        };

        this.data.push(trimbleUser);

        return of(trimbleUser);
    }

    search(search: string, params: RestHttpParams, sort?: SortParams): Observable<PageResponse<TrimbleUser>> {
        return of(
            createPageResponseOf(
                this.data.filter(item =>
                    item.name.toLowerCase().includes(search) ||
                    item.email.toLowerCase().includes(search))
            )
        );
    }
}
