import {Component, EventEmitter, Inject, Input, OnDestroy, Output} from '@angular/core';
import {Project} from '../../models/project';
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ProjectService} from '../../services/project.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {concatMap, filter, map, toArray, withLatestFrom} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, from, lastValueFrom, Observable, pipe, Subscription} from 'rxjs';
import {AuthorizationService} from '../../services/authorization.service';
import {VWUIFlatpickrOptions} from '@recognizebv/vwui-core';
import {MDMService} from '../../services/mdm.service';
import {CompanyService} from '../../services/company.service';
import {Company} from '../../models/company';

type KvwNumber = { label: string, kvwNumber: string };

@Component({
    selector: 'app-project-detail-tab',
    templateUrl: './project-detail-tab.component.html'
})
export class ProjectDetailTabComponent implements OnDestroy {
    private readonly descriptionLimit = 1024;
    @Output() projectUpdated = new EventEmitter<void>();
    form: UntypedFormGroup;
    submitPending = false;
    project$ = new BehaviorSubject<Project>(null);
    readonly$ = new BehaviorSubject<boolean>(false);
    kvwNumberList$: Observable<KvwNumber[]>;

    datePickerOptions: VWUIFlatpickrOptions = {
        dateFormat: 'Y-m-dTH:i',
        altFormat: 'd-m-Y'
    }

    operationalProjectsStatusList$ = this.mdmService.getOperationalProjectPhasesOptions();

    private subscriptions: Subscription[] = [];
    private startDateSubscription: Subscription;
    private endDateSubscription: Subscription;

    constructor(
        @Inject('AuthorizationService') private authorizationService: AuthorizationService,
        @Inject('MDMService') private mdmService: MDMService,
        @Inject('ProjectService') private projectService: ProjectService,
        @Inject('CompanyService') private companyService: CompanyService,
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
    ) {
        this.kvwNumberList$ = this.companyService.listAll(true).pipe(
            concatMap(arr => from(arr)),
            withLatestFrom(this.project$),
            filter(([company, project]: [Company, Project]) => {
                // Exclude the current projects company from the list
                return company.kvwNumber !== project.kvwNumber;
            }),
            map(([company]) => ({
                label: company.kvwNumber ? (company.kvwNumber + ' - ' + company.name) : company.name,
                kvwNumber: company.kvwNumber
            })),
            toArray()
        )
        this.subscriptions.push(
            combineLatest([this.project$, this.readonly$]).pipe(
                filter(([project]) => !!project)
            ).subscribe(([project, readonly]) => {
                this.form = this.createForm(project);
                if (readonly) {
                    setTimeout(() => {
                        this.form.disable();
                    }, 0);
                }
            })
        );
    }
    @Input()
    set readonly(readonly: boolean) {
        this.readonly$.next(readonly);
    }

    @Input()
    set project(project: Project) {
        this.project$.next(project);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    async save() {
        try {
            await this.patchProject();
        } catch (e) {
            console.error('Save failed', e);
        }
    }

    async saveAndContinue() {
        try {
            await this.patchProject();
            await this.router.navigate([], {relativeTo: this.route, queryParams: {tab: 'location'}});
        } catch (e) {
            console.error('SaveAndContinue failed', e);
        }
    }

    private async patchProject() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            throw new Error('Couldn\'t save form, it\'s invalid');
        } else if (this.submitPending) {
            throw new Error('Couldn\'t save form, it\'s pending a submission');
        }

        try {
            this.submitPending = true;
            const {image, ...rest} = this.form.value;

            if (!image) {
                await lastValueFrom(this.projectService.deleteRelation(this.project$.value._links.image));
            }

            const {projectLabel, ...projectData} = this.project$.value;

            rest.operationalProjectStatusGUID = [rest.operationalProjectStatusGUID];
            const newProject = await lastValueFrom(this.projectService.patch({
                ...projectData,
                ...rest
            }));
            this.project$.next(newProject);

            this.toastr.success('Opgeslagen');
            this.projectUpdated.emit();
        } catch (error) {
            console.error('Unable to save project', error);
            this.toastr.error('Opslaan mislukt.');
            throw error;
        } finally {
            this.submitPending = false;
        }
    }

    private createForm(project: Project): UntypedFormGroup {
        const form = new UntypedFormGroup({
            name: new UntypedFormControl(project ? project.name : null, [Validators.required, Validators.maxLength(255)]),
            commercialName: new UntypedFormControl(project ? project.commercialName : null, [Validators.maxLength(255)]),
            code: new UntypedFormControl({value: project ? project.code : null, disabled: true}),
            startDate: new UntypedFormControl(project ? project.startDate : null),
            endDate: new UntypedFormControl(project ? project.endDate : null),
            companyName: new UntypedFormControl({
                value: project ? project.kvwNumber ? (project.kvwNumber + ' - ' + project.companyName) : project.companyName : null,
                disabled: true
            }),
            description: new UntypedFormControl(project?.description,[Validators.maxLength(1024)]),
            image: new UntypedFormControl(project ? project._links.image : null),
            operationalProjectStatusGUID: new UntypedFormControl(project ? project.operationalProjectStatusGUID[0] : null, [Validators.required]),
            bouwcombinatieKvwNumbers: new FormControl(project.bouwcombinatieKvwNumbers)
        });

        if (this.startDateSubscription) {
            this.startDateSubscription.unsubscribe();
        }
        if (this.endDateSubscription) {
            this.endDateSubscription.unsubscribe();
        }

        // Change the time to noon so that this will give the same date for BVGO ICT even when they parse it with the wrong timezone
        const changeTimeToNoon = (control: string) => {
            return (date) => {
                const clonedDate = new Date(date);
                clonedDate.setHours(12, 0, 0);

                form.get(control).setValue(clonedDate, {emitEvent: false});
            };
        };

        this.startDateSubscription = form.get('startDate').valueChanges.subscribe(changeTimeToNoon('startDate'));
        this.endDateSubscription = form.get('endDate').valueChanges.subscribe(changeTimeToNoon('endDate'));

        return form;
    }

    getValue(type: string): string{
        return this.form.get(type).value;
    }
    stringLimitReached(type:string, limit = 1024) {
        return this.getValue(type)?.length >= limit;
    }
    descriptionLimitText(type:string){
        const value = this.getValue(type) || '';
        return this.stringLimitReached(type) ?
            `Maximaal ${this.descriptionLimit} karakters. (${value.length - this.descriptionLimit} te veel)`:
            `Maximaal ${this.descriptionLimit} karakters (${this.descriptionLimit - value.length} over)`;
    }
}
