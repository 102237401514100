import {AbstractControl} from '@angular/forms';

const pattern = /^[1-9][0-9]{3}[\s]?([A-Za-z]{2})?$/g;

export function validatePostcode(control: AbstractControl) {
    const value = '' + control.value;

    if (!value.match(pattern)) {
        return {invalidPostcode: true};
    }

    return null;
}
