import {Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormGroupDirective, UntypedFormGroup} from '@angular/forms';
import * as atlas from 'azure-maps-rest';
import {AuthorizationService} from '../../services/authorization.service';
import {Project} from '../../models/project';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError, debounceTime, filter, mergeMap} from 'rxjs/operators';
import {createEmptyAzureSearchResponse} from '../../models/azure-maps';
import {AzureMapsRestService} from '../../services/azure-maps-rest.service';
import SearchAddressReverseResult = atlas.Models.SearchAddressReverseResult;
import Coordinate = atlas.Models.Coordinate;

@Component({
    selector: 'app-project-location-form-group',
    templateUrl: './project-location-form-group.component.html'
})
export class ProjectLocationFormGroupComponent implements OnInit, OnDestroy, OnChanges {
    private subscriptions: Subscription[] = [];
    private readonlySubscription: Subscription;
    private _readonly$ = new BehaviorSubject<boolean>(false);

    @Input() project: Project;
    @Input() initialLocation: { latitude: number, longitude: number } | undefined;
    form: UntypedFormGroup;

    projectCoordinates: Coordinate;
    showLocationForm = false;
    canUpdateDeleteProject$: Observable<boolean>;

    searchString$ = new BehaviorSubject<string>(null);
    azureSearchResponse$: Observable<atlas.Models.SearchFuzzyResponse> = this.searchString$.pipe(
        debounceTime(200),
        filter(it => !!it),
        mergeMap(search => this.azureMapsRest.findFuzzy(search, 5).pipe(
            catchError(() => of(createEmptyAzureSearchResponse()))
        ))
    );

    constructor(
        @Inject('AzureMapsRestService') private azureMapsRest: AzureMapsRestService,
        @Inject('AuthorizationService') private authorizationService: AuthorizationService,
        private controlContainer: FormGroupDirective,
    ) {
    }

    @Input() set readonly$(readonly$: Observable<boolean>) {
        if (this.readonlySubscription) {
            this.readonlySubscription.unsubscribe();
        }

        this.readonlySubscription = readonly$.subscribe(readonly => {
            this._readonly$.next(readonly)

            if (readonly) {
                this.controlContainer.control.disable();
            } else {
                this.controlContainer.control.enable();
            }
        });
    }

    ngOnInit() {
        this.form = this.controlContainer.control;
        [
            'postCode',
            'houseNumber',
            'street',
            'city',
            'latitude',
            'longitude',
        ].forEach(key => {
            if (this.form.get(key) === null) {
                throw new Error(`Invalid form group supplied, missing control with key '${key}'`);
            }
        });
        this.canUpdateDeleteProject$ = this.authorizationService.canUpdateDeleteProject$(this.project);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        if (this.readonlySubscription) {
            this.readonlySubscription.unsubscribe();
        }
        this.subscriptions = [];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.project && changes.project.currentValue) {
            this.projectCoordinates = {
                latitude: changes.project.currentValue.latitude,
                longitude: changes.project.currentValue.longitude
            };
            this.showLocationForm = changes.project.currentValue.latitude;
        }
    }

    search(query: string) {
        this.searchString$.next(query);
    }

    selectSearchAddress({position, address}: atlas.Models.SearchAddressResult) {
        this.form.patchValue({
            longitude: position.lon,
            latitude: position.lat,
            houseNumber: address.streetNumber ? address.streetNumber : '',
            street: address.streetName !== address.municipality ? address.streetName : '',
            postCode: this.addressPostalCode(address),
            city: address.municipalitySubdivision || address.municipality
        }, {emitEvent: false});

        this.projectCoordinates = {latitude: position.lat, longitude: position.lon};
        this.showLocationForm = true;
        this.searchString$.next('');
    }

    formatCoordinates() {
        const {longitude, latitude} = this.form.getRawValue();
        return `${(+latitude).toFixed(5)}, ${(longitude).toFixed(5)}`;
    }

    formatSearchResult(result: atlas.Models.SearchFuzzyResult): { name: string, location: string } {
        if (result.type === 'POI') {
            return {
                name: result.poi.name,
                location: `${result.address.freeformAddress}`
            };
        } else if (result.type === 'Point Address') {
            return {
                name: `${result.address.streetName} ${result.address.streetNumber}`,
                location: `${result.address.extendedPostalCode}, ${result.address.localName}`
            };
        } else if (result.type === 'Street') {
            let municipality = result.address.localName;
            if (municipality !== result.address.municipality) {
                municipality += `${result.address.municipality}`;
            }
            return {
                name: result.address.streetName,
                location: `${result.address.postalCode}, ${municipality}`
            };
        } else {
            return {
                name: `${result.address.streetName} ${result.address.streetNumber}`,
                location: `${result.address.postalCode}, ${result.address.municipalitySubdivision}`
            };
        }
    }

    onClickedAddress(clickedAddress: SearchAddressReverseResult) {
        const {address, position} = clickedAddress;
        const positionLatLong = position.split(',');

        this.form.patchValue({
            houseNumber: address.streetNumber ? address.streetNumber : '',
            street: address.streetName !== address.municipality ? address.streetName : '',
            postCode: this.addressPostalCode(address),
            city: address.municipalitySubdivision || address.municipality,
            latitude: +positionLatLong[0],
            longitude: +positionLatLong[1],
        }, {emitEvent: false});
        this.showLocationForm = true;
    }

    private addressPostalCode(address: atlas.Models.SearchResultAddress): string {
        if (address.extendedPostalCode) {
            // some addresses will have values like: '1234 AB, 1234 DE'
            return address.extendedPostalCode.split(',')[0];
        }

        return address.postalCode;
    }
}
