import {Injectable} from '@angular/core';
import {Company} from '../models/company';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export interface CompanyService {
    listMine(): Observable<Company[]>
    listMineWithChildren(): Observable<Company[]>
    listAll(excludeDivisionAndRegion: boolean): Observable<Company[]>
}

@Injectable()
export class CompanyServiceImpl implements CompanyService {
    constructor(private client: HttpClient) {
    }

    listMine(): Observable<Company[]> {
        return this.client.get<Company[]>('/api/v1/companies/mine')
    }

    listMineWithChildren(): Observable<Company[]> {
        return this.client.get<Company[]>('/api/v1/companies/mine?withChildren=true')
    }

    listAll(excludeDivisionAndRegion: boolean): Observable<Company[]> {
        const params = { excludeDivisionAndRegion: excludeDivisionAndRegion };
        return this.client.get<Company[]>('/api/v1/companies', {params})
    }
}
