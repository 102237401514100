import {ItemSelectComponent} from './item-select.component';
import {Component, forwardRef, Inject} from '@angular/core';
import {Observable, of} from 'rxjs';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {createPageResponseOf, PageResponse} from '../../models/page-response';
import {Company} from '../../models/company';
import {CompanyService} from '../../services/company.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-company-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CompanySelectComponent),
            multi: true
        }
    ]
})
export class CompanySelectComponent extends ItemSelectComponent<Company, {}> {

    constructor(
        @Inject('CompanyService') private companyService: CompanyService
    ) {
        super();

        this.placeholder = 'Selecteer een onderneming';
    }

    getFilter(): Observable<{}> {
        return of({});
    }


    getLabelText(item: Company): string {
        return item.kvwNumber ? (item.kvwNumber + ' - ' + item.name) : item.kvwNumber;
    }

    override loadMorePages(page: number, filter: { term: string }): Observable<PageResponse<Company>> {
        return this.companyService.listMine().pipe(
            map(result => createPageResponseOf(result))
        )
    }
}
