import {Component} from '@angular/core';
import {MsalService} from '@azure/msal-angular';

@Component({
    selector: 'app-unauthorized-modal',
    templateUrl: './unauthorized-modal.component.html',
})
export class UnauthorizedModalComponent {
    constructor(private msalService: MsalService) {
    }

    loginWithOtherAccount() {
        this.msalService.loginRedirect();
    }
}
