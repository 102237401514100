import {Component, Inject} from '@angular/core';
import {ProjectService} from '../../services/project.service';
import {Project} from '../../models/project';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, combineLatest, lastValueFrom, Observable, throwError} from 'rxjs';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {awaitModalClosed} from '../../utils/modal';
import {ToastrService} from 'ngx-toastr';
import {catchError, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {ProjectAppStoreItemService} from '../../services/project-app-store-item.service';
import {ConfirmModalComponent} from '../../components/confirm-modal/confirm-modal.component';
import {AuthorizationService} from '../../services/authorization.service';
import {Role} from '../../models/role';
import {CompanyService} from '../../services/company.service';

@Component({
    selector: 'app-project-detail',
    templateUrl: './project-detail.component.html'
})
export class ProjectDetailComponent {
    refresh$ = new BehaviorSubject<void>(null);
    project$: Observable<Project> = combineLatest([
        this.route.params,
        this.refresh$
    ]).pipe(
        switchMap(([params]) => this.projectService.get(+params.id)),
        shareReplay(1),
        catchError(err => {
            if (err.status === 403) {
                this.router.navigate(['projects'])
            }

            return throwError(err);
        })
    );
    canSeeHistory$: Observable<boolean> = this.authorizationService.roles$.pipe(
        map(roles => roles.includes(Role.superAdmin) || roles.includes(Role.bedrijfsAdmin))
    );

    readonly$ = combineLatest([this.project$, this.project$.pipe(
        switchMap(project => this.authorizationService.canUpdateDeleteProject$(project))
    )]).pipe(
        map(([project, canManage]) =>
            project.finalized || !canManage
        )
    );

    applicationCount$: Observable<number> = this.project$.pipe(
        switchMap(project => this.projectAppStoreItemService.countByProject(project))
    );

    private deleteConfirmData = {
        title: 'Project verwijderen',
        description: 'Weet u zeker dat u dit project wilt verwijderen?',
        confirmButton: 'Verwijderen'
    };
    private finalizeConfirmData = {
        title: 'Project afsluiten',
        description: 'Weet u zeker dat u dit project wilt afsluiten?',
        confirmButton: 'Afsluiten'
    };
    private reopenConfirmData = {
        title: 'Project heropenen',
        description: 'Weet u zeker dat u dit project wilt heropenen?',
        confirmButton: 'Heropenen'
    };

    constructor(
        @Inject('ProjectService') private projectService: ProjectService,
        @Inject('ProjectAppStoreItemService') private projectAppStoreItemService: ProjectAppStoreItemService,
        @Inject('AuthorizationService') private authorizationService: AuthorizationService,
        private modalService: VwuiModalService,
        private toastr: ToastrService,
        public route: ActivatedRoute,
        private router: Router
    ) {
    }

    async openDeleteProjectModal() {
        const deleteModal = this.modalService.open(ConfirmModalComponent, {
            data: {...this.deleteConfirmData}
        });
        const result = await awaitModalClosed<boolean>(deleteModal);

        if (result) {
            try {
                const project = await lastValueFrom<Project>(this.project$.pipe(take(1)));
                await lastValueFrom<void>(this.projectService.delete(project));
                this.toastr.success('Project verwijderd.');
            } catch (error) {
                this.toastr.error('Project verwijderen mislukt.');
            }
            await this.router.navigate(['/projects']);
        }
    }

    async openFinalizeProjectModal() {
        await this.changeFinalizeState(this.finalizeConfirmData, true, 'Project afgesloten.', 'Project afsluiten mislukt.');
    }

    async openReopenProjectModal() {
        await this.changeFinalizeState(this.reopenConfirmData, false, 'Project heropend.', 'Project heropenen mislukt.');
    }

    onTabChange(tab: string) {
        this.router.navigate([], {relativeTo: this.route, queryParams: {tab}, replaceUrl: true});
    }

    onProjectUpdated() {
        this.refresh$.next();
    }

    back() {
        history.back()
    }

    private async changeFinalizeState(modalData: object, finalize: boolean, successMessage: string, errorMessage: string) {
        const finalizeModal = this.modalService.open(ConfirmModalComponent, {
            data: {...modalData}
        });
        const result = await awaitModalClosed<boolean>(finalizeModal);

        if (result) {
            const project = await lastValueFrom<Project>(this.project$.pipe(take(1)));
            try {
                await lastValueFrom<Project>(this.projectService.patch({
                    finalized: finalize,
                    _links: {...project._links}
                }));
                this.toastr.success(successMessage);
            } catch (error) {
                this.toastr.error(errorMessage);
            }
            await this.router.navigate(['/projects']);
        }
    }
}
