export enum ProjectRole {
    Owner = 'Owner',
    ProjectLeader = 'ProjectLeader',
    TeamsOwner = 'TeamsOwner'
}

export const projectRoleTranslation = {
    Owner: 'Eigenaar',
    ProjectLeader: 'Projectverantwoordelijke',
    TeamsOwner: 'Teams eigenaar'
};
