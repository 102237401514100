import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {VwuiModalRef, VwuiModalService} from '@recognizebv/vwui-angular';
import {NgSelectConfig} from '@ng-select/ng-select';
import {lastValueFrom, Subscription} from 'rxjs';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {
    AuthenticationRequiredModalComponent
} from './components/authentication-required-modal/authentication-required-modal.component';
import {AuthorizationService} from './services/authorization.service';
import {PendoService} from './integrations/pendo/pendo.service';
import {AppInsightsService} from './services/app-insights.service';
import {filter} from 'rxjs/operators';
import {EventType, Logger, LogLevel} from '@azure/msal-browser';
import {environment} from '../environments/environment';
import {GraphService} from './services/graph.service';
import {ToastrService} from 'ngx-toastr';
import {CompanyService} from './services/company.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    version = environment.version || 'dev';

    constructor(
        private config: NgSelectConfig,
        private authService: MsalService,
        private broadcastService: MsalBroadcastService,
        private msalService: MsalService,
        private modalService: VwuiModalService,
        private pendoService: PendoService,
        private appInsightsService: AppInsightsService,
        private toastr: ToastrService,
        @Inject('CompanyService') private companyService: CompanyService,
        @Inject('GraphService') private graphService: GraphService,
        @Inject('AuthorizationService') private authorizationService: AuthorizationService
    ) {
        this.appInsightsService.init();
        authService.setLogger(new Logger({
            loggerCallback: (logLevel, message) => {
                if (logLevel === LogLevel.Warning) {
                    console.warn('AUTH', message);
                } else if (logLevel === LogLevel.Error) {
                    console.error('AUTH', message);
                }
            }
        }));
    }

    async ngOnInit(): Promise<void> {
        this.applyNgSelectConfig(this.config);
        let modalRef: VwuiModalRef;
        this.subscriptions.push(
            this.broadcastService.msalSubject$.pipe(
                filter(event => event.eventType === EventType.ACQUIRE_TOKEN_FAILURE)
            ).subscribe((_) => {
                if (!modalRef) {
                    modalRef = this.modalService.open(AuthenticationRequiredModalComponent);
                }
            })
        );
    }

     async showKvwNumbers() {
         const companies = await lastValueFrom(this.companyService.listMineWithChildren());
         const kvwNumbers = companies.map(it => it.kvwNumber);
         if(!kvwNumbers) {
             this.toastr.warning("Deze gebruiker heeft geen KVW Nummer(s)");
         } else {
             this.toastr.info("Deze gebruikers heeft kvw(s): " + kvwNumbers.toString())
         }
     }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }

    // noinspection JSMethodCanBeStatic
    private applyNgSelectConfig(config: NgSelectConfig) {
        config.notFoundText = 'Geen resultaten';
        config.addTagText = 'Toevoegen';
        config.typeToSearchText = 'Type om te zoeken';
        config.loadingText = 'Laden...';
    }
}
