import {AbstractRestServiceMock} from './abstract-rest.service.mock';
import {ProjectUserPreference} from '../../models/project-user-preference';
import {ProjectUserPreferencesService} from '../project-user-preferences.service';
import {Observable, of} from 'rxjs';
import {mockUsers} from './user.service.mock';
import {mockProjects} from './data/projects';

export let userPreference: ProjectUserPreference;

export class ProjectUserPreferencesServiceMock
    extends AbstractRestServiceMock<ProjectUserPreference> implements ProjectUserPreferencesService {
    findByProjectOrCreate(projectId: number, hidden: boolean): Observable<ProjectUserPreference> {
        if (!userPreference) {
            userPreference = {
                project: mockProjects.find(value => value.id === projectId),
                user: mockUsers[0],
                hidden
            };
        } else {
            userPreference.hidden = hidden;
        }

        return of(userPreference);
    }
}
