import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GraphUsersPageResponse} from '../models/graph-users-page-response';
import {GraphCompanyDetailsResponse} from '../models/company-page-response';

export interface GraphService {
    findUsers(name: string): Observable<GraphUsersPageResponse>;
    findUsersFromNextLink(name: string, url: string): Observable<GraphUsersPageResponse>;
    getCompanyDetails(): Observable<GraphCompanyDetailsResponse>;
}

@Injectable()
export class GraphServiceImpl implements GraphService {
    constructor(private http: HttpClient) {
    }

    findUsers(name: string): Observable<GraphUsersPageResponse> {
        return this.http.get<GraphUsersPageResponse>('/api/graph/users', {params: {name}});
    }

    findUsersFromNextLink(name: string, nextUrl: string): Observable<GraphUsersPageResponse> {
        const searchParams = new URL(nextUrl).searchParams;

        return this.http.get<GraphUsersPageResponse>('/api/graph/users', {params: {
            name,
            $skiptoken: searchParams.get('$skiptoken')
        }});
    }

    getCompanyDetails(): Observable<GraphCompanyDetailsResponse> {
        return this.http.get<GraphCompanyDetailsResponse>(`/api/graph/companyDetails`);
    }

}
