import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable, of} from 'rxjs';
import {MsalBroadcastService, MsalInterceptorAuthRequest, MsalService} from '@azure/msal-angular';
import {AccountInfo, AuthenticationResult} from '@azure/msal-common';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {InteractionStatus} from '@azure/msal-browser';
import {filterAccountsByAudience} from '../utils/msal';

@Injectable()
export class MsalIdTokenInterceptor implements HttpInterceptor {

    constructor(
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        @Inject('BASE_URL') private baseUrl: string
    ) {
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const isProjectenPortaalRequest = (
            req.url.charAt(0) === '/'
            || req.url.toLowerCase().startsWith(this.baseUrl.toLowerCase())
        );

        // only add id token to requests to self
        if (!isProjectenPortaalRequest) {
            return next.handle(req);
        }

        // Sets account as active account or first account
        let account: AccountInfo;


        const activeAccount = this.authService.instance.getActiveAccount();
        if (activeAccount !== null) {
            this.authService.getLogger().verbose('Interceptor - active account selected');
            account = activeAccount;
        } else {
            this.authService.getLogger().verbose('Interceptor - no active account, fallback to first account');

            account = filterAccountsByAudience(this.authService.instance.getAllAccounts())[0]
        }

        const authRequest = {account};
        const scopes = ['openid', 'email', 'profile'];
        return this.msalBroadcastService.inProgress$.pipe(filter(it => it === InteractionStatus.None),
            take(1),
            switchMap(() => {
                return this.authService.acquireTokenSilent({...authRequest, scopes}).pipe(
                    catchError(() => {
                        this.authService.getLogger().error(
                            'Interceptor - acquireTokenSilent rejected with error. Invoking interaction to resolve.');
                        return this.acquireTokenInteractively(authRequest, scopes);
                    }),
                    switchMap((result: AuthenticationResult) => {
                        if (!result.accessToken) {
                            this.authService.getLogger().error('Interceptor - acquireTokenSilent resolved with null access token. Known issue with B2C tenants, invoking interaction to resolve.');
                            return this.acquireTokenInteractively(authRequest, scopes);
                        }
                        return of(result);
                    }),
                    switchMap((result: AuthenticationResult) => {
                        this.authService.getLogger().verbose('Interceptor - setting authorization headers');
                        const headers = req.headers
                            .set('Authorization', `Bearer ${result.idToken}`);

                        const requestClone = req.clone({headers});
                        return next.handle(requestClone);
                    })
                );
            })
        );
    }

    /**
     * Invoke interaction for the given set of scopes
     */
    private acquireTokenInteractively(authRequest: MsalInterceptorAuthRequest, scopes: string[]): Observable<AuthenticationResult> {
        this.authService.getLogger().verbose('Interceptor - error acquiring token silently, acquiring by redirect');
        const redirectStartPage = window.location.href;
        this.authService.acquireTokenRedirect({...authRequest, scopes, redirectStartPage});
        return EMPTY;
    }
}
