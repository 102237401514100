<form [formGroup]="form" (submit)="createApp()">
    <vwui-modal-header>
        Applicatie toevoegen
    </vwui-modal-header>
    <vwui-modal-body>
        <vwui-form-group label="Titel" [required]="true">
            <vwui-input inputId="title" placeholder="Applicatie titel" formControlName="title" autofocus="autofocus"></vwui-input>
            <div *ngIf="form.get('title').hasError('internalTitleExists')" class="text-danger">
                <small>Er bestaat al een app met deze interne titel</small>
            </div>
            <input type="submit" [hidden]="true" />
        </vwui-form-group>
    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modalRef.close()">Annuleren</vwui-button>
        <vwui-button
            class="is-primary"
            (click)="createApp()"
            [disabled]="!form.valid || submitPending"
            [loading]="submitPending"
        >
            Toevoegen
        </vwui-button>
    </vwui-modal-footer>
</form>
