import {RestLink} from '../../../../frontend/src/app/models/rest/rest-link';

export class RestUtils {
    static getIdFromSelfLink(link: RestLink<any>) {
        const lastSlashIndex = link.href.lastIndexOf('/');
        if (lastSlashIndex === -1) {
            throw new Error('Couldn\'t determine id');
        }

        return link.href.substr(lastSlashIndex + 1);
    }
}
