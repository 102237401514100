<vwui-modal-header>
    <div class="d-flex">
        <h3>Projectlabel {{label ? 'bijwerken' : 'toevoegen'}}</h3>
    </div>
</vwui-modal-header>

<vwui-modal-body>
    <div [formGroup]="form">
        <vwui-form-group label="Naam">
            <vwui-input inputId="name" formControlName="name"></vwui-input>
        </vwui-form-group>
        <vwui-form-group label="Kleur">
            <ng-select [items]="colors" [hideSelected]="true" formControlName="color" appendTo="body" bindValue="color">
                <ng-template ng-label-tmp let-item="item">
                    <div class="d-flex align-items-center">
                        <vwui-icon class="mr-2" [style.color]="item.color" iconName="dot"></vwui-icon>
                        {{item.label}}
                    </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    <vwui-icon [style.color]="item.color" iconName="dot" class="mr-2"></vwui-icon>
                    {{item.label}}
                </ng-template>
            </ng-select>
        </vwui-form-group>
        <vwui-form-group label="Actief">
            <vwui-toggle formControlName="active"></vwui-toggle>
        </vwui-form-group>
    </div>
</vwui-modal-body>

<vwui-modal-footer>
    <vwui-button class="is-light" (click)="modalRef.close()">Venster sluiten</vwui-button>
    <vwui-button
        class="is-secondary"
        [disabled]="!form.valid || submitPending"
        (click)="save()"
    >
        Opslaan
    </vwui-button>
</vwui-modal-footer>

