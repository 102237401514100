import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-sorting-table-header',
    templateUrl: './sorting-table-header.component.html'
})
export class SortingTableHeaderComponent {
    @Input() sortKey: string;
    @Output() sortChange = new EventEmitter<string>()

    constructor(
        private route: ActivatedRoute
    ) {
    }

    get sortIcon() {
        if (this.route.snapshot.queryParams['field'] === this.sortKey) {
            return `vwui-sort-${this.route.snapshot.queryParams['direction']?.toLowerCase()}`;
        }

        return 'vwui-sort';
    }

}
