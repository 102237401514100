<vwui-modal-header>
    {{ title }}
</vwui-modal-header>
<vwui-modal-body>
    {{ description }}
</vwui-modal-body>
<vwui-modal-footer>
    <vwui-button class="is-light" (click)="modalRef.close()">Annuleren</vwui-button>
    <vwui-button class="is-secondary" (click)="confirm()">{{ confirmButton }}</vwui-button>
</vwui-modal-footer>
