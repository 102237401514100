/* tslint:disable:no-console */

import {RestItem} from '../../models/rest/rest-item';
import {AbstractRestServiceInterface, RestHttpParams, RestItemOrRestLink, SortParams} from '../abstract-rest.service';
import {Observable, of} from 'rxjs';
import {createPageResponseOf, PageResponse} from '../../models/page-response';
import {RestUtils} from '../../utils/rest-utils';
import {tap} from 'rxjs/operators';
import {RestLink} from '../../models/rest/rest-link';

export abstract class AbstractRestServiceMock<T extends RestItem<T>, C = Partial<Omit<T, '_links'>>>
    implements AbstractRestServiceInterface<T, C> {

    constructor(protected data: Array<T>) {
    }

    create(createItem: C): Observable<T> {
        console.debug(`Invoked "create" method of ${this.constructor.toString().match(/\w+/g)[1]}`, createItem);

        throw new Error('Unsupported');
    }

    get(id: number): Observable<T> {
        console.debug(`Invoked "get" method of ${this.constructor.toString().match(/\w+/g)[1]}`, id);

        return of(this.data.find(item => RestUtils.getIdFromSelfLink(item._links.self) === id.toString()));
    }

    patch(restItem: Required<RestItem<T>> & Partial<T>): Observable<T> {
        console.debug(`Invoked "patch" method of ${this.constructor.toString().match(/\w+/g)[1]}`, restItem);

        return this.get(+RestUtils.getIdFromSelfLink(restItem._links.self)).pipe(
            tap(item => Object.assign(item, restItem))
        );
    }

    delete(restItem: T): Observable<void> {
        console.debug(`Invoked "delete" method of ${this.constructor.toString().match(/\w+/g)[1]}`, restItem);

        this.data = this.data.filter(item => {
            return item._links.self.href !== restItem._links.self.href;
        });

        return of(void 0);
    }

    findAll(params?: RestHttpParams, sort?: SortParams): Observable<PageResponse<T>> {
        console.debug(`Invoked "findAll" method of ${this.constructor.toString().match(/\w+/g)[1]}`, params, sort);

        return of(createPageResponseOf(this.data.slice()));
    }

    getSingleRelation<L extends RestLink<T>>(relation: L): Observable<T> {
        console.debug(`Invoked "getSingleRelation" method of ${this.constructor.toString().match(/\w+/g)[1]}`, relation);

        return of(this.data[0]);
    }

    getCollectionRelation<L extends RestLink<T>>(relation: L, key?: string): Observable<T[]> {
        console.debug(`Invoked "getCollectionRelation" method of ${this.constructor.toString().match(/\w+/g)[1]}`, relation);

        return of(this.data.slice());
    }

    addRelation<L extends RestLink<T>>(relation: L, ...values: Array<RestItemOrRestLink<T>>): Observable<L> {
        return of();
    }

    deleteRelation<L extends RestLink<any>>(relation: L): Observable<void> {
        return of(void 0);
    }

    replaceRelation<L extends RestLink<T>>(relation: L, ...values: Array<RestItemOrRestLink<T>>): Observable<L> {
        return of(void 0);
    }
}
