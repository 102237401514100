import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Project} from '../../models/project';
import {ProjectAppStoreItemService} from '../../services/project-app-store-item.service';
import {BehaviorSubject, combineLatest, EMPTY, lastValueFrom, mergeMap, Subscription, timer} from 'rxjs';
import {expand, filter, map, shareReplay, skip, switchMap} from 'rxjs/operators';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {TrimbleUserService} from '../../services/trimble-user.service';
import {AuthorizationService} from '../../services/authorization.service';
import {NavisionAdministrationService} from '../../services/navision-administration.service';
import {AppStoreItemService} from '../../services/app-store-item.service';
import {ProjectAppStoreItem} from '../../models/application';
import {
    ProjectAppStoreDetailModalComponent
} from '../project-app-store-detail-modal/project-app-store-detail-modal.component';
import {
    ProjectAvailableAppsModalComponent
} from '../project-available-apps-modal/project-available-apps-modal.component';
import {ProjectService} from '../../services/project.service';
import {ToastrService} from 'ngx-toastr';
import {NavisionAdministrationResponse} from '../../models/navision-administration-response';

@Component({
    selector: 'app-project-applications-tab',
    templateUrl: './project-applications-tab.component.html'
})
export class ProjectApplicationsTabComponent implements OnInit, OnDestroy {
    @Input() readonly = false
    @Output() projectUpdated = new EventEmitter<void>();

    emptyPageMessage: string = 'Er zijn geen applicaties toegevoegd aan het project.\n' +
        'Klik op applicatie toevoegen om te beginnen.';

    project$ = new BehaviorSubject<Project>(null);
    refresh$ = new BehaviorSubject<void>(null);


    appStoreItems$ = combineLatest([this.project$, this.refresh$]).pipe(
        filter(([project]) => !!project),
        switchMap(([project]) => {
            return this.projectAppStoreItemService.getCollectionRelation(project._links.projectAppStoreItems).pipe(
                expand(items => {
                    if (items.some(item => item.status === 'CREATED')) {
                        return timer(60 * 1000).pipe(
                            switchMap(() => this.projectAppStoreItemService.getCollectionRelation(project._links.projectAppStoreItems))
                        );
                    } else {
                        return EMPTY;
                    }
                })
            )
        }),
        map(items => items.sort((a, b) => {
            if (a.type !== b.type) {
                if (a.type === 'secondary' || b.type === 'secondary') {
                    return b.type === 'secondary' ? -1 : 1;
                }
            } else if (a.type === 'secondary' && b.type === 'secondary') {
                return !b.secondaryType
                    ? -1 : (!a.secondaryType ? 1 : b.secondaryType.localeCompare(a.secondaryType));
            }

            return a.title < b.title ? -1 : 1;
        })),
        shareReplay({bufferSize: 1, refCount: true}),
    );

    subscription: Subscription[];
    userHasNavisionAdministrations = false;
    canUpdateDeleteProject$ = this.project$.pipe(
        switchMap(project => this.authorizationService.canUpdateDeleteProject$(project))
    );
    appModalOpen = false;

    constructor(
        @Inject('ProjectAppStoreItemService') private projectAppStoreItemService: ProjectAppStoreItemService,
        @Inject('TrimbleUserService') private trimbleUserService: TrimbleUserService,
        @Inject('AuthorizationService') private authorizationService: AuthorizationService,
        @Inject('NavisionAdministrationService') private navisionAdministrationService: NavisionAdministrationService,
        @Inject('AppStoreItemService') private appStoreItemService: AppStoreItemService,
        @Inject('ProjectService') private projectService: ProjectService,
        private toast: ToastrService,
        private modalService: VwuiModalService,
    ) {
        this.subscription = [
            this.refresh$.pipe(
                skip(1),
            ).subscribe(_ => this.projectUpdated.emit())
        ];
    }

    ngOnInit(): void {
        this.checkUserHasNavisionAdministrations();
    }

    ngOnDestroy(): void {
        this.subscription.forEach(sub => sub.unsubscribe());
    }

    @Input()
    set project(project: Project) {
        this.project$.next(project);
    }

    private async checkUserHasNavisionAdministrations() {
        const response = await lastValueFrom<NavisionAdministrationResponse>(this.navisionAdministrationService.list(0, 1));

        this.userHasNavisionAdministrations = response.administrations.length > 0;
    }

    private projectHasLocation(): boolean {
        const project = this.project$.value
        return !!(project.latitude && project.longitude);
    }

    private projectHasType(): boolean {
        const type = this.project$.value.typeGUID;

        return type !== null && type !== undefined && type.length !== 0;
    }

    private projectHasOperationalStatus(): boolean {
        const operationalStatus = this.project$.value.operationalProjectStatusGUID;

        return operationalStatus !== null && operationalStatus !== undefined && operationalStatus.length !== 0;
    }

    private projectHasCoordinates(): boolean {
        const [latitude, longitude] = [this.project$.value.latitude, this.project$.value.longitude];

        return latitude !== null && latitude !== undefined && longitude !== null && longitude !== undefined;
    }

    openAvailableApplicationModal() {
        if (!this.projectHasLocation()) {
            this.toast.error('Applicaties kunnen pas aangemaakt worden als de locatie gevuld is.');
            return;
        }
        if (!this.projectHasType()) {
            this.toast.error('Applicaties kunnen pas aangemaakt worden als de werksoort ingevuld is (tabblad kenmerken).');
            return;
        }
        if (this.readonly) {
            this.toast.error('Het project is afgesloten, er kunnen geen applicaties toegevoegd worden.');
            return;
        }
        if (!this.projectHasOperationalStatus()) {
            this.toast.error('Applicaties kunnen pas aangemaakt worden als de status ingevuld is (tabblad kenmerken).');
            return;
        }
        if (!this.projectHasCoordinates()) {
            this.toast.error('Applicaties kunnen pas aangemaakt worden als de locatie ingevuld is (tabblad locatie).');
            return;
        }

        this.appModalOpen = true;
        this.modalService.open(ProjectAvailableAppsModalComponent, {
            modalClass: 'modal-lg',
            closeOnBackdropClick: true,
            data: {project: this.project$.value}
        }).afterClosed.subscribe(_ => {
            this.appModalOpen = false;
            this.refresh$.next();
        }, () => {
            this.appModalOpen = false;
        });
    }

    async openAppStoreDetailModal(appInstance: ProjectAppStoreItem) {
        this.modalService.open(ProjectAppStoreDetailModalComponent, {
            modalClass: 'modal-lg',
            closeOnBackdropClick: true,
            data: {appInstance, project: this.project$.value}
        }).afterClosed.subscribe(() => this.refresh$.next());
    }
}
