import {Component} from '@angular/core';
import {VwuiModalRef} from '@recognizebv/vwui-angular';

@Component({
  selector: 'app-project-sight-disclaimer',
  templateUrl: './project-sight-disclaimer.component.html'
})
export class ProjectSightDisclaimerComponent {

  constructor(
      public modalRef: VwuiModalRef,
  ) { }

}
