import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ProjectLabelService} from '../project-label.service';
import {Observable} from 'rxjs';
import {ProjectLabel} from '../../models/project-label';
import {AbstractRestServiceMock} from './abstract-rest.service.mock';
import {RestCollection} from '../../models/rest/rest-collection';

export const mockLabels: ProjectLabel[] = [
    {
        id: 1,
        name: 'Demo Project',
        color: '#FFFFFF',
        active: true,
        used: true
    },
    {
        id: 2,
        name: 'Test Project',
        color: '#000000',
        active: false,
        used: false
    },
    {
        id: 3,
        name: 'Template Project',
        color: '#000BB0',
        active: true,
        used: false
    },
    {
        id: 4,
        name: 'Training Project',
        color: '#0AABB0',
        active: false,
        used: true
    }

]

@Injectable()
export class ProjectLabelServiceMock extends AbstractRestServiceMock<ProjectLabel> implements ProjectLabelService {
    constructor(protected httpClient: HttpClient) {
        super(mockLabels);
    }

    getActive(): Observable<RestCollection<ProjectLabel>> {
        return undefined;
    }

}
