import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProjectListComponent} from './pages/project-list/project-list.component';
import {ProjectDetailComponent} from './pages/project-detail/project-detail.component';
import {MsalGuard} from '@azure/msal-angular';
import {UnauthorizedComponent} from './pages/unauthorized/unauthorized.component';
import {environment} from '../environments/environment';
import {AppStoreListComponent} from './pages/app-store-list/app-store-list.component';
import {AppStoreDetailComponent} from './pages/app-store-detail/app-store-detail.component';
import {SuperAdminGuard} from './guards/super-admin.guard';
import {MigrationCompanyNamesComponent} from './pages/migration-company-names/migration-company-names.component';
import {ProjectLabelsComponent} from './pages/project-labels/project-labels.component';
import {OpenTableauTabComponent} from './components/external-redirect/open-tableau-tab.component';

const routes: Routes = [
    {
        path: '',
        canActivate: environment.useMock ? [] : [MsalGuard],
        children: [
            {
                path: 'projects',
                children: [
                    {
                        path: '',
                        component: ProjectListComponent,
                    },
                    {
                        path: ':id',
                        component: ProjectDetailComponent,
                    }
                ]
            },
            {
                path: 'app-store',
                canActivateChild: [SuperAdminGuard],
                children: [
                    {
                        path: '',
                        component: AppStoreListComponent,
                    },
                    {
                        path: ':id',
                        component: AppStoreDetailComponent
                    }
                ]
            },
            {
                path: 'unauthorized',
                component: UnauthorizedComponent
            },
            {
                path: 'project-labels',
                canActivate: [SuperAdminGuard],
                component: ProjectLabelsComponent
            },
            {
                path: 'migrations',
                children: [
                    {
                        path: 'company-names',
                        canActivate: [SuperAdminGuard],
                        component: MigrationCompanyNamesComponent
                    }
                ]
            },
            {
                path: 'manage-trimble-apps',
                redirectTo: '/migrations/manage-trimble-apps',
            },
            {
                path: 'dashboard',
                component: OpenTableauTabComponent
            },
            {path: '**', redirectTo: '/projects'}
        ]
    },
    {path: '**', redirectTo: '/projects'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
