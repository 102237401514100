<ng-container *ngIf="appStoreItems$ | async as content">
    <vwui-grid class="project-applications" [fillItems]="true" *ngIf="content.length > 0; else emptyList">
        <div class="d-flex align-items-center w-100" slot="grid-title">
            <h3 class="flex-grow-1">
                Applicaties
                <vwui-badge class="grid-badge">{{content.length}}</vwui-badge>
            </h3>
            <ng-container>
                <vwui-button [disabled]="appModalOpen || (canUpdateDeleteProject$ | async) !== true" id="add-apps" icon="vwui-add" (click)="openAvailableApplicationModal()">
                    Applicatie toevoegen
                </vwui-button>
            </ng-container>
        </div>

        <ng-container *ngFor="let item of content">
            <app-project-app-store-card
                [app]="item"
                (appClick)="openAppStoreDetailModal($event)"
            ></app-project-app-store-card>
        </ng-container>

    </vwui-grid>
</ng-container>

<ng-template #emptyList>
    <vwui-empty-state messageTitle="Applicaties toevoegen"
                      [message]="emptyPageMessage">
        <div slot="button">
            <vwui-button [disabled]="appModalOpen || (canUpdateDeleteProject$ | async) !== true" id="add-apps-empty" icon="vwui-add" (click)="openAvailableApplicationModal()">
                Applicatie toevoegen
            </vwui-button>
        </div>

    </vwui-empty-state>
</ng-template>
