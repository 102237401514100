import {Component, forwardRef, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ImageInputValue} from '@recognizebv/vwui-core';
import {ToastrService} from 'ngx-toastr';
import {AuthorizationService} from '../../services/authorization.service';
import {lastValueFrom, Subscription} from 'rxjs';
import {AppStoreItem} from '../../models/app-store';
import {AppStoreItemService} from '../../services/app-store-item.service';
import {IconService} from '../../services/icon.service';
import {Icon} from '../../models/icon';
import {RestLink} from '../../models/rest/rest-link';

@Component({
    selector: 'app-app-store-item-icon-upload',
    templateUrl: './app-store-item-icon-upload.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AppStoreItemIconUploadComponent),
            multi: true
        }
    ],
})
export class AppStoreItemIconUploadComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() appStoreItem: AppStoreItem;
    disabled = false;
    value: Icon = null;
    imageControl: UntypedFormControl;

    private onChange: (value: Icon) => void;
    private onTouched: () => void;
    private subscriptions: Subscription[] = [];

    constructor(
        @Inject('IconService') private iconService: IconService,
        @Inject('AppStoreItemService') private appStoreItemService: AppStoreItemService,
        @Inject('AuthorizationService') private authorizationService: AuthorizationService,
        private toastr: ToastrService,
    ) {
    }

    ngOnInit(): void {
        this.imageControl = new UntypedFormControl(null);

        this.subscriptions.push(
            this.authorizationService.canCreateProject$.subscribe(canUpdateOrCreate => {
                if (!canUpdateOrCreate) {
                    this.imageControl.disable();
                }
            })
        );

        this.imageControl.valueChanges.subscribe((value: ImageInputValue) => {
            if (value) {
                this.uploadIcon(value.file);
            } else {
                this.onChange(null);
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    registerOnChange(fn: any): void {
        this.onChange = (icon) => {
            fn(icon);
        };
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (isDisabled) {
            this.imageControl.disable();
        } else {
            this.imageControl.enable();
        }
    }

    writeValue(icon: Icon): void {
        if (this.value) {
            window.URL.revokeObjectURL(this.value.href);
        }

        this.value = icon;
        if (icon && icon.iconSas) {
            this.imageControl.setValue({
                ...this.imageControl.value,
                previewSrc: icon.iconSas
            }, {emitEvent: false})
        }
    }

    clear() {
        this.value = null;
        this.onTouched();
        this.onChange(this.value);
    }

    async uploadIcon(file: File | Blob) {
        try {
            const uploadedIcon = await this.iconService.postImage(file);
            this.onChange(uploadedIcon);

            if (uploadedIcon._links.self.href !== this.appStoreItem._links.icon.href) {
                lastValueFrom<RestLink<Icon>>(await this.appStoreItemService.replaceRelation(this.appStoreItem._links.icon, uploadedIcon._links.self));
            }
            this.toastr.success('Icoon succesvol geupload.')
        } catch (error) {
            console.error(error);
            this.imageControl.setValue(null);
            this.toastr.error('Uploaden van icoon is mislukt.');
        }
    }
}
