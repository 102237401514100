export enum Role {
    superAdmin = 'ROLE_SUPER_ADMIN',
    user = 'ROLE_USER',
    trimbleManager = 'ROLE_TRIMBLE_MANAGER',
    trimbleUser = 'ROLE_TRIMBLE_USER',
    trimbleAdmin = 'ROLE_TRIMBLE_ADMIN',
    bouwpas = 'ROLE_BOUWPAS',
    prm = 'ROLE_PRM',
    bedrijfsAdmin = 'ROLE_BEDRIJFS_ADMIN'
}
