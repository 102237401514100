import {AbstractRestService, AbstractRestServiceInterface} from './abstract-rest.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CreateProjectMember, ProjectMember} from '../models/project-member';

export interface ProjectMemberService extends AbstractRestServiceInterface<ProjectMember, CreateProjectMember> {
}

@Injectable()
export class ProjectMemberServiceImpl extends AbstractRestService<ProjectMember, CreateProjectMember> implements ProjectMemberService {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, 'projectMembers');
    }
}
