import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Icon} from '../models/icon';
import {AbstractRestService, AbstractRestServiceInterface} from './abstract-rest.service';
import {lastValueFrom} from 'rxjs';

export interface SasResponse {[key: string]: string}

export interface IconService extends AbstractRestServiceInterface<Icon>{
    postImage(imageFile: File | Blob): Promise<Icon>;
}

@Injectable()
export class IconServiceImpl extends AbstractRestService<Icon> implements IconService {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, 'icons');
    }

    postImage(file: File | Blob) {
        const formData = new FormData();

        formData.append('icon', file, '');
        return lastValueFrom<Icon>(this.httpClient.post<Icon>('/api/v1/icons', formData));
    }

}
