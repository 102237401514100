<ng-container *ngIf="(project$|async) as project">
    <form *ngIf="form" [formGroup]="form">
        <div class="row">
            <div class="col-12 col-md-6">
                <vwui-card>
                    <vwui-card-header>Projectgegevens</vwui-card-header>
                    <vwui-card-body>

                        <div class="d-flex ">
                            <vwui-form-group inputId="code" label="Project ID" class="">
                                <vwui-input readonly="true" formControlName="code"></vwui-input>
                            </vwui-form-group>
                            <vwui-form-group inputId="name" label="Projectnaam" class="required vw-65 ml-4">
                                <vwui-input type="text" formControlName="name"></vwui-input>
                            </vwui-form-group>

                        </div>

                        <vwui-form-group inputId="commercialName" label="Commerciële projectnaam">
                            <vwui-input type="text" formControlName="commercialName"></vwui-input>
                        </vwui-form-group>

                        <vwui-form-group inputId="operationalProjectStatus" label="Operationele projectstatus" class=""
                        >
                            <ng-select [items]="operationalProjectsStatusList$ | async "
                                       bindLabel="value"
                                       bindValue="key"
                                       placeholder="Selecteer operationele projectstatus"
                                       formControlName="operationalProjectStatusGUID"
                                       appendTo="body"
                            >
                            </ng-select>
                        </vwui-form-group>
                        <div class="d-flex">
                            <vwui-form-group inputId="startDate" label="Startdatum" class="date">
                                <vwui-datepicker formControlName="startDate" [flatpickrOptions]="datePickerOptions"
                                                 [maxDate]="form.get('endDate').value"></vwui-datepicker>
                            </vwui-form-group>

                            <vwui-form-group inputId="endDate" label="Einddatum" class="ml-4 date">
                                <vwui-datepicker formControlName="endDate" [flatpickrOptions]="datePickerOptions"
                                                 [minDate]="form.get('startDate').value"></vwui-datepicker>
                            </vwui-form-group>
                        </div>

                        <vwui-form-group inputId="companyName" label="Onderneming">
                            <vwui-input readonly="true" formControlName="companyName"></vwui-input>
                        </vwui-form-group>
                        <vwui-form-group inputId="bouwcombinatie" label="Combinant / Partner">
                            <ng-select [items]="kvwNumberList$ | async"
                                       bindLabel="label"
                                       bindValue="kvwNumber"
                                       formControlName="bouwcombinatieKvwNumbers"
                                       [multiple]="true"></ng-select>
                        </vwui-form-group>
                        <div class="d-flex w-100 mb-3">
                            <ng-container *ngIf="(readonly$ | async) === false">
                                <vwui-button
                                    label="save" slot="action"
                                    class="is-secondary ml-auto"
                                    (click)="save()"
                                    [disabled]="!form.valid || submitPending"
                                >
                                    Opslaan
                                </vwui-button>
                                <vwui-button
                                    label="saveAndContinue"
                                    type="submit"
                                    slot="action"
                                    class="is-secondary ml-2"
                                    (click)="saveAndContinue()"
                                    [disabled]="!form.valid || submitPending"
                                    [loading]="submitPending"
                                >
                                    Opslaan en verder
                                </vwui-button>
                            </ng-container>
                        </div>
                    </vwui-card-body>
                </vwui-card>
            </div>

            <div class="col-12 col-md-6">

                <vwui-form-group inputId="description" label="Korte beschrijving project">
                    <vwui-textarea type="text" formControlName="description" class="description-textarea">

                    </vwui-textarea>
                    <span
                          [ngClass]="stringLimitReached('description') ? 'string--limit-reached' : 'string--limit'">{{descriptionLimitText('description')}}</span>
                </vwui-form-group>
                <div class="embed-responsive embed-responsive-1by1 overflow-inherit">
                    <app-image-upload [project]="project" class="embed-responsive-item" formControlName="image"></app-image-upload>
                </div>
            </div>
        </div>
    </form>
</ng-container>
