import {Injectable} from '@angular/core';
import {AbstractRestService, AbstractRestServiceInterface} from './abstract-rest.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ProjectUserPreference} from '../models/project-user-preference';

export interface ProjectUserPreferencesService extends AbstractRestServiceInterface<ProjectUserPreference> {
    findByProjectOrCreate(projectId: number, hidden: boolean): Observable<ProjectUserPreference>;
}

@Injectable()
export class ProjectUserPreferencesServiceImpl extends AbstractRestService<ProjectUserPreference> implements ProjectUserPreferencesService {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, 'project-user-preferences');
    }

    findByProjectOrCreate(projectId: number, hidden: boolean): Observable<ProjectUserPreference> {
        return this.httpClient.get<ProjectUserPreference>(`/api/v1/${this.resourcePath}/search/upsertByProject`, { params: {
            project: '' + projectId,
            hidden: '' + hidden
        }});
    }
}
