import {FormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, forwardRef, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {ADUser} from '../../models/ad-user';
import {GraphService} from '../../services/graph.service';
import {MsalService} from '@azure/msal-angular';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    expand,
    filter,
    map,
    mergeMap,
    retry,
    scan,
    skip,
    switchMap,
    take,
    tap
} from 'rxjs/operators';
import {GraphUsersPageResponse} from '../../models/graph-users-page-response';

@Component({
    selector: 'app-user-email-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserEmailSelectComponent),
            multi: true
        }
    ]
})
export class UserEmailSelectComponent implements OnInit, OnDestroy {
    placeholder: string;
    addTag: boolean | ((term: string) => ADUser | Promise<ADUser>) = false;

    formControl = new UntypedFormControl('');
    disabled = false;

    private onChange: (value: string) => void;
    private onTouched: () => void;

    private formSubscription: Subscription;

    onInput = new BehaviorSubject<string>('');
    loadMoreSubject = new BehaviorSubject<void>(null);
    loading = false;

    items$ = this.customFilteredInfiniteScrollObservable(
        this.onInput.pipe(debounceTime(200), distinctUntilChanged()),
        this.loadMoreSubject,
        searchString => {
            this.loading = true;
            return this.loadMore(searchString).pipe(
                tap(() => this.loading = false)
            );
        }
    );

    scrollToEnd = () => this.loadMoreSubject.next(null);

    constructor(
        @Inject('GraphService') private graphService: GraphService,
        private msalService: MsalService
    ) {
        this.placeholder = 'Selecteer een emailadres';
    }

    getFilter(): Observable<{}> {
        return of({});
    }

    getLabelText(item: ADUser): string {
        return item.userPrincipalName;
    }

    loadMore(searchString: string): Observable<GraphUsersPageResponse> {
        return this.graphService.findUsers(searchString);
    }

    compare(a: ADUser, b: ADUser): boolean {
        return a === b;
    }

    getSubText(item: ADUser): string {
        return null;
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.formControl.disable();
    }

    writeValue(value: string): void {
        this.formControl.patchValue(value);
    }

    ngOnInit(): void {
        this.formSubscription = this.formControl.valueChanges.subscribe((value) => {
            if (this.onChange) {
                this.onChange(value.userPrincipalName);
            }
            if (this.onTouched) {
                this.onTouched();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
    }

    customFilteredInfiniteScrollObservable(
        filterString: Observable<string>,
        loadMoreSubject: Observable<any>,
        loadFunction: (filter: string) => Observable<GraphUsersPageResponse>
    ): Observable<ADUser[]> {
        return filterString.pipe(
            filter(searchString => searchString !== ''),
            switchMap(searchString => loadFunction(searchString).pipe(
                expand((next) =>
                    next['@odata.nextLink'] ? loadMoreSubject.pipe(
                        skip(1),
                        take(1),
                        mergeMap(() => this.graphService.findUsersFromNextLink(searchString, next['@odata.nextLink']))
                    ) : of(),
                ),
                retry(3),
                map<GraphUsersPageResponse, ADUser[]>(it => it.value),
                scan<ADUser[], ADUser[]>((acc, items) => [...acc, ...items], [])
            )),
            catchError((err) => {
                // Catch error to prevent observable finishing / error-ing
                console.error('Failed retrieving data:', err);
                return of([]);
            }),
        );
    }
}
