import {Component, Inject} from '@angular/core';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {AppStoreModalComponent} from '../../components/app-store-modal/app-store-modal.component';
import {AppStoreItemService} from '../../services/app-store-item.service';
import {Router} from '@angular/router';
import {RestUtils} from '../../utils/rest-utils';
import {AppStoreItem} from '../../models/app-store';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {mapTo, switchMap} from 'rxjs/operators';
import {Paginator} from '../../utils/paginator';
import {AuthorizationService} from '../../services/authorization.service';

@Component({
    selector: 'app-app-store-list',
    templateUrl: './app-store-list.component.html'
})
export class AppStoreListComponent {
    canCreateProject$ = this.authorizationService.canCreateProject$;
    modalOpen = false;

    refresh$ = new BehaviorSubject<void>(null);
    paginator$ = combineLatest([
        this.refresh$
    ]).pipe(
        switchMap(() => {
            const paginator = new Paginator(page => {
                return this.appStoreItemService.findAll({
                    page: page.toString(),
                    sort: 'type,secondaryType,name'
                });
            });

            return paginator.content$.pipe(
                mapTo(paginator)
            );
        })
    );

    constructor(
        @Inject('AppStoreItemService') private appStoreItemService: AppStoreItemService,
        @Inject('AuthorizationService') private authorizationService: AuthorizationService,
        private modalService: VwuiModalService,
        private router: Router
    ) {
    }

    openAppStoreModal() {
        this.modalOpen = true;
        const modal = this.modalService.open(AppStoreModalComponent);

        modal.afterClosed.subscribe(it => {
            this.modalOpen = false;
            if (it) {
                this.navigateToAppDetail(it);
            }
        }, () => {
            this.modalOpen = false;
        });
    }

    navigateToAppDetail(item: AppStoreItem) {
        this.router.navigate(['app-store', RestUtils.getIdFromSelfLink(item._links.self)]);
    }
}
