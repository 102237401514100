import {
    Directive,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    SecurityContext,
    SimpleChanges
} from '@angular/core';
import {ImageService} from '../services/image.service';
import {Image, ImageSize} from '../models/image';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';

@Directive({
    selector: '[appBlobImage]',
})
export class BlobImageDirective implements OnChanges, OnDestroy {
    @Input() image: Image;
    @Input() imageSize: ImageSize;
    @Input() fallbackSrc: string = null;

    src: SafeUrl;

    constructor(
        @Inject('ImageService') private imageService: ImageService,
        private http: HttpClient,
        private sanitizer: DomSanitizer,
        private elRef: ElementRef
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.image) {
            if (this.src) {
                window.URL.revokeObjectURL(this.src.toString());
            }

            if (this.image) {
                if (this.fallbackSrc) {
                    this.updateImageSrc(this.fallbackSrc);
                }

                this.fetchImage();
            }
        }
    }

    ngOnDestroy(): void {
        if (this.src) {
            window.URL.revokeObjectURL(this.src.toString());
        }
    }

    private async fetchImage() {
        try {
            const imageData = await lastValueFrom<Blob>(this.imageService.getImageData(this.image, this.imageSize));
            const src = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(imageData));

            this.updateImageSrc(this.sanitizer.sanitize(SecurityContext.URL, src));
        } catch (error) {
            this.src = this.fallbackSrc;
        }
    }

    private updateImageSrc(src: string) {
        this.elRef.nativeElement.src = src;
    }
}
