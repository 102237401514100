import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AppStoreItem} from '../../models/app-store';
import {appStoreItemStatusTranslation} from '../../models/app-store-item-status';

@Component({
    selector: 'app-app-store-card',
    templateUrl: './app-store-card.component.html'
})
export class AppStoreCardComponent {
    @Input() app: AppStoreItem;
    @Input() btnLabel = 'Instellingen';
    @Output() appClick = new EventEmitter<AppStoreItem>();

    readonly appStoreItemStatusTranslation = appStoreItemStatusTranslation;
}
