import {Component, OnInit} from '@angular/core';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {UnauthorizedModalComponent} from '../../components/unauthorized-modal/unauthorized-modal.component';

@Component({
    selector: 'app-unauthorized',
    template: ''
})
export class UnauthorizedComponent implements OnInit {
    constructor(private vwuiModalService: VwuiModalService) {
    }

    ngOnInit(): void {
        this.vwuiModalService.open(UnauthorizedModalComponent);
    }
}
