export const projectHistoryPropertyTranslations = {
    name : 'Naam',
    created : 'Aangemaakt',
    commercialName : 'Commerciele naam',
    beginDate : 'Begin datum',
    endDate : 'Eind datum',
    client : 'Client',
    postCode : 'Postcode',
    houseNumber : 'Huisnummer',
    street : 'Straatnaam',
    city : 'Stad',
    companyName : 'Bedrijfsnaam',
    longitude : 'Longitude',
    latitude : 'Latitude',
    type : 'Werkmethode',
    buildingClassification : 'Gebouw klassificatie',
    buildingSubClassification : 'Gebouw Subklassificatie',
    vwConcept : 'VolkerWessels woonconcept',
    sustainabilityClass : 'Duurzaamheidsklasse',
    image : 'Foto',
    owner : 'Eigenaar',
    finalized : 'Afgerond',
    projectLabel : 'Projectlabel',
    operationalProjectStatus : 'Operationeel project status',
    projectMembers : 'Projectleden',
    kvwNumber: 'Onderneming',
    bouwcombinatieKvwNumbers : 'Combinant / Partner',
    startDate : 'Startdatum',
    createdAt : 'Aangemaakt op',
    projectAppStoreItems : 'Applicaties',
    operationalProjectStatusGUID: 'Operationele projectstatus',
    buildingClassificationGUID: 'Gebruiksfunctie',
    buildingSubClassificationGUID: 'Sub-gebruiksfunctie',
    sustainabilityClassGUID: 'Duurzaamheid classificatie',
    typeGUID: 'Werksoort',
    vwConceptGUID: 'VolkerWessels concept',
    description: 'Korte beschrijving project'
}
