import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Project} from '../../models/project';
import {BehaviorSubject, combineLatest, firstValueFrom, lastValueFrom, Observable, of, Subscription} from 'rxjs';
import {ProjectService} from '../../services/project.service';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {CreateProjectModalComponent} from '../../components/create-project-modal/create-project-modal.component';
import {catchError, map, mapTo, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {UntypedFormControl} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AuthorizationService} from '../../services/authorization.service';
import {ProjectUserPreferencesService} from '../../services/project-user-preferences.service';
import {noLabelColor, ProjectLabel} from '../../models/project-label';
import {ProjectLabelService} from '../../services/project-label.service';
import {NotificationService} from '../../services/notification.service';
import {InfiniteScrollPaginator} from '../../utils/infinite-scroll-paginator';
import {mergeQueryParamsAndNavigate} from '../../utils/merge-query-params-and-navigate';
import {createEmptyPageResponse} from '../../models/page-response';
import {ProjectUserPreference} from '../../models/project-user-preference';

const ContentViewModeStorageKey = 'list-view-mode';

type ContentViewMode = 'grid' | 'table';

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html'
})
export class ProjectListComponent implements OnInit, OnDestroy {
    selectedContentViewMode: ContentViewMode = 'grid';
    noLabelColor = noLabelColor;
    searchControl = new UntypedFormControl();

    refresh$ = new BehaviorSubject<void>(null);

    filterControl = new UntypedFormControl({});
    defaultFiltersApplied$ = new BehaviorSubject<boolean>(false);

    projectPaginator$: Observable<InfiniteScrollPaginator<Project>> = combineLatest([
        this.route.queryParams,
        this.filterControl.valueChanges.pipe(startWith({})),
        this.defaultFiltersApplied$,
        this.refresh$,
    ]).pipe(
        switchMap(([params, form, defaultFiltersApplied]) => {
            if (!defaultFiltersApplied) {
                return [];
            }

            if (params.search) {
                form = {...form, search: [params.search.toLowerCase()]}
            }

            const paginator = new InfiniteScrollPaginator(page => {
                return this.projectService.findProjectsWithCompany(
                    page,
                    form,
                    {
                        field: params.field ?? 'id',
                        direction: params.direction ?? 'desc'
                    }
                ).pipe(
                    catchError(error => of(createEmptyPageResponse<Project>())),
                )
            });

            /** Wait for first page of results before returning the paginator. This prevents flash of empty content */
            return paginator.content$.pipe(
                mapTo(paginator)
            );
        }),
        shareReplay(1)
    );

    projectLabels$ = combineLatest([
        this.refresh$,
    ]).pipe(
        switchMap(([_]) => {
            return this.projectLabelService.getActive()
        }),
        map(response => response._embedded.projectLabels),
        shareReplay(1)
    );

    private subscriptions: Subscription[] = [];
    modalOpen = false;
    labelSelectorOpen = false;

    filter$ = combineLatest([
        this.filterControl.valueChanges.pipe(startWith({})),
        this.searchControl.valueChanges.pipe(startWith('')),
    ])
    facets$ = combineLatest([
        this.projectService.facets(),
        this.route.queryParams,
    ]).pipe(
        map(([facets, params]) => {
            const cleanedParams = {...params}
            delete cleanedParams.search
            const hasFilter = Object.keys(cleanedParams).length > 0
            if (hasFilter) {
                facets[0].items.forEach(item => {
                    item.default = []
                })
            }

            Object.keys(params).forEach(key => {
                facets[0].items.forEach(item => {
                    if (item.field === key) {
                        const newValue = (Array.isArray(params[key]) ? params[key] : [params[key]]).map(value => {
                            return {key: value, value}
                        });

                        item.default = newValue
                    }
                })
            })

            return facets
        }),
        shareReplay(1)
    )

    notificationsRefresh$ = new BehaviorSubject<void>(null)
    notifications$ = this.notificationsRefresh$.pipe(
        switchMap(() => this.notificationService.list()),
    );

    sortingSubject = new BehaviorSubject<{field: string, direction: 'asc' | 'desc'}>(null);

    constructor(
        @Inject('ProjectService') private projectService: ProjectService,
        @Inject('ProjectLabelService') private projectLabelService: ProjectLabelService,
        @Inject('AuthorizationService') private authorizationService: AuthorizationService,
        @Inject('ProjectUserPreferencesService') private projectUserPreferenceService: ProjectUserPreferencesService,
        @Inject('NotificationService') private notificationService: NotificationService,
        private toast: ToastrService,
        private modalService: VwuiModalService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.setContentViewMode(localStorage.getItem(ContentViewModeStorageKey) as ContentViewMode);
    }

    ngOnInit() {
        this.subscriptions.push(this.filter$.subscribe(([filter, search]) => {
            this.mergeQueryParams({...filter, search});
        }))

        const params = {...this.route.snapshot.queryParams};
        this.searchControl.patchValue(params.search);
        delete params.search;
        Object.keys(params).forEach(key => {
            if (!Array.isArray(params[key])) {
                params[key] = [params[key]]
            }
        })
        this.filterControl.patchValue(params)

        if (params.newProjectLocation?.[0]) {
            const [latitude, longitude] = params.newProjectLocation[0].split(',');

            this.openCreateProjectModal({latitude, longitude})
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }

    onProjectClick(item: Project) {
        this.router.navigate(['/projects', item.id], {queryParams: {tab: 'detail'}});
    }

    setContentViewMode(contentViewMode: ContentViewMode) {
        const mode = contentViewMode ? contentViewMode : 'grid';

        this.selectedContentViewMode = mode;
        localStorage.setItem(ContentViewModeStorageKey, mode);
    }

    openCreateProjectModal(location?: { latitude: number, longitude: number }) {
        this.modalOpen = true;
        const modal = this.modalService.open(CreateProjectModalComponent, {
            data: location,
            modalClass: 'modal-lg'
        });

        modal.afterClosed.subscribe(it => {
            this.modalOpen = false;
            this.refresh$.next(null);
            this.router.navigate(['/projects', it], {queryParams: {tab: 'detail'}});
        }, () => {
            this.modalOpen = false;
        });
    }

    async updateProjectActive(project: Project, active: boolean) {
        try {
            await lastValueFrom<ProjectUserPreference>(this.projectUserPreferenceService.findByProjectOrCreate(project.id, !active));

            this.refresh$.next(null)
            this.toast.success(`Project ${active ? 'zichtbaar gemaakt' : 'verborgen'}`);
        } catch (error) {
            console.error(error);
            this.toast.error('Bijwerken van project mislukt.');
        }
    }

    async toggleLabelSelector(project: Project) {
        const editPermission = await lastValueFrom<boolean>(this.authorizationService.canUpdateDeleteProject$(project))
        if (editPermission) {
            this.labelSelectorOpen = !this.labelSelectorOpen
        }
    }

    async saveProjectLabel(project: Project, label: ProjectLabel) {
        const editPermission = await lastValueFrom<boolean>(this.authorizationService.canUpdateDeleteProject$(project));
        if (!editPermission) {
            return
        }

        try {
            if (label == null) {
                await lastValueFrom<Project>(this.projectService.deleteLabel(project));
            } else {
                await lastValueFrom<Project>(this.projectService.replaceLabel(project, label));
            }

            this.toast.success('Projectlabel successvol aangepast')
        } catch (e) {
            console.error('Error while saving project label', e)
            this.toast.error('Projectlabel aanpassen mislukt')
        } finally {
            this.labelSelectorOpen = false
            this.refresh$.next(null);
        }
    }

    private mergeQueryParams(params: Params): Promise<boolean> {
        return this.router.navigate([], {
            relativeTo: this.route,
            queryParamsHandling: 'merge',
            queryParams: params
        });
    }

    async acknowledgeNotification(id: number) {
        await firstValueFrom(this.notificationService.acknowledge(id));
        this.notificationsRefresh$.next();
    }

    changeSort(field: string) {
        const direction = this.sortingSubject.value?.field === field && this.sortingSubject.value?.direction === 'asc'
            ? 'desc' : 'asc';

        this.sortingSubject.next({field, direction});

        mergeQueryParamsAndNavigate(this.router, this.route, this.sortingSubject.value);
    }
}
