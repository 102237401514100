<ng-container *ngIf="appStoreItem$ | async as app">
    <vwui-header>
        {{app.title}}

        <vwui-button *ngIf="!(app.type === 'Primary')" slot="action" class="is-neutral" icon="vwui-trash"
                     (click)="openDeleteConfirmModal(app)" [appDisabledWhenAppStoreItemUsed]="app"></vwui-button>
        <vwui-button slot="action" class="is-primary" (click)="save(app)">Opslaan</vwui-button>

        <vwui-breadcrumb slot="breadcrumbs">
            <a routerLink="/app-store">App Beheer</a>
        </vwui-breadcrumb>
        <vwui-breadcrumb slot="breadcrumbs">{{app.title}}</vwui-breadcrumb>
    </vwui-header>

    <form [formGroup]="form" *ngIf="form" (submit)="save(app)">
        <vwui-layout type="split">
            <vwui-card slot="split-left">
                <vwui-card-header>
                    Algemeen
                </vwui-card-header>
                <vwui-card-body>
                    <vwui-form-group *ngIf="app.type === 'Secondary'" label="Interne titel">
                        <vwui-input inputId="internalTitle" formControlName="internalTitle"
                                    [readonly]="true"></vwui-input>
                    </vwui-form-group>
                    <vwui-form-group label="Titel" [required]="true">
                        <vwui-input inputId="title" placeholder="Applicatie titel" formControlName="title"></vwui-input>
                    </vwui-form-group>
                    <vwui-form-group label="Type" [required]="true" *ngIf="app.type === 'Secondary'">
                        <ng-select formControlName="secondaryType" placeholder="Kies een extensietype" appendTo="body" [clearable]="false">
                            <ng-option value="TrimbleViewer">3D-Viewer</ng-option>
                            <ng-option value="TrimbleExplorer">Verkenner</ng-option>
                            <ng-option value="Extension">Extensie</ng-option>
                            <ng-option value="Addon">Overige applicatie</ng-option>
                        </ng-select>
                    </vwui-form-group>
                    <vwui-form-group label="URL" [required]="true">
                        <vwui-input inputId="url" placeholder="https://www.voorbeeld.com" formControlName="url"></vwui-input>
                    </vwui-form-group>
                    <vwui-form-group label="Afhankelijkheden" *ngIf="app.type === 'Secondary'">
                        <ng-select formControlName="dependencies"
                                   placeholder="Kies afhankelijkheden"
                                   appendTo="body"
                                   [items]="(dependencies$ | async)?._embedded['app-store-items']"
                                   [multiple]="true"
                                   bindLabel="title"
                                   [hideSelected]="true">
                        </ng-select>
                    </vwui-form-group>
                    <vwui-form-group label="Icoon" [required]="true">
                        <app-app-store-item-icon-upload formControlName="icon" [appStoreItem]="app">
                        </app-app-store-item-icon-upload>
                    </vwui-form-group>
                    <vwui-form-group label="App activatie" required *ngIf="app.type === 'Secondary'">
                        <app-activation-type-select formControlName="activationType"
                                                    [excludedOptions]="app.type === 'Primary' ? ['Automatic'] : []">
                        </app-activation-type-select>
                    </vwui-form-group>

                    <vwui-form-group label="Status" [required]="true">
                        <ng-select formControlName="status" placeholder="Kies een status" appendTo="body">
                            <ng-option *ngFor="let appStatus of appStatusList" [value]="appStatus">
                                <app-app-store-status-label [status]="appStatus"></app-app-store-status-label>
                            </ng-option>

                            <ng-template ng-option-tmp let-item="item">
                                <app-app-store-status-label [status]="item"></app-app-store-status-label>
                            </ng-template>
                        </ng-select>
                    </vwui-form-group>
                </vwui-card-body>
            </vwui-card>
            <vwui-card slot="split-right">
                <vwui-card-header>
                    Omschrijving
                </vwui-card-header>
                <vwui-card-body>
                    <ckeditor [editor]="ckEditor" [config]="ckConfig" formControlName="description"></ckeditor>
                </vwui-card-body>
            </vwui-card>
        </vwui-layout>
        <input type="submit" hidden>
    </form>
</ng-container>
