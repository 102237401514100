<div class="detail-modal-header">
    <vwui-modal-header>
        <div class="d-flex">
            <div class="item-card__icon">
                <div *ngIf="app.iconSas" class="app-store-icon">
                    <img [src]="app.iconSas">
                </div>
            </div>
            <div>
                <vwui-breadcrumb>
                    <span>Appstore</span>
                </vwui-breadcrumb>
                <vwui-breadcrumb>
                    <span>{{app.title}}</span>
                </vwui-breadcrumb>
                <h3>{{app.title}}</h3>
            </div>
        </div>
    </vwui-modal-header>
</div>

<vwui-modal-body>
    <vwui-layout type="split">
        <div slot="split-left">
            <h3>Omschrijving</h3>
            <div [innerHTML]="app.description"></div>
        </div>

        <div slot="split-right" [formGroup]="form" class="app-details-form">
            <h3 class="mb-2">Applicatie activeren</h3>
            <div class="mb-2">Vul onderstaande gegevens in en klik op ‘activeren’.</div>

            <vwui-btn-option-group name="existingApplication" formControlName="existingApplication">
                <vwui-btn-option value="false">Nieuw</vwui-btn-option>
                <vwui-btn-option value="true">Bestaand</vwui-btn-option>
            </vwui-btn-option-group>
            <br />

            <vwui-form-group
                label="URL"
                *ngIf="this.form.get('existingApplication').value === 'true'"
            >
                <vwui-input
                    placeholder="https://bouwpas.nl/projects/.."
                    formControlName="applicationNumber"
                ></vwui-input>
            </vwui-form-group>
            <vwui-form-group
                label="Bouwpas eigenaar"
                *ngIf="this.form.get('existingApplication').value !== 'true'"
            >
                <app-graph-user-select formControlName="owner"></app-graph-user-select>
            </vwui-form-group>

            <vwui-form-group
                label="Template"
                *ngIf="this.form.get('existingApplication').value !== 'true'"
            >
                <app-bouwpas-template-select formControlName="template"></app-bouwpas-template-select>
            </vwui-form-group>

            <vwui-form-group
                label="Bedrijf / bouwcombinatie"
                *ngIf="this.form.get('existingApplication').value !== 'true'"
            >
                <app-bouwpas-contractor-select [project]="project" formControlName="contractor"></app-bouwpas-contractor-select>
            </vwui-form-group>

            <vwui-form-group label="Navision voor data uitwisseling">
                <app-navision-application-select [project]="project" formControlName="navApplication"></app-navision-application-select>
            </vwui-form-group>

            <vwui-button class="is-primary"
                         [disabled]="!form.valid || submitPending"
                         [loading]="submitPending"
                         (click)="save()">
                Activeren
            </vwui-button>
        </div>
    </vwui-layout>

</vwui-modal-body>

<vwui-modal-footer>
    <vwui-button class="is-light" (click)="modalRef.close()">Venster sluiten</vwui-button>
</vwui-modal-footer>
