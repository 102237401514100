import {Injectable} from '@angular/core';
import {TemplateService} from '../template.service';
import {Observable, of, throwError} from 'rxjs';
import {TemplateResponse} from '../../models/template-response';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class TemplateServiceMock implements TemplateService {
    getTemplates(kvwNumbers: Array<string>): Observable<TemplateResponse> {
        if (kvwNumbers.length === 0) {
            return throwError(new HttpErrorResponse({status: 400, statusText: 'Bad request'}));
        }

        if (kvwNumbers.includes('0022')) {
            return of({
                availableTemplates: ['default', 'Recognize', 'Hyrde'],
                templateExists: true
            });
        } else if (kvwNumbers.includes('1234')) {
            return of({
                availableTemplates: ['default', 'BVGO'],
                templateExists: true
            });
        }

        return of({
            availableTemplates: ['default'],
            templateExists: false
        });
    }
}
