import {Directive, ElementRef, Inject, OnDestroy} from '@angular/core';
import {AuthorizationService} from '../services/authorization.service';
import {Subscription} from 'rxjs';

@Directive({
    selector: '[appShowIfCanCreateProjects]'
})
export class ShowWhenCanCreateProjectsDirective implements OnDestroy {

    private subscriptions: Subscription[] = [];

    constructor(
        private element: ElementRef,
        @Inject('AuthorizationService') private authorizationService: AuthorizationService,
    ) {
        this.subscriptions.push(
            this.authorizationService.canCreateProject$.subscribe(canCreateProject => {
                if (!canCreateProject) {
                    this.element.nativeElement.style.display = 'none';
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
