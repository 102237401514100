<ng-container *ngIf="project$ | async as project">
    <vwui-header>
        <div class="d-flex">
            <vwui-button class="back-button" (click)="back()">
                <img src="assets/icons/arrow-left.svg"
                     alt="Terug naar projecten">
            </vwui-button>
            <div>
                <vwui-breadcrumb>
                    <a routerLink="/projects">Projecten</a>
                </vwui-breadcrumb>
                <vwui-breadcrumb>
                    {{project.name}}
                </vwui-breadcrumb>
                <h3>
                    {{project.name}}
                    <vwui-tooltip position="top" [label]="project.projectLabel ? project.projectLabel.name : 'Productie'">
                        <vwui-icon class="project-archive-icon tiny-icon text-vwui-success" [style.color]="project.projectLabel?.color" iconName="dot"></vwui-icon>
                    </vwui-tooltip>
                </h3>
            </div>
        </div>
        <div slot="action">
            <ng-container *ngIf="(applicationCount$ | async) === 0">
                <vwui-button appShowIfCanUpdateProject [project]="project$ | async" class="is-neutral" (click)="openDeleteProjectModal()" icon="vwui-trash"></vwui-button>
            </ng-container>
            <ng-container *ngIf="(applicationCount$ | async) > 0">
                <vwui-button *ngIf="!project.finalized; else reopenProject" appShowIfCanUpdateProject [project]="project$ | async" class="is-neutral" (click)="openFinalizeProjectModal()">
                    Afsluiten
                </vwui-button>
                <ng-template #reopenProject>
                    <vwui-button appShowIfCanUpdateProject [project]="project$ | async" class="is-neutral" (click)="openReopenProjectModal()">
                        Heropenen
                    </vwui-button>
                </ng-template>
            </ng-container>
        </div>
    </vwui-header>

    <ng-container *ngIf="(route.queryParams|async)?.tab || 'detail' as tab">
        <vwui-tab-bar [selected]="tab" (vwuiTabChange)="onTabChange($event.detail)">
            <vwui-tab tabId="detail">Algemeen</vwui-tab>
            <vwui-tab tabId="location">Locatie </vwui-tab>
            <vwui-tab tabId="features">Kenmerken</vwui-tab>
            <vwui-tab tabId="applications">Applicaties</vwui-tab>
            <vwui-tab *ngIf="canSeeHistory$|async" tabId="history">Geschiedenis</vwui-tab>
        </vwui-tab-bar>

        <ng-template let-readonly [ngTemplateOutletContext]="{ $implicit: (readonly$|async) }" [ngTemplateOutlet]="tabs" #tabs>
            <div ngSwitch="{{ tab }}">
                <app-project-detail-tab [readonly]="readonly" *ngSwitchCase="'detail'" [project]="project" (projectUpdated)="onProjectUpdated()"> </app-project-detail-tab>
                <app-project-location-tab [readonly]="readonly" *ngSwitchCase="'location'" [project]="project" (projectUpdated)="onProjectUpdated()"></app-project-location-tab>
                <app-project-features-tab [readonly]="readonly" *ngSwitchCase="'features'" [project]="project" (projectUpdated)="onProjectUpdated()"></app-project-features-tab>
                <app-project-applications-tab [readonly]="readonly" *ngSwitchCase="'applications'" [project]="project" (projectUpdated)="onProjectUpdated()"></app-project-applications-tab>
                <app-project-history-tab [readonly]="readonly" *ngSwitchCase="'history'" [project]="project" (projectUpdated)="onProjectUpdated()"></app-project-history-tab>
            </div>
        </ng-template>
    </ng-container>
</ng-container>
