<div class="detail-modal-header" [class.detail-modal-header--light-bg]="app.appType === 'Secondary'">
    <vwui-modal-header [class.detail-modal-header--light-close-btn]="app.appType === 'Primary'">
        <div class="d-flex">
            <div class="item-card__icon">
                <div *ngIf="app.iconSas" class="app-store-icon">
                    <img [src]="app.iconSas">
                </div>
            </div>
            <div>
                <vwui-breadcrumb>
                    <span>{{project.name}}</span>
                </vwui-breadcrumb>
                <vwui-breadcrumb>
                    <span>{{app.title}}</span>
                </vwui-breadcrumb>
                <h3>{{app.title}}</h3>
            </div>
        </div>
    </vwui-modal-header>
</div>

<vwui-modal-body>
    <ng-container *ngIf="isSecondary(app) as secondaryApp;else primary">
        <div class="status-box mb-4">
            <vwui-icon iconName="vwui-check" class="status-box__icon status-box__icon--active"></vwui-icon>
            <span class="status-box__text">Applicatie is geactiveerd.</span>
        </div>

        <div class="mb-4" *ngIf="app.secondaryType === 'TrimbleExplorer'">
            <p>Verkenner extensie geactiveerd voor de volgende Trimble Projecten:</p>
            <ul class="list-style-inside">
                <li *ngFor="let activation of explorerAppActivations$ | async">{{activation.trimbleProjectId}}</li>
            </ul>
        </div>

        <vwui-layout type="split">
            <vwui-layout type="split">
                <div slot="split-left">
                    <h3>Omschrijving</h3>
                    <div [innerHTML]="app.description"></div>
                </div>

                <div slot="split-right" [formGroup]="form" class="app-details-form">
                    <h3 class="mb-2">Details</h3>

                    <vwui-form-group *ngIf="app.secondaryType === 'Addon'" label="Url" inputId="url">
                        <vwui-input formControlName="url" [readonly]="true"></vwui-input>
                    </vwui-form-group>
                    <vwui-form-group label="Navision-administratie" inputId="projectId" class="app-details-form" *ngIf="secondaryApp.navisionAdministration">
                        <vwui-input formControlName="navision" [readonly]="true"></vwui-input>
                    </vwui-form-group>
                    <vwui-button
                            appShowIfSuperAdmin
                            [id]="'detail-' + kebabCase(app.title)"
                            class="item-card__button btn-red-text mt-md"
                            [block]="true"
                            (click)="deleteApplication()">
                        Ontkoppelen
                    </vwui-button>
                </div>
            </vwui-layout>
        </vwui-layout>
    </ng-container>

    <ng-template #primary>
        <ng-container *ngIf="app.status === 'COMPLETED'">
            <div class="status-box mb-4">
                <vwui-icon iconName="vwui-check" class="status-box__icon status-box__icon--active"></vwui-icon>
                <span class="status-box__text">Applicatie is geactiveerd.</span>
            </div>
        </ng-container>
        <ng-container *ngIf="app.status === 'CREATED'">
            <div class="status-box mb-4">
                <vwui-spinner class="status-box__icon"></vwui-spinner>
                <span class="status-box__text">Applicatie wordt geactiveerd.</span>
            </div>
        </ng-container>

        <vwui-layout type="split">
            <div slot="split-left">
                <h3>Omschrijving</h3>
                <div [innerHTML]="app.description"></div>
            </div>

            <div slot="split-right" [formGroup]="form" class="app-details-form">
                <h3 class="mb-2">Details</h3>

                <vwui-form-group label="ID" inputId="projectId">
                    <vwui-input formControlName="applicationNumber" [readonly]="true"></vwui-input>
                </vwui-form-group>
                <vwui-form-group label="Status">
                    <vwui-input inputId="status" formControlName="status" [readonly]="true"></vwui-input>
                </vwui-form-group>

                <ng-container *ngIf="app.type === 'trimbleConnect'">
                    <vwui-form-group label="Projectadmin" inputId="email">
                        <vwui-input formControlName="email" [readonly]="true"></vwui-input>
                    </vwui-form-group>
                </ng-container>

                <ng-container *ngIf="app.type === 'nav'">
                    <vwui-form-group label="Navision-administratie" inputId="navAdmin">
                        <vwui-input formControlName="navAdmin" [readonly]="true"></vwui-input>
                    </vwui-form-group>
                </ng-container>

                <ng-container *ngIf="app.type === 'teams'">
                    <vwui-form-group label="Eigenaar" inputId="owner">
                        <vwui-input formControlName="owner" [readonly]="true"></vwui-input>
                    </vwui-form-group>
                    <vwui-form-group label="Template" inputId="template">
                        <vwui-input formControlName="template" [readonly]="true"></vwui-input>
                    </vwui-form-group>
                </ng-container>

                <ng-container *ngIf="app.type === 'bouwpas'">
                    <vwui-form-group label="Bouwpas eigenaar">
                        <vwui-input formControlName="owner" [readonly]="true"></vwui-input>
                    </vwui-form-group>

                    <vwui-form-group label="Template">
                        <vwui-input formControlName="template" [readonly]="true"></vwui-input>
                    </vwui-form-group>

                    <vwui-form-group label="Bedrijf / bouwcombinatie">
                        <vwui-input formControlName="contractor" [readonly]="true"></vwui-input>
                    </vwui-form-group>

                    <vwui-form-group label="Navision voor data uitwisseling">
                        <vwui-input formControlName="navApplication" [readonly]="true"></vwui-input>
                    </vwui-form-group>
                </ng-container>

                <ng-container *ngIf="app.type === 'projectRisicoMonitor'">
                    <vwui-form-group label="Verantwoordelijke">
                        <vwui-input formControlName="owner" [readonly]="true"></vwui-input>
                    </vwui-form-group>
                </ng-container>

                <ng-container *ngIf="app.type === 'projectSight'">
                    <vwui-form-group label="Portfolio">
                        <vwui-input formControlName="portfolioId" [readonly]="true"></vwui-input>
                    </vwui-form-group>

                    <vwui-form-group label="Projectadmin">
                        <vwui-input formControlName="projectAdmin" [readonly]="true"></vwui-input>
                    </vwui-form-group>

                    <vwui-form-group label="Trimble Connect template">
                        <vwui-input formControlName="trimbleConnectTemplate" [readonly]="true"></vwui-input>
                    </vwui-form-group>

                    <vwui-form-group label="Trimble Connect project ID">
                        <vwui-input formControlName="trimbleConnectProjectId" [readonly]="true"></vwui-input>
                    </vwui-form-group>

                    <vwui-form-group label="ProjectSight slabloon template">
                        <vwui-input value="{{(projectSightParentProject$ | async)?.name}}" [readonly]="true"></vwui-input>

                        <div *ngIf="form.value.copyFromProject" class="pt-2">
                            <div>
                                Projectinstellingen overnemen: {{form.value.copyProjectSettings ? 'ja' : 'nee'}}
                            </div>
                            <div>
                                Gebruikers overnemen: {{form.value.copyUsers ? 'ja' : 'nee'}}
                            </div>
                        </div>
                    </vwui-form-group>
                </ng-container>

                <vwui-button
                    appShowIfSuperAdmin
                    [id]="'detail-' + kebabCase(app.title)"
                    class="item-card__button btn-red-text"
                    [block]="true"
                    (click)="deleteApplication()">
                    Ontkoppelen
                </vwui-button>
            </div>
        </vwui-layout>
    </ng-template>
</vwui-modal-body>

<vwui-modal-footer>
    <vwui-button class="is-light" (click)="modalRef.close()">Venster sluiten</vwui-button>
</vwui-modal-footer>

