import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'searchHighlight'
})
export class SearchHighlightPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(value: any, highlightText: string): any {
        const safeValue = this.sanitizer.sanitize(SecurityContext.HTML, value);
        const highlightRegex = highlightText.split(' ').filter(it => {
            return it.trim() !== '' && it.length > 1;
        }).join('|');
        return this.sanitizer.bypassSecurityTrustHtml(
            !highlightRegex
                ? safeValue
                : safeValue.replace(
                new RegExp(highlightRegex, 'gi'),
                match => ` <b>${match}</b>`
                ).replace(// Fix spaces not showing when 2 spans are next to each other
                /<\/b>(\s+)<b/gi,
                (match, spaces) => `${spaces}</b><b`
                )
        );
    }

}
