<ng-container *ngIf="projectAccessList$ | async as projectAccessList">
    <vwui-grid class="grid recent-projects-grid" [fillItems]="true" *ngIf="projectAccessList.length > 0">
        <h3 slot="grid-title">Recent geopend</h3>

        <ng-container *ngFor="let project of projectAccessList">
            <div
                class="item-card item-card--hover"
                (click)="projectClick.emit(project)"
            >
                <div class="item-card__header">
                    <img *ngIf="project.image" class="item-card__image" appBlobImage [image]="project.image" imageSize="thumbnail"
                         fallbackSrc="./assets/images/vwicon.svg">
                    <img *ngIf="project.image === null" class="item-card__image" src="./assets/images/vwicon.svg">
                    <div class="item-card__title-container">
                        <div class="item-card__title">{{project.name}}</div>
                        <div class="item-card__subtitle">{{project.kvwNumber ? (project.kvwNumber + ' - ' + project.companyName) : project.companyName}}</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </vwui-grid>
</ng-container>
