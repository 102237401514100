<div class="item-card">
    <div class="item-card__header"
         [class.item-card__header--light-bg]="app.type === 'Secondary' && app.secondaryType !== 'Addon'"
         [class.item-card__header--gray-bg]="app.type === 'Secondary' && app.secondaryType === 'Addon'">
        <div class="item-card__icon">
            <div *ngIf="app.iconSas" class="app-store-icon">
                <img [src]="app.iconSas">
            </div>
        </div>
        <div class="item-card__titles">
            <div class="item-card__title" [class.item-card__title--black]="app.type === 'Secondary'">
                {{app.title}}
            </div>
            <div class="item-card__subtitle" [class.item-card__subtitle--blue]="app.type === 'Secondary'">
                {{appStoreItemStatusTranslation[app.status]}}
            </div>
        </div>
    </div>
    <div class="item-card__content">
        <vwui-button class="item-card__button" [block]="true" (click)="appClick.emit(app)">{{btnLabel}}</vwui-button>
    </div>
</div>
