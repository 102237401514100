import {Component} from '@angular/core';
import {MigrationService} from '../../services/migration.service';
import {lastValueFrom} from 'rxjs';

@Component({
    selector: 'app-migrate-company-names',
    templateUrl: './migration-company-names.component.html'
})
export class MigrationCompanyNamesComponent {
    migrationStarted = false;
    logs = '';

    constructor(
        private migrationsService: MigrationService
    ) {}

    async startMigration() {
        this.migrationStarted = true;
        this.log('Migratie gestart...');

        try {
            const log = await lastValueFrom<string>(this.migrationsService.migrateCompanyNames()) || '';
            this.logs += log;
            this.log('Migratie is uitgevoerd!');
        } catch (err) {
            this.log('Onbekend fout opgetreden tijdens migratie');
        }
    }

    private log(message: string) {
        this.logs += message + '\n';
    }
}
