import * as atlas from 'azure-maps-rest';

export function createEmptyAzureSearchResponse(): atlas.Models.SearchFuzzyResponse {
    return {
        results: [],
        summary: {
            fuzzyLevel: 0,
            numResults: 0,
            offset: 0,
            query: '',
            totalResults: 0
        }
    };
}
