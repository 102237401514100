import {Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {AppStoreItem, appTypeTranslation} from '../../models/app-store';
import {AppStoreItemService} from '../../services/app-store-item.service';
import {kebabCase} from '../../utils/kebab-case';
import {lastValueFrom} from 'rxjs';

@Component({
    selector: 'app-app-store-item-card',
    templateUrl: './app-store-item-card.component.html'
})
export class AppStoreItemCardComponent implements OnChanges{
    @Input() app: AppStoreItem = null;
    @Input() btnLabel = 'Meer informatie';
    dependencies: AppStoreItem[] = [];

    @Output() appClick = new EventEmitter<AppStoreItem>();

    readonly appTypeTranslation = appTypeTranslation;
    kebabCase = kebabCase;

    constructor(
        @Inject('AppStoreItemService') private appStoreItemService: AppStoreItemService,
    ) {}

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.app) {
            this.dependencies = await lastValueFrom<AppStoreItem[]>(this.appStoreItemService.getCollectionRelation(this.app._links.dependencies, 'app-store-items'));
        }
    }
}
