<vwui-modal-body>
    <ng-container *ngIf="paginator$ | async as paginator">
        <ng-container *ngIf="paginator.content$ | async as content">
            <vwui-grid [fillItems]="true">
                <div slot="grid-title">
                    <h2>Applicaties</h2>
                </div>

                <app-pagination slot="grid-pagination" [paginator]="paginator"></app-pagination>
                <ng-container *ngFor="let item of content">
                    <app-app-store-item-card
                        [app]="item"
                        [btnLabel]="item.secondaryType === 'Addon' ? 'Toevoegen' : 'Activeren'"
                        (appClick)="openAppStoreDetailModal($event)"
                    ></app-app-store-item-card>
                </ng-container>
            </vwui-grid>
        </ng-container>
    </ng-container>
</vwui-modal-body>

<vwui-modal-footer>
    <vwui-button class="is-light" (click)="modalRef.close()">Venster sluiten</vwui-button>
</vwui-modal-footer>

