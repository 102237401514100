<div *ngIf="app?.type === 'Secondary'" class="text-white">
    <div *ngIf="dependencies?.length <= 2; else multipleDependencies" [ngClass]="extraClass">
        <ng-container *ngFor="let dependency of dependencies">
            <vwui-tooltip appFade [label]="dependency.title">
                <div *ngIf="app.iconSas" class="app-store-icon app-store-icon--small ml-xs">
                    <img [src]="dependency.iconSas">
                </div>
            </vwui-tooltip>
        </ng-container>
    </div>
    <ng-template #multipleDependencies>
        <vwui-tooltip appFade [ngClass]="extraClass" [label]="multipleDependenciesTooltip">
            <div class="app-store-icon app-store-icon--small dependency-counter">
                {{dependencies.length}}
            </div>
        </vwui-tooltip>
    </ng-template>
</div>
