import {Injectable} from '@angular/core';
import {Project} from '../models/project';
import {AbstractRestService, AbstractRestServiceInterface, RestHttpParams, SortParams} from './abstract-rest.service';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PageableResponse, PageableToPage, PageResponse} from '../models/page-response';
import {map} from 'rxjs/operators';
import {AppStoreItem} from '../models/app-store';
import {RestUtils} from '../utils/rest-utils';
import {CheckDependenciesResponse} from '../models/check-dependencies-response';
import {SearchFacetGroupCollection} from '../components/project-list-filter/project-list-filter.component';
import {cleanFilter} from '../utils/filter';
import {ProjectLabel} from '../models/project-label';
import {RestCollection} from '../models/rest/rest-collection';
import {ProjectHistory} from '../models/projectHistory';

export interface ProjectService extends AbstractRestServiceInterface<Project> {
    searchProjects(searchFunction: string, params?: RestHttpParams, sort?: SortParams): Observable<PageResponse<Project>>;

    findRecent(params?: RestHttpParams, sort?: SortParams): Observable<PageResponse<Project>>;

    findProjectsWithCompany(page: number, filter: object, sort?: SortParams): Observable<PageResponse<Project>>;

    checkDependencies(project: Project, app: AppStoreItem): Observable<CheckDependenciesResponse>;

    facets(): Observable<SearchFacetGroupCollection[]>;

    deleteLabel(project: Project): Observable<Project>;

    getByApplicationNumber(id: number): Observable<Project>;

    replaceLabel(project: Project, label: ProjectLabel): Observable<Project>;

    history(project: Project, page): Observable<PageResponse<ProjectHistory>>
}

@Injectable()
export class ProjectServiceImpl extends AbstractRestService<Project> implements ProjectService {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, 'projects');
    }

    history(project: Project, page: number): Observable<PageResponse<ProjectHistory>> {
        return this.httpClient.get<PageableResponse<ProjectHistory>>(
            `/api/v1/${this.resourcePath}/${project.id}/history`,
            {
                params: {
                    page: `${page}`,
                }
            }).pipe(map(it => ({
            content: it.content,
            page: PageableToPage(it)
        })));
    }

    get(id: number): Observable<Project> {
        return this.httpClient.get<Project>(`/api/v1/${this.resourcePath}/${id}?projection=projectDetail`);
    }

    getByApplicationNumber(id: number): Observable<Project> {
        return this.httpClient.get<Project>(`/api/v1/${this.resourcePath}?applicationNumber=${id}`);
    }

    searchProjects(searchFunction: string, params: RestHttpParams, sort?: SortParams) {
        return this.httpClient.get<RestCollection<Project>>(
            `/api/v1/${this.resourcePath}/search/${searchFunction}?projection=projectList`,
            {
                params: {
                    ...params,
                    ...(sort ? {sort: sort.field + ',' + sort.direction} : {}),
                }
            }).pipe(map(it => ({
            content: it._embedded[this.resourceName],
            page: it.page
        })));
    }

    findRecent(params: RestHttpParams, sort?: SortParams) {
        return this.httpClient.get<PageableResponse<Project>>(
            `/api/v1/${this.resourcePath}/search/findRecent?projection=projectList`,
            {
                params: {
                    ...params,
                    ...(sort ? {sort: sort.field + ',' + sort.direction} : {}),
                }
            }).pipe(map(it => ({
            content: it.content,
            page: PageableToPage(it)
        })));
    }

    findProjectsWithCompany(page: number, filter: object, sort?: SortParams) {
        return this.httpClient.post<PageableResponse<Project>>(
            `/api/v1/${this.resourcePath}/search/findProjectsWithCompany?projection=projectList`,
            {
                page,
                filter: cleanFilter(filter),
                ...(sort ? {sort: sort.field + ',' + sort.direction} : {}),
            }).pipe(map(it => ({
            content: it.content,
            page: PageableToPage(it)
        })));
    }

    checkDependencies(project: Project, app: AppStoreItem): Observable<CheckDependenciesResponse> {
        const params = new HttpParams()
            .append('app', `${RestUtils.getIdFromSelfLink(app._links.self)}`)

        return this.httpClient.get<CheckDependenciesResponse>(`/api/v1/${this.resourcePath}/${project.id}/dependency-check`, {params});
    }

    facets(): Observable<SearchFacetGroupCollection[]> {
        return this.httpClient.get<SearchFacetGroupCollection[]>(`/api/v1/${this.resourcePath}/facets`);
    }

    deleteLabel(project: Project): Observable<Project> {
        return this.httpClient.delete<Project>(`/api/v1/${this.resourcePath}/${project.id}/label`);
    }

    replaceLabel(project: Project, label: ProjectLabel): Observable<Project> {
        return this.httpClient.post<Project>(`/api/v1/${this.resourcePath}/${project.id}/label`, {...label});
    }
}
