import {AbstractRestService, AbstractRestServiceInterface} from './abstract-rest.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Project} from '../models/project';
import {Observable} from 'rxjs';
import {CreateApplication, ProjectAppStoreItem, ProjectAppStoreItemWithProject} from '../models/application';
import {ExplorerAppActivation} from '../models/explorer-app-activation';

export interface ProjectAppStoreItemService extends AbstractRestServiceInterface<ProjectAppStoreItem, CreateApplication> {
    getApplicationsBelongingToMyProjects(type?: string, status?: string): Observable<ProjectAppStoreItemWithProject[]>;

    countByProject(project: Project): Observable<number>;

    getTrimbleExplorerActivations(appId: number): Observable<ExplorerAppActivation[]>;
}

@Injectable()
export class ProjectAppStoreItemServiceImpl extends AbstractRestService<ProjectAppStoreItem> implements ProjectAppStoreItemService {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, 'project-app-store-items');
    }

    getApplicationsBelongingToMyProjects(type?: string, status?: string): Observable<ProjectAppStoreItemWithProject[]> {
        let params = new HttpParams();
        if (type) {
            params = params.set('type', type);
        }
        if (status) {
            params = params.set('status', status);
        }

        return this.httpClient.get<ProjectAppStoreItemWithProject[]>(`/api/v1/app-store-items/my-projects`, {params});
    }

    countByProject(project: Project) {
        const params = new HttpParams().set('project', '' + project.id);

        return this.httpClient.get<number>(`/api/v1/${this.resourcePath}/search/findCountByProject`, {params});
    }

    getTrimbleExplorerActivations(appId: number): Observable<any> {
        return this.httpClient.get<any>(`/api/v1/${this.resourcePath}/${appId}/trimble-explorer-activations`);
    }
}
