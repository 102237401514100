import {Injectable} from '@angular/core';
import {AbstractRestService, AbstractRestServiceInterface} from './abstract-rest.service';
import {HttpClient} from '@angular/common/http';
import {User} from '../models/user';
import {Observable} from 'rxjs';

export interface UserService extends AbstractRestServiceInterface<User> {
    findByEmailOrCreate(user: Omit<User, '_links'>): Observable<User>;
}

@Injectable()
export class UserServiceImpl extends AbstractRestService<User> implements UserService {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, 'users');
    }

    findByEmailOrCreate(user: Omit<User, '_links'>): Observable<User> {
        return this.httpClient.get<User>(`/api/v1/${this.resourcePath}/search/upsertByEmail`, { params: user });
    }
}
