import {Component} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {VwuiModalRef} from '@recognizebv/vwui-angular';

@Component({
    selector: 'app-authentication-required-modal',
    templateUrl: './authentication-required-modal.component.html',
})
export class AuthenticationRequiredModalComponent {

    constructor(
        public modalRef: VwuiModalRef,
        private msalService: MsalService
    ) {
    }

    doLogin() {
        this.msalService.loginRedirect();
    }
}
