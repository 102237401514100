import {ItemSelectComponent} from './item-select.component';
import {Component, forwardRef, Inject, Input} from '@angular/core';
import {Observable, of} from 'rxjs';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {createPageResponseOf, PageResponse} from '../../models/page-response';
import {map} from 'rxjs/operators';
import {ProjectAppStoreItemWithProject} from '../../models/application';
import {ProjectAppStoreItemService} from '../../services/project-app-store-item.service';

@Component({
    selector: 'app-project-app-store-item-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProjectAppStoreItemSelectComponent),
            multi: true
        }
    ]
})
export class ProjectAppStoreItemSelectComponent extends ItemSelectComponent<ProjectAppStoreItemWithProject, {}> {
    @Input() type: string;
    @Input() status: string;
    @Input() placeholder: string;

    constructor(
        @Inject('ProjectAppStoreItemService') private projectAppStoreItemService: ProjectAppStoreItemService,
    ) {
        super();
    }

    getFilter(): Observable<{}> {
        return of({});
    }

    getLabelText(item: ProjectAppStoreItemWithProject): string {
        return `${item.projectName} - ${item.applicationNumber}`;
    }

    loadMorePages(page: number, filter: { term: string }): Observable<PageResponse<ProjectAppStoreItemWithProject>> {
        return this.projectAppStoreItemService.getApplicationsBelongingToMyProjects(this.type, this.status).pipe(
            map(it => createPageResponseOf(it))
        )
    }
}
