import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import * as atlas from 'azure-maps-rest';
import SearchFuzzyResponse = atlas.Models.SearchFuzzyResponse;
import SearchAddressReverseResponse = atlas.Models.SearchAddressReverseResponse;
import Coordinate = atlas.Models.Coordinate;

export interface AzureMapsRestService {
    findFuzzy(addressLine: string, maxResults?: number): Observable<SearchFuzzyResponse>;
    reverseSearch(coordinate: Coordinate): Observable<SearchAddressReverseResponse>;
}

@Injectable()
export class AzureMapsRestServiceImpl implements AzureMapsRestService {
    private azureFuzzyUrl = 'https://atlas.microsoft.com/search/fuzzy/json';
    private addressReverseUrl = 'https://atlas.microsoft.com/search/address/reverse/json';

    constructor(private http: HttpClient) {
    }

    findFuzzy(addressLine: string, maxResults?: number): Observable<SearchFuzzyResponse> {
        let params = new HttpParams()
            .set('api-version', '1.0')
            .set('language', 'nl-NL')
            .set('lat', '52')
            .set('lon', '5.5')
            .set('countrySet', 'NL')
            .set('idxSet', 'Addr,POI,PAD,Str')
            .set('query', addressLine)
            .set('subscription-key', environment.azureMapsKey);

        if (maxResults) {
            params = params.set('limit', '' + maxResults);
        }

        return this.http.get<SearchFuzzyResponse>(`${this.azureFuzzyUrl}?${params.toString()}`);
    }

    reverseSearch(coordinate: Coordinate): Observable<SearchAddressReverseResponse> {
        const params = new HttpParams()
            .set('api-version', '1.0')
            .set('subscription-key', environment.azureMapsKey)
            .set('query', `${coordinate.latitude},${coordinate.longitude}`);
        return this.http.get<SearchAddressReverseResponse>(`${this.addressReverseUrl}?${params.toString()}`);
    }
}
