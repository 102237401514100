<vwui-modal [visible]="true">
    <vwui-modal-header>
        Inloggegevens verlopen
    </vwui-modal-header>
    <vwui-modal-body>
        Uw inloggegevens zijn verlopen of er is toegang nodig tot een externe service.
    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modalRef.close()">Annuleren</vwui-button>
        <vwui-button class="is-secondary" (click)="doLogin()">Opniew inloggen</vwui-button>
    </vwui-modal-footer>
</vwui-modal>
