import {Component, Inject, Input, OnInit} from '@angular/core';
import {AppStoreItem} from '../../models/app-store';
import {VwuiModalConfig, VwuiModalRef, VwuiModalService} from '@recognizebv/vwui-angular';
import {Project} from '../../models/project';
import {AppStoreItemService} from '../../services/app-store-item.service';
import {BehaviorSubject, combineLatest, lastValueFrom} from 'rxjs';
import {map, mapTo, switchMap, take} from 'rxjs/operators';
import {Paginator} from '../../utils/paginator';
import {AppStoreActivateModalComponent} from '../app-store-activate-modal/app-store-activate-modal.component';
import {Application, ProjectAppStoreItem} from '../../models/application';
import {ApplicationTrimbleModalComponent} from '../application-trimble-modal/application-trimble-modal.component';
import {DisclaimerComponent} from '../disclaimer/disclaimer.component';
import {ApplicationNavModalComponent} from '../application-nav-modal/application-nav-modal.component';
import {ToastrService} from 'ngx-toastr';
import {Role} from '../../models/role';
import {AuthorizationService} from '../../services/authorization.service';
import {NavisionAdministrationService} from '../../services/navision-administration.service';
import {ProjectService} from '../../services/project.service';
import {ApplicationTeamsModalComponent} from '../application-teams-modal/application-teams-modal.component';
import {ProjectAppStoreItemService} from '../../services/project-app-store-item.service';
import {ApplicationBouwpasModalComponent} from '../application-bouwpas-modal/application-bouwpas-modal.component';
import {ApplicationPrmModalComponent} from '../application-prm-modal/application-prm-modal.component';
import {ApplicationProjectSightModalComponent} from '../application-project-sight-modal/application-project-sight-modal.component';
import {ProjectSightDisclaimerComponent} from '../project-sight-disclaimer/project-sight-disclaimer.component';
import {NavisionAdministrationResponse} from '../../models/navision-administration-response';

@Component({
    selector: 'app-project-available-apps-modal',
    templateUrl: './project-available-apps-modal.component.html'
})
export class ProjectAvailableAppsModalComponent implements OnInit {
    @Input() readonly = false;
    project: Project;

    userHasNavisionAdministrations = false;
    refresh$ = new BehaviorSubject<void>(null);
    paginator$ = combineLatest([
        this.refresh$,
    ]).pipe(
        switchMap(() => {
            const paginator = new Paginator(page => {
                return this.appStoreItemService.searchAppStoreItems('findAllAvailable', {
                    projectId: this.project.id.toString(),
                    page: page.toString()
                }, [
                    {field: 'type', direction: 'ASC'},
                    {field: 'secondaryType', direction: 'DESC'}
                ]);
            });

            return paginator.content$.pipe(
                mapTo(paginator)
            );
        })
    );

    createTrimbleApplicationAllowed$ = this.authorizationService.roles$.pipe(
        map(roles => roles.includes(Role.trimbleManager) || roles.includes(Role.bedrijfsAdmin))
    );

    activateTrimbleExtensionAllowed$ = this.authorizationService.roles$.pipe(
        map(roles => roles.includes(Role.trimbleManager) || roles.includes(Role.bedrijfsAdmin))
    );

    constructor(
        @Inject('AppStoreItemService') private appStoreItemService: AppStoreItemService,
        @Inject('AuthorizationService') private authorizationService: AuthorizationService,
        @Inject('ProjectService') private projectService: ProjectService,
        @Inject('ProjectAppStoreItemService') private projectAppStoreItemService: ProjectAppStoreItemService,
        @Inject('NavisionAdministrationService') private navisionAdministrationService: NavisionAdministrationService,
        private modalService: VwuiModalService,
        public modalRef: VwuiModalRef,
        private toast: ToastrService,
        modalParams: VwuiModalConfig<{ project: Project }>
    ) {
        this.project = modalParams.data.project;
    }

    ngOnInit() {
        this.checkUserHasNavisionAdministrations();
    }

    async openAppStoreDetailModal(app: AppStoreItem | ProjectAppStoreItem) {
        app = app as AppStoreItem;
        if (app.type === 'Secondary') {
            await this.openSecondaryModal(app);
        } else if (app.type === 'Primary' && app.internalTitle === 'trimble connect') {
            await this.openTrimbleModal(app);
        } else if (app.type === 'Primary' && app.internalTitle === 'navision') {
            await this.openNavisionModal(app);
        } else if (app.type === 'Primary' && app.internalTitle === 'teams') {
            await this.openTeamsModal(app);
        } else if (app.type === 'Primary' && app.internalTitle === 'bouwpas') {
            await this.openBouwpasModal(app)
        } else if (app.type === 'Primary' && app.internalTitle === 'PRM') {
            await this.openPrmModal(app)
        } else if (app.type === 'Primary' && app.internalTitle === 'ProjectSight') {
            await this.openProjectSightModal(app)
        } else {
            console.error(`Unsupported Primary app "${app.internalTitle}"`, app)
        }
    }

    private async openTrimbleModal(app: AppStoreItem) {
        if (!await lastValueFrom<boolean>(this.createTrimbleApplicationAllowed$)) {
            this.toast.error('U hebt onvoldoende rechten om een Trimble Connect applicatie aan te maken.');
            return;
        }

        try {
            const modal = this.modalService.open(ApplicationTrimbleModalComponent, {
                closeOnBackdropClick: false,
                modalClass: 'modal-lg',
                data: {
                    ...await this.activeModalData(app)
                }
            });

            modal.afterClosed.pipe(
                take(1)
            ).subscribe( (result: boolean) => {
                if (result) {
                    this.modalService.open(DisclaimerComponent, {
                        closeOnBackdropClick: false,
                    });
                    this.modalRef.close();
                }
            });
        } catch (err) {
            console.error('Opening trimble modal failed', err);
            this.toast.error('Onverwachte fout.');
        }
    }

    private async openNavisionModal(app: AppStoreItem) {
        if (!this.userHasNavisionAdministrations) {
            this.toast.error('Er zijn geen Navision-administraties gevonden waar u toegang tot hebt.');
            return;
        }

        const modal = this.modalService.open(ApplicationNavModalComponent, {
            closeOnBackdropClick: false,
            modalClass: 'modal-lg',
            data: {
                ...await this.activeModalData(app)
            }
        });
        modal.afterClosed.pipe(
            take(1)
        ).subscribe((appActivated: boolean) => {
            if (appActivated) {
                this.modalRef.close();
            }
        });
    }

    async openTeamsModal(app: AppStoreItem) {
        const modal = this.modalService.open(ApplicationTeamsModalComponent, {
            modalClass: 'modal-lg',
            closeOnBackdropClick: false,
            data: {
                ...await this.activeModalData(app)
            }
        });

        modal.afterClosed.pipe(
            take(1)
        ).subscribe((appActivated: boolean) => {
            if (appActivated) {
                this.modalRef.close();
            }
        });
    }

    async openBouwpasModal(app: AppStoreItem) {
        let canCreateBouwpas = true;

        const roles = await lastValueFrom<Role[]>(this.authorizationService.roles$.pipe(take(1)));
        if (!roles.includes(Role.bouwpas)) {
            this.toast.error('Je hebt niet voldoende rechten om een Bouwpas applicatie aan te maken');
            canCreateBouwpas = false;
        }

        if (this.project.startDate == null || this.project.endDate == null) {
            this.toast.error('Start en Einddatum moeten ingevuld zijn om Bouwpas aan te maken.');
            canCreateBouwpas = false;
        }
        const navisionAppExists = await this.checkApplicationExistsOfType('nav');
        if (!navisionAppExists) {
            this.toast.error('Er moet minimaal 1 Navision applicatie aanwezig zijn in het project om een Bouwpas aan te maken.');
            canCreateBouwpas = false;
        }

        if (!canCreateBouwpas) {
            return;
        }

        const modal = this.modalService.open(ApplicationBouwpasModalComponent, {
            modalClass: 'modal-lg',
            closeOnBackdropClick: false,
            data: {
                ...await this.activeModalData(app)
            }
        });

        modal.afterClosed.pipe(
            take(1)
        ).subscribe(async (appActivated: boolean) => {
            if (appActivated) {
                this.modalRef.close();
            }
        });
    }

    async openPrmModal(app: AppStoreItem) {
        const roles = await lastValueFrom<Role[]>(this.authorizationService.roles$.pipe(take(1)));

        if (!roles.includes(Role.prm)) {
            this.toast.error('Je hebt niet voldoende rechten om een Project Risico Monitor applicatie aan te maken');
            return;
        }

        const modal = this.modalService.open(ApplicationPrmModalComponent, {
            modalClass: 'modal-lg',
            closeOnBackdropClick: false,
            data: {
                ...await this.activeModalData(app)
            }
        });

        modal.afterClosed.pipe(
            take(1)
        ).subscribe(async (appActivated: boolean) => {
            if (appActivated) {
                this.modalRef.close();
            }
        });
    }

    async openProjectSightModal(app: AppStoreItem) {
        const modal = this.modalService.open(ApplicationProjectSightModalComponent, {
            modalClass: 'modal-lg',
            closeOnBackdropClick: false,
            data: {
                ...await this.activeModalData(app)
            }
        });

        modal.afterClosed.pipe(
            take(1)
        ).subscribe(async (appActivated: boolean) => {
            if (appActivated) {
                this.modalService.open(ProjectSightDisclaimerComponent, {
                    closeOnBackdropClick: false,
                });
                this.modalRef.close();
            }
        });
    }

    async openSecondaryModal(app: AppStoreItem) {
        if (app.secondaryType && !await lastValueFrom<boolean>(this.activateTrimbleExtensionAllowed$)) {
            this.toast.error('U hebt onvoldoende rechten om een Trimble extensie te activeren.')
            return;
        }

        const modal = this.modalService.open(AppStoreActivateModalComponent, {
            modalClass: 'modal-lg',
            closeOnBackdropClick: true,
            data: {
                ...await this.activeModalData(app)
            }
        });
        modal.afterClosed.pipe(
            take(1)
        ).subscribe((appActivated: boolean) => {
            if (appActivated) {
                this.modalRef.close();
            }
        });
    }

    private async checkApplicationExistsOfType(type: Application['type']) {
        return (await lastValueFrom<ProjectAppStoreItem[]>(this.projectAppStoreItemService
            .getCollectionRelation(this.project._links.projectAppStoreItems)))
            .some(item => item.status === 'COMPLETED' && item.type === type);
    }

    private async checkUserHasNavisionAdministrations() {
        const response = await lastValueFrom<NavisionAdministrationResponse>(this.navisionAdministrationService.list(0, 1));

        this.userHasNavisionAdministrations = response.administrations.length > 0;
    }

    private async activeModalData(app: AppStoreItem) {
        const dependencies = await lastValueFrom<AppStoreItem[]>(this.appStoreItemService.getCollectionRelation(app._links.dependencies, 'app-store-items'))

        return {
            app,
            dependencies,
            project: this.project
        }
    }
}
