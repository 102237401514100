import {BehaviorSubject, Observable} from 'rxjs';
import {concatMap, map, retry, scan, shareReplay, takeWhile} from 'rxjs/operators';
import {PageResponse} from '../models/page-response';

export class InfiniteScrollPaginator<T> {
    private loadMoreSubject = new BehaviorSubject<null>(null);

    hasMore = true;
    content$: Observable<T[]> = this.loadMoreSubject.pipe(
        scan<null, number>((previousPage) => {
            // Calculate page number by sum of previous plus current pagenumber
            // If previousPage is -1 then start with page 0
            return previousPage === -1 ? 0 : previousPage + 1;
        }, -1),
        concatMap<number, Observable<PageResponse<T>>>((page) => this.loadFunction(page)),
        retry(3), // Retry retrieving at most 3 times
        takeWhile<PageResponse<T>>(pageResponse => {
            // Take data while last page hasn't been reached yet
            return this.hasMore = pageResponse.page.number === 0 || pageResponse.page.number < pageResponse.page.totalPages;
        }),
        // Only return items, not the other paginated properties
        map<PageResponse<T>, T[]>((it) => it.content),
        scan<T[], T[]>((acc, items) => [...acc, ...items], []),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    constructor(private loadFunction: (page: number) => Observable<PageResponse<T>>) {
    }

    loadMore(): void {
        this.loadMoreSubject.next(null);
    }
}
