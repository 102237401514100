import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {NavisionAdministrationResponse} from '../../models/navision-administration-response';
import {NavisionAdministrationService} from '../navision-administration.service';

export const mockResponse: NavisionAdministrationResponse = {
    administrations: [
        {
            administration: '0136 Kondor Wessels Oost BV'
        },
        {
            administration: '1143 VW BVGO ICT'
        },
        {
            administration: '2043 DigiBase B.V.'
        },
        {
            administration: '2126 PUUR Exloo B.V.'
        }
    ]
};

@Injectable()
export class NavisionAdministrationServiceMock implements NavisionAdministrationService {
    list(skip: number, take: number): Observable<NavisionAdministrationResponse> {
        return of(mockResponse);
    }

    hasAdministrations(): Observable<boolean> {
        return of(true);
    }
}
