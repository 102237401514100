import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {NavisionAdministrationResponse} from '../models/navision-administration-response';
import {catchError} from 'rxjs/operators';

export interface NavisionAdministrationService {
    list(skip: number, take: number): Observable<NavisionAdministrationResponse>;
    hasAdministrations(): Observable<boolean>;
}

@Injectable()
export class NavisionAdministrationServiceImpl implements NavisionAdministrationService {
    constructor(protected http: HttpClient) {
    }

    list(skip: number, take: number) {
        const params = new HttpParams()
            .set('skip', '' + skip)
            .set('take', '' + take);

        return this.http.get<NavisionAdministrationResponse>('/api/v1/navision-administrations', { params });
    }

    hasAdministrations() {
        return this.http.get<boolean>('/api/v1/navision-administrations/has-administrations').pipe(
            catchError(() => of(false))
        );
    }
}
