import {Component, Inject, OnInit} from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {FormControl, FormGroup, UntypedFormGroup, Validators} from '@angular/forms';
import {getAppStoreStatusList} from '../../utils/app-store-status-list';
import {AppStoreItemService} from '../../services/app-store-item.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, combineLatest, lastValueFrom, Observable} from 'rxjs';
import {AppStoreItem} from '../../models/app-store';
import {shareReplay, switchMap, take} from 'rxjs/operators';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {ConfirmModalComponent} from '../../components/confirm-modal/confirm-modal.component';
import {ToastrService} from 'ngx-toastr';
import {UrlMatcher} from '../../utils/regex-validators';
import {IconService} from '../../services/icon.service';
import {Icon} from '../../models/icon';
import {RestLink} from '../../models/rest/rest-link';

@Component({
    selector: 'app-app-store-detail',
    templateUrl: './app-store-detail.component.html'
})
export class AppStoreDetailComponent implements OnInit {
    ckEditor = ClassicEditor;
    ckConfig = {
        toolbar: ['bold', 'italic', 'link', 'undo', 'redo']
    };

    readonly appStatusList = getAppStoreStatusList();

    form: UntypedFormGroup;
    submitPending = false;

    refresh$ = new BehaviorSubject<void>(null);
    appStoreItem$: Observable<AppStoreItem> = combineLatest([
        this.route.params,
        this.refresh$
    ]).pipe(
        switchMap(([params]) => this.appStoreItemService.get(+params.id)),
        shareReplay(1)
    );

    dependencies$ = this.appStoreItemService.getAvailableDependencies();

    constructor(
        @Inject('AppStoreItemService') private appStoreItemService: AppStoreItemService,
        @Inject('IconService') private iconService: IconService,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: VwuiModalService,
        private toastr: ToastrService,
    ) {}

    ngOnInit(): void {
        this.appStoreItem$.pipe(take(1)).subscribe(item => {
            this.createFormGroup(item);
        });
    }

    openDeleteConfirmModal(app: AppStoreItem) {
        const modal = this.modalService.open(ConfirmModalComponent, {
            data: {
                title: `App ${app.title} verwijderen`,
                description: 'Weet je zeker om deze app te verwijderen?',
                confirmButton: 'Verwijderen'
            }
        });

        modal.afterClosed.subscribe(it => it && this.deleteApp(app));
    }

    async save(app: AppStoreItem) {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            this.toastr.error('Niet alle verplichte velden zijn (correct) ingevuld.');
            throw new Error('Couldn\'t save form, it\'s invalid');
        } else if (this.submitPending) {
            throw new Error('Couldn\'t save form, it\'s pending a submission');
        }

        try {
            this.submitPending = true;
            const {dependencies, icon, ...appStoreItemData} = this.form.value;

            await lastValueFrom<AppStoreItem>(this.appStoreItemService.patch({
                ...app,
                ...appStoreItemData
            }));

            await lastValueFrom<RestLink<AppStoreItem>>(this.appStoreItemService.replaceRelation(app._links.dependencies, ...dependencies));
            if (icon) {
                await lastValueFrom<RestLink<Icon>>(this.appStoreItemService.replaceRelation(app._links.icon, icon));
            } else {
                await lastValueFrom<void>(this.appStoreItemService.deleteRelation(app._links.icon));
            }

            this.toastr.success('Opgeslagen');
        } catch (e) {
            console.error('Save AppStoreItem error', e);
            this.showErrorToast('Opslaan mislukt');
        } finally {
            this.submitPending = false;
            this.refresh$.next();
        }
    }

    private async createFormGroup(app: AppStoreItem) {
        const dependencies = await lastValueFrom<AppStoreItem[]>(this.appStoreItemService.getCollectionRelation(app._links.dependencies, 'app-store-items'));
        const icon = app._links.icon ? await lastValueFrom<Icon>(this.iconService.getSingleRelation(app._links.icon)) : undefined;
        this.form = new FormGroup({
            internalTitle: new FormControl(app.internalTitle),
            title: new FormControl(app.title, [Validators.required, Validators.maxLength(255)]),
            url: new FormControl(app.url, [Validators.required, Validators.pattern(UrlMatcher), Validators.maxLength(2048)]),
            status: new FormControl(app.status, [Validators.required]),
            secondaryType: new FormControl(app.secondaryType, app.type === 'Secondary' ? [Validators.required] : []),
            description: new FormControl(app.description || ''),
            dependencies: new FormControl(dependencies || []),
            activationType: new FormControl(app.activationType, [Validators.required]),
            icon: new FormControl(icon || null)
        });
    }

    private async deleteApp(appStoreItem: AppStoreItem) {
        try {
            await lastValueFrom<void>(this.appStoreItemService.delete(appStoreItem));

            this.toastr.success(`${appStoreItem.title} is verwijderd`);
            this.router.navigate(['app-store']);
        } catch (e) {
            console.error('deleteApp error', e);
            this.showErrorToast('Verwijderen mislukt');
        }
    }

    private showErrorToast(message: string) {
        this.toastr.error(message);
    }
}
