import {ItemSelectComponent} from './item-select.component';
import {Component, forwardRef, Inject, Input} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {Project} from '../../models/project';
import {ProjectAppStoreItemService} from '../../services/project-app-store-item.service';
import {NAVApplication} from '../../models/application';

@Component({
    selector: 'app-navision-application-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NavisionApplicationSelectComponent),
            multi: true
        }
    ]
})
export class NavisionApplicationSelectComponent extends ItemSelectComponent<NAVApplication, {}> {
    private readonly projectSubject = new BehaviorSubject<Project>(null);
    private readonly navApplications$ = this.projectSubject.pipe(
        switchMap(project => {
            if (!project) {
                return of([])
            }

            return this.projectAppStoreItemService.getCollectionRelation(project._links.projectAppStoreItems).pipe(
                map(apps => apps.filter(app => app.type === 'nav') as NAVApplication[])
            )
        }),
        shareReplay({bufferSize: 1, refCount: false})
    )

    constructor(
        @Inject('ProjectAppStoreItemService') private projectAppStoreItemService: ProjectAppStoreItemService
    ) {
        super();

        this.paginated = false;
        this.placeholder = 'Kies een van de gekoppelde navision administraties';
    }

    @Input() set project(value: Project) {
        if (this.projectSubject.value !== value) {
            this.writeValue(null);
        }

        this.projectSubject.next(value);
    }

    getFilter(): Observable<{}> {
        return of({});
    }

    getLabelText({navAdministration, applicationNumber}: NAVApplication): string {
        return `${navAdministration} - ${applicationNumber}`;
    }

    loadMoreItems(page: number, filter: { term: string }): Observable<NAVApplication[]> {
        return this.navApplications$.pipe(
            map(response => this.filterBySearchTerm(response, filter)
            )
        );
    }

    private filterBySearchTerm(items: NAVApplication[], filter: { term: string }) {
        return items.filter(it => {
            const term = filter.term?.toLowerCase() ?? '';
            return this.getLabelText(it).toLowerCase().includes(term);
        });
    }
}
