<div class="detail-modal-header">
    <vwui-modal-header class="detail-modal-header--light-close-btn">
        <div class="d-flex">
            <div class="item-card__icon">
                <div *ngIf="app.iconSas" class="app-store-icon">
                    <img [src]="app.iconSas">
                </div>
            </div>
            <div>
                <vwui-breadcrumb>
                    <span>Appstore</span>
                </vwui-breadcrumb>
                <vwui-breadcrumb>
                    <span>{{app.title}}</span>
                </vwui-breadcrumb>
                <h3>{{app.title}}</h3>
            </div>
        </div>
    </vwui-modal-header>
</div>

<vwui-modal-body>
    <vwui-layout type="split">
        <div slot="split-left">
            <h3>Omschrijving</h3>
            <div [innerHTML]="app.description"></div>
        </div>

        <div slot="split-right" [formGroup]="form" class="app-details-form">
            <h3 class="mb-2">Applicatie activeren</h3>
            <div class="mb-2">Vul onderstaande gegevens in en klik op ‘activeren’.</div>

                <vwui-btn-option-group name="existingApplication" formControlName="existingApplication">
                    <vwui-btn-option value="false">Nieuw</vwui-btn-option>
                    <vwui-btn-option value="true">Bestaand</vwui-btn-option>
                </vwui-btn-option-group>
                <br>
                <vwui-form-group label="Navision-administratie">
                    <app-navision-administration-select
                        formControlName="navAdministration"></app-navision-administration-select>
                </vwui-form-group>
                <vwui-form-group label="Projectnummer" inputId="applicationNumber">
                    <vwui-input inputId="applicationNumber" formControlName="applicationNumber"
                                placeholder="Nummer"></vwui-input>
                </vwui-form-group>

            <vwui-button id="activate" class="is-primary"
                         [disabled]="form.invalid || submitPending"
                         [loading]="submitPending"
                         (click)="submit()">
                Activeren
            </vwui-button>
        </div>
    </vwui-layout>
</vwui-modal-body>

<vwui-modal-footer>
    <vwui-button class="is-light" (click)="modalRef.close()">Venster sluiten</vwui-button>
</vwui-modal-footer>
