import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {MDMService} from '../mdm.service';

@Injectable()
export class MDMServiceMock implements MDMService {
    getOperationalProjectPhasesOptions() {
        return of([
            {
                "key": "24da7c77-6473-44ed-959e-8778d5f9f3ee",
                "value": "Initiatief/haalbaarheid",
            },
            {
                "key": "2c1d358d-c4be-4278-a16c-4d268805e9d6",
                "value": "Projectdefinitie",
            },
            {
                "key": "cd2d363b-f2c1-4e69-a382-207fc62c542f",
                "value": "Structuurontwerp",
            },
        ]);
    }

    getSustainabilityRatingsOptions() {
        return of([
            {key: 'None', value: 'Geen'},
            {key: 'Breeam', value: 'Breeam'},
            {key: 'NOM', value: 'NOM'},
            {key: 'EPV', value: 'EPV'},
            {key: 'EPC0', value: 'EPC=0,0'},
            {key: 'Energienotaloos', value: 'Energienotaloos'},
            {key: 'Well', value: 'Well'},
            {key: 'Gasloos', value: 'Gasloos'},
            {key: 'GPR', value: 'GPR'},
            {key: 'Other', value: 'Anders'},
        ]);
    }

    getSubUsageFunctionsOptions() {
        return of([
            {key: 'ResidentialAppartments', value: 'Appartementengebouw'},
            {key: 'ResidentialAsylumCenter', value: 'Asielzoekerscentrum'},
            {key: 'ResidentialMonastery', value: 'Klooster'},
            {key: 'ResidentialRectory', value: 'Pastorie'},
            {key: 'ResidentialNursingHome', value: 'Verpleeghuis'},
            {key: 'ResidentialOrphanage', value: 'Weeshuis'},
            {key: 'ResidentialHouse', value: 'Woning'},
            {key: 'ResidentialResidentialCareComplex', value: 'Woonzorgcomplex'},
            {key: 'MeetingStockExchange', value: 'Beursgebouw'},
            {key: 'MeetingCasino', value: 'Casino'},
            {key: 'MeetingConcertHall', value: 'Concertzaal'},
            {key: 'MeetingConventionCenter', value: 'Congrescentrum'},
            {key: 'MeetingCrematorium', value: 'Crematorium'},
            {key: 'MeetingDisco', value: 'Discotheek'},
            {key: 'MeetingEventHall', value: 'Evenementenhal'},
            {key: 'MeetingExhibitionSpace', value: 'Expositieruimte'},
            {key: 'MeetingGuildHouse', value: 'Gildehuis'},
            {key: 'MeetingCateringArea', value: 'Horecaruimte'},
            {key: 'MeetingDaycare', value: 'Kinderopvang'},
            {key: 'MeetingChurchBuilding', value: 'Kerkgebouw'},
            {key: 'MeetingMilitaryBarracks', value: 'Militaire kazerne'},
            {key: 'MeetingMultiFunctionAccommodation', value: 'Multi funct. Accommodatie'},
            {key: 'MeetingMuseum', value: 'Museum'},
            {key: 'MeetingParliamentBuilding', value: 'Parlementsgebouw'},
            {key: 'MeetingStadium', value: 'Stadion'},
            {key: 'MeetingTheater', value: 'Theater'},
            {key: 'MeetingAirportTerminal', value: 'Vliegveldterminal'},
            {key: 'PrisonPrison', value: 'Gevangenis'},
            {key: 'HealthPsychiatricHospital', value: 'Psychiatrisch ziekenhuis'},
            {key: 'HealthRehabilitationCenter', value: 'Revalidatiecentrum'},
            {key: 'HealthHospital', value: 'Ziekenhuis'},
            {key: 'IndustrialFarm', value: 'Boerderij'},
            {key: 'IndustrialDistributionCenter', value: 'Distributiecentrum'},
            {key: 'IndustrialPowerPlant', value: 'Energiecentrale'},
            {key: 'IndustrialFactory', value: 'Fabriek'},
            {key: 'IndustrialIndustrialHeritage', value: 'Industrieel erfgoed'},
            {key: 'IndustrialLaboratory', value: 'Laboratorium'},
            {key: 'IndustrialMill', value: 'Molen'},
            {key: 'IndustrialProductionPlatform', value: 'Productieplatform'},
            {key: 'OfficeCityHall', value: 'Gemeentehuis'},
            {key: 'OfficeCourthouse', value: 'Gerechtsgebouw'},
            {key: 'OfficeOfficeBuilding', value: 'Kantoorgebouw'},
            {key: 'OfficePostOffice', value: 'Postkantoor'},
            {key: 'OfficeProvincialHouse', value: 'Provinciehuis'},
            {key: 'LodgingCamping', value: 'Camping'},
            {key: 'LodgingHotel', value: 'Hotel'},
            {key: 'LodgingDaycare', value: 'Kinderdagverblijf'},
            {key: 'SportsSportsAccommodation', value: 'Sportaccomodatie'},
            {key: 'ShopStore', value: 'Winkel'},
            {key: 'ShopMall', value: 'Winkelcentrum'},
            {key: 'OtherThemePark', value: 'Attractiepark'},
            {key: 'OtherLibrary', value: 'Bibliotheek'},
            {key: 'OtherFireStation', value: 'Brandweerkazerne'},
            {key: 'OtherZoo', value: 'Dierentuin'},
            {key: 'OtherPublicParkingGarage', value: 'Parkeergarage'},
            {key: 'OtherCastle', value: 'Kasteel'},
            {key: 'OtherPalace', value: 'Paleis'},
            {key: 'OtherPrivateParkingGarage', value: 'Stallingsgarage'},
            {key: 'OtherStation', value: 'Station'},
            {key: 'OtherTransformationHouse', value: 'Transformatiehuis'},
            {key: 'OtherFortification', value: 'Vestingwerk'},
            {key: 'NonBuildingBridge', value: 'Brug'},
            {key: 'NonBuildingArt', value: 'Kunst'},
            {key: 'NonBuildingFountain', value: 'Fontein'},
            {key: 'NonBuildingPort', value: 'Haven'},
            {key: 'NonBuildingDesign', value: 'Inrichting'},
            {key: 'NonBuildingWindFarm', value: 'Windturbinepark'},
        ])
    }

    getUsageFunctionsOptions() {
        return of([
            {key: 'Residential', value: 'Wonen'},
            {key: 'Meeting', value: 'Bijeenkomst'},
            {key: 'Prison', value: 'Cel'},
            {key: 'Health', value: 'Gezondheid'},
            {key: 'Industrial', value: 'Industrie'},
            {key: 'Office', value: 'Kantoor'},
            {key: 'Lodging', value: 'Logies'},
            {key: 'Education', value: 'Onderwijs'},
            {key: 'Sports', value: 'Sport'},
            {key: 'Shop', value: 'Winkel'},
            {key: 'Other', value: 'Overig'},
            {key: 'NonBuilding', value: 'Geen gebouw'},
        ]);
    }

    getVolkerWesselsConceptsOptions() {
        return of([
            {key: 'None', value: 'Geen'},
            {key: 'ClimateReady', value: 'Climate Ready'},
            {key: 'MorgenWonen', value: 'MorgenWonen'},
            {key: 'NieuwWonen', value: 'NieuwWonen'},
            {key: 'Plusrenoveren', value: 'Plusrenoveren'},
            {key: 'PluswonenAppartementen', value: 'Pluswonen Appartementen'},
            {key: 'PluswonenGrondgebonden', value: 'Pluswonen Grondgebonden'},
            {key: 'ZuiverWonen', value: 'ZuiverWonen'},
            {key: 'PuurWonen', value: 'Puur wonen'},
            {key: 'Other', value: 'Anders'},
        ]);
    }

    getWorkTypesOptions() {
        return of([
            {
                "key": "Transformation",
                "value": "Herbestemming / Transformatie",
            },
            {
                "key": "NewConstruction",
                "value": "Nieuwbouw",
            },
            {
                "key": "Maintenance",
                "value": "Onderhoud",
            },
            {
                "key": "Other",
                "value": "Overige / Diverse en aanverwanten",
            },
            {
                "key": "Renovation",
                "value": "Renovatie",
            },
            {
                "key": "Restoration",
                "value": "Restauratie",
            },
            {
                "key": "Demolition",
                "value": "Sloop",
            },
            {
                "key": "Expansion",
                "value": "Uitbreiding",
            },
        ]);
    }
}
