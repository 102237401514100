import {User} from '../../models/user';
import {Injectable} from '@angular/core';
import {AbstractRestServiceMock} from './abstract-rest.service.mock';
import {UserService} from '../user.service';
import {Observable, of} from 'rxjs';

export const mockUsers: User[] = [
    {
        email: 'j.hobert@recognize.nl',
        displayName: 'Julius de Heerser',
        companyName: 'Recognize BV',
        _links: {
            self: {href: '/api/v1/users/1'}
        }
    },
    {
        email: 's.dekker@recognize.nl',
        displayName: 'Stein Dakdekker',
        companyName: 'Recognize BV',
        _links: {
            self: {href: '/api/v1/users/2'}
        }
    }
];

@Injectable()
export class UserServiceMock extends AbstractRestServiceMock<User> implements UserService {
    constructor() {
        super(mockUsers);
    }

    findByEmailOrCreate(params: Omit<User, '_links'>): Observable<User> {
        return of(this.data[0]);
    }
}
