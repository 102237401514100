import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AppStoreItem} from '../../models/app-store';

@Component({
    selector: 'app-app-store-dependencies',
    templateUrl: './app-store-dependencies.component.html'
})
export class AppStoreDependenciesComponent implements OnChanges {
    @Input() dependencies: AppStoreItem[] = [];
    @Input() app: AppStoreItem | null;
    @Input() extraClass = '';

    multipleDependenciesTooltip = '';

    ngOnChanges(changes: SimpleChanges): void {
        this.multipleDependenciesTooltip = this.dependencies
            .map(dep => dep.title)
            .sort()
            .join(', ')
    }
}
