import {Inject, Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthorizationService} from '../services/authorization.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SuperAdminGuard implements CanActivateChild, CanActivate {
    constructor(
        @Inject('AuthorizationService') private authorizationService: AuthorizationService,
        private router: Router
    ) {}

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.authorizationService.isSuperAdmin$.pipe(
            map(it => it ? true : this.router.createUrlTree(['/projects']))
        );
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.authorizationService.isSuperAdmin$.pipe(
            map(it => it ? true : this.router.createUrlTree(['/projects']))
        );
    }
}
