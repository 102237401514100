import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {VwuiAngularModule} from '@recognizebv/vwui-angular';
import {ProjectListComponent} from './pages/project-list/project-list.component';
import {ProjectServiceImpl} from './services/project.service';
import {ProjectDetailComponent} from './pages/project-detail/project-detail.component';
import {ProjectDetailTabComponent} from './components/project-detail-tab/project-detail-tab.component';
import {ProjectLocationTabComponent} from './components/project-location-tab/project-location-tab.component';
import {ProjectApplicationsTabComponent} from './components/project-applications-tab/project-applications-tab.component';
import {AzureMapComponent} from './components/azure-map/azure-map.component';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {AzureMapsRestServiceImpl} from './services/azure-maps-rest.service';
import {environment} from '../environments/environment';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlatpickrModule} from 'angularx-flatpickr';
import {CreateProjectModalComponent} from './components/create-project-modal/create-project-modal.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {ClientServiceImpl} from './services/client.service';
import {ProjectServiceMock} from './services/mock/project.service.mock';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ImageCropModalComponent} from './components/image-crop-modal/image-crop-modal.component';
import {ClientServiceMock} from './services/mock/client.service.mock';
import {ImageUploadComponent} from './components/image-upload/image-upload.component';
import {ProjectAppStoreItemServiceImpl} from './services/project-app-store-item.service';
import {CommonModule, registerLocaleData} from '@angular/common';
import {ImageService, ImageServiceImpl} from './services/image.service';
import {BlobImageDirective} from './directives/blob-image.directive';
import {AzureMapsRestServiceMock} from './services/mock/azure-maps.service.mock';
import {UserServiceImpl} from './services/user.service';
import {UserServiceMock} from './services/mock/user.service.mock';
import {SearchHighlightPipe} from './pipes/search-highlight.pipe';
import {PaginationComponent} from './components/pagination/pagination.component';
import {TrimbleUserServiceImpl} from './services/trimble-user.service';
import {TrimbleUserSelectComponent} from './components/item-select/trimble-user-select.component';
import {UserEmailSelectComponent} from './components/item-select/user-email-select.component';
import {ApplicationTrimbleModalComponent} from './components/application-trimble-modal/application-trimble-modal.component';
import {UserSelectComponent} from './components/item-select/user-select.component';
import {TrimbleUserServiceMock} from './services/mock/trimble-user.service.mock';
import {MsalModule, MsalRedirectComponent} from '@azure/msal-angular';
import {ProjectMemberServiceImpl} from './services/project-member.service';
import {AddProjectMemberModalComponent} from './components/add-project-member-modal/add-project-member-modal.component';
import {GraphUserSelectComponent} from './components/item-select/graph-user-select.component';
import {GraphServiceImpl} from './services/graph.service';
import {AuthenticationRequiredModalComponent} from './components/authentication-required-modal/authentication-required-modal.component';
import {MsalComponent} from './components/msal/msal.component';
import {UnauthorizedModalComponent} from './components/unauthorized-modal/unauthorized-modal.component';
import {DateInterceptor} from './interceptor/date-interceptor.service';
import {UnauthorizedComponent} from './pages/unauthorized/unauthorized.component';
import {GraphServiceMock} from './services/mock/graph.service.mock';
import {MsalAuthWrapperServiceImpl} from './services/msal-auth-wrapper.service';
import {MsalAuthWrapperServiceMock} from './services/mock/msal-auth-wrapper.service.mock';
import {ProjectMemberServiceMock} from './services/mock/project-member.service.mock';
import {ApplicationNavModalComponent} from './components/application-nav-modal/application-nav-modal.component';
import {ShowWhenCanUpdateProjectDirective} from './directives/show-when-can-update-project.directive';
import {ShowWhenCanCreateProjectsDirective} from './directives/show-when-can-create-projects.directive';
import {ReadonlyIfCannotManageProjectDirective} from './directives/readonly-if-cannot-manage-project.directive';
import {AuthorizationGuardService} from './services/authorization-guard.service';
import {AuthorizationService, AuthorizationServiceImpl} from './services/authorization.service';
import {AuthorizationServiceMock} from './services/mock/authorization.service.mock';
import {NavisionAdministrationServiceImpl} from './services/navision-administration.service';
import {NavisionAdministrationServiceMock} from './services/mock/navision-administration.service.mock';
import {NavisionAdministrationSelectComponent} from './components/item-select/navision-administration-select.component';
import {DisclaimerComponent} from './components/disclaimer/disclaimer.component';
import {HasRolesDirective} from './directives/has-roles.directive';
import {AppStoreListComponent} from './pages/app-store-list/app-store-list.component';
import {AppStoreModalComponent} from './components/app-store-modal/app-store-modal.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {AppStoreDetailComponent} from './pages/app-store-detail/app-store-detail.component';
import {AppStoreCardComponent} from './components/app-store-card/app-store-card.component';
import {RecentProjectsListComponent} from './components/recent-projects-list/recent-projects-list.component';
import {AppStoreItemServiceImpl} from './services/app-store-item.service';
import {AppStoreItemServiceMock} from './services/mock/app-store-item.service.mock';
import {ConfirmModalComponent} from './components/confirm-modal/confirm-modal.component';
import {AppStoreStatusLabelComponent} from './components/app-store-status-label/app-store-status-label.component';
import {TemplateServiceImpl} from './services/template.service';
import {TemplateServiceMock} from './services/mock/template.service.mock';
import {TemplateSelectComponent} from './components/item-select/template-select.component';
import {ShowIfSuperAdminDirective} from './directives/show-if-super-admin.directive';
import {MigrationCompanyNamesComponent} from './pages/migration-company-names/migration-company-names.component';
import {ProjectFeaturesTabComponent} from './components/project-features-tab/project-features-tab.component';
import {ProjectUserPreferencesServiceImpl} from './services/project-user-preferences.service';
import {ProjectUserPreferencesServiceMock} from './services/mock/project-user-preferences.service.mock';
import {ProjectLocationFormGroupComponent} from './components/project-location-form-group/project-location-form-group.component';
import {ProjectSelectComponent} from './components/item-select/project-select.component';
import {TrimbleConnectApplicationService} from './services/trimble-connect-application.service';
import {ProjectAppStoreCardComponent} from './components/project-app-store-card/project-app-store-card.component';
import {ProjectAppStoreDetailModalComponent} from './components/project-app-store-detail-modal/project-app-store-detail-modal.component';
import {ProjectAvailableAppsModalComponent} from './components/project-available-apps-modal/project-available-apps-modal.component';
import {AppStoreActivateModalComponent} from './components/app-store-activate-modal/app-store-activate-modal.component';
import {ApplicationTeamsModalComponent} from './components/application-teams-modal/application-teams-modal.component';
import {AppStoreItemCardComponent} from './components/app-store-item-card/app-store-item-card.component';
import {ErrorHandlerService} from './services/error-handler.service';
import {ProjectNavisionAdministrationSelectComponent} from './components/item-select/project-navision-administration-select.component';
import localeNL from '@angular/common/locales/nl';
import {NavisionApplicationService, NavisionApplicationServiceImpl} from './services/navision-application.service';
import {NavisionApplicationServiceMock} from './services/mock/navision-application.service.mock';
import {ProjectLabelsComponent} from './pages/project-labels/project-labels.component';
import {ProjectLabelServiceImpl} from './services/project-label.service';
import {ProjectLabelServiceMock} from './services/mock/project-label.service.mock';
import {ProjectLabelModalComponent} from './components/project-label-modal/project-label-modal.component';
import {ApplicationBouwpasModalComponent} from './components/application-bouwpas-modal/application-bouwpas-modal.component';
import {InteractionType, LogLevel, PublicClientApplication} from '@azure/msal-browser';
import {MsalIdTokenInterceptor} from './interceptor/msal-id-token.interceptor';
import {BouwpasServiceImpl} from './services/bouwpas.service';
import {BouwpasServiceMock} from './services/mock/bouwpas.service.mock';
import {BouwpasContractorSelectComponent} from './components/item-select/bouwpas-contractor-select.component';
import {BouwpasTemplateSelectComponent} from './components/item-select/bouwpas-template-select.component';
import {NavisionApplicationSelectComponent} from './components/item-select/navision-application-select.component';
import {ActivationTypeSelectComponent} from './components/item-select/activation-type-select.component';
import {AppStoreItemIconUploadComponent} from './components/app-store-item-icon-upload/app-store-item-icon-upload.component';
import {IconServiceImpl} from './services/icon.service';
import {ProjectAppStoreItemMock} from './services/mock/project-app-store-item.service.mock';
import {CompanyServiceImpl} from './services/company.service';
import {CompanyServiceMock} from './services/mock/company.service.mock';
import {CompanySelectComponent} from './components/item-select/company-select.component';
import {DisabledWhenAppStoreItemUsedDirective} from './directives/disabled-when-app-store-item-used.directive';
import {AppStoreDependenciesComponent} from './components/app-store-dependencies/app-store-dependencies.component';
import {FadeDirective} from './directives/fade.directive';
import {ApplicationPrmModalComponent} from './components/application-prm-modal/application-prm-modal.component';
import {ProjectListFilterComponent} from './components/project-list-filter/project-list-filter.component';
import {SearchFilterGroupSimpleComponent} from './components/search-filter-group-simple/search-filter-group-simple.component';
import {NgxEnvModule} from '@ngx-env/core';
import {NotificationServiceImpl} from './services/notification.service';
import {NotificationServiceMock} from './services/mock/notification.service.mock';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {
    ApplicationProjectSightModalComponent
} from './components/application-project-sight-modal/application-project-sight-modal.component';
import {ProjectSightPortfolioSelectComponent} from './components/item-select/project-sight-portfolio-select.component';
import {ProjectSightServiceImpl} from './services/project-sight.service';
import {ProjectSightServiceMock} from './services/mock/project-sight.service.mock';
import {ProjectAppStoreItemSelectComponent} from './components/item-select/project-app-store-item-select.component';
import {ProjectSightDisclaimerComponent} from './components/project-sight-disclaimer/project-sight-disclaimer.component';
import {ProjectAppStoreItemTypePipe} from './pipes/project-app-store-type.pipe';
import {AppStoreItemTypePipe} from './pipes/app-store-item-type.pipe';
import {ProjectTypePipe} from './pipes/project-type.pipe';
import {SortingTableHeaderComponent} from './components/sorting-table-header/sorting-table-header.component';
import {ProjectHistoryTabComponent} from './components/project-history-tab/project-history-tab.component';
import {ShowIfBedrijfsAdminDirective} from './directives/show-if-bedrijfs-admin.directive';
import { OpenTableauTabComponent } from './components/external-redirect/open-tableau-tab.component';
import {MDMServiceImpl} from './services/mdm.service';
import {MDMServiceMock} from './services/mock/mdm.service.mock';

registerLocaleData(localeNL, 'nl');

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function service(provide: string, serviceName): any {
    return {
        useClass: serviceName,
        provide
    };
}

export function serviceWithMock(provide: string, serviceRef, mock): any {
    return {
        useClass: environment.useMock ? mock : serviceRef,
        provide
    };
}

@NgModule({
    declarations: [
        ActivationTypeSelectComponent,
        AddProjectMemberModalComponent,
        AppComponent,
        AppStoreActivateModalComponent,
        AppStoreCardComponent,
        AppStoreDependenciesComponent,
        AppStoreDetailComponent,
        AppStoreItemCardComponent,
        AppStoreItemIconUploadComponent,
        AppStoreListComponent,
        AppStoreModalComponent,
        AppStoreStatusLabelComponent,
        ApplicationBouwpasModalComponent,
        ApplicationNavModalComponent,
        ApplicationPrmModalComponent,
        ApplicationProjectSightModalComponent,
        ApplicationTeamsModalComponent,
        ApplicationTrimbleModalComponent,
        AuthenticationRequiredModalComponent,
        AzureMapComponent,
        BlobImageDirective,
        BouwpasContractorSelectComponent,
        BouwpasTemplateSelectComponent,
        CompanySelectComponent,
        ConfirmModalComponent,
        CreateProjectModalComponent,
        DisabledWhenAppStoreItemUsedDirective,
        DisclaimerComponent,
        FadeDirective,
        GraphUserSelectComponent,
        HasRolesDirective,
        ImageCropModalComponent,
        ImageUploadComponent,
        MigrationCompanyNamesComponent,
        MsalComponent,
        NavisionAdministrationSelectComponent,
        NavisionApplicationSelectComponent,
        PaginationComponent,
        ProjectAppStoreCardComponent,
        ProjectAppStoreDetailModalComponent,
        ProjectAppStoreItemSelectComponent,
        ProjectApplicationsTabComponent,
        ProjectAvailableAppsModalComponent,
        ProjectDetailComponent,
        ProjectDetailTabComponent,
        ProjectHistoryTabComponent,
        ProjectFeaturesTabComponent,
        ProjectLabelModalComponent,
        ProjectLabelsComponent,
        ProjectListComponent,
        ProjectListFilterComponent,
        ProjectLocationFormGroupComponent,
        ProjectLocationTabComponent,
        ProjectNavisionAdministrationSelectComponent,
        ProjectSelectComponent,
        ProjectSightDisclaimerComponent,
        ProjectSightPortfolioSelectComponent,
        ReadonlyIfCannotManageProjectDirective,
        RecentProjectsListComponent,
        SearchFilterGroupSimpleComponent,
        SearchHighlightPipe,
        ShowIfSuperAdminDirective,
        ShowIfBedrijfsAdminDirective,
        ShowWhenCanUpdateProjectDirective,
        ShowWhenCanCreateProjectsDirective,
        TemplateSelectComponent,
        TrimbleUserSelectComponent,
        UnauthorizedComponent,
        UnauthorizedModalComponent,
        UserEmailSelectComponent,
        UserSelectComponent,
        ProjectAppStoreItemTypePipe,
        AppStoreItemTypePipe,
        ProjectTypePipe,
        SortingTableHeaderComponent,
        OpenTableauTabComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ReactiveFormsModule,
        environment.useMock ? NoopAnimationsModule : BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: environment.useMock ? 1000 : 5000
        }),
        VwuiAngularModule.forRoot(),
        FormsModule,
        NgSelectModule,
        CKEditorModule,
        FlatpickrModule.forRoot({
            altInputClass: 'flatpickr-input',
            altFormat: 'j F Y',
        }),
        ImageCropperModule,
        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    authority: `https://login.microsoftonline.com/${environment.tenantId}`,
                    clientId: environment.authClientId,
                    redirectUri: environment.authRedirectUri,
                    navigateToLoginRequestUrl: false,
                },
                cache: {
                    storeAuthStateInCookie: isIE,
                    cacheLocation: 'localStorage',
                },
                system: {
                    loggerOptions: {
                        logLevel: LogLevel.Info,
                        loggerCallback(level: LogLevel, message: string, containsPii: boolean) {
                        }
                    }
                }
            }),
            {
                interactionType: InteractionType.Redirect,
                loginFailedRoute: '/unauthorized',
                authRequest: {
                    authority: `https://login.microsoftonline.com/${environment.tenantId}`,
                    scopes: ['openid']
                },
            },
            {
                interactionType: InteractionType.Redirect,
                protectedResourceMap: new Map([
                    ['/api/', ['openid']]
                ])
            }),
        NgxEnvModule,
        InfiniteScrollModule
    ],
    providers: [
        AuthorizationGuardService,
        service('TrimbleApplicationService', TrimbleConnectApplicationService),
        service('ImageService', ImageServiceImpl),
        service('IconService', IconServiceImpl),
        serviceWithMock('AppStoreItemService', AppStoreItemServiceImpl, AppStoreItemServiceMock),
        serviceWithMock('AuthorizationService', AuthorizationServiceImpl, AuthorizationServiceMock),
        serviceWithMock('AzureMapsRestService', AzureMapsRestServiceImpl, AzureMapsRestServiceMock),
        serviceWithMock('BouwpasService', BouwpasServiceImpl, BouwpasServiceMock),
        serviceWithMock('ClientService', ClientServiceImpl, ClientServiceMock),
        serviceWithMock('CompanyService', CompanyServiceImpl, CompanyServiceMock),
        serviceWithMock('GraphService', GraphServiceImpl, GraphServiceMock),
        serviceWithMock('MDMService', MDMServiceImpl, MDMServiceMock),
        serviceWithMock('MsalAuthWrapperService', MsalAuthWrapperServiceImpl, MsalAuthWrapperServiceMock),
        serviceWithMock('NavisionAdministrationService', NavisionAdministrationServiceImpl, NavisionAdministrationServiceMock),
        serviceWithMock('NavisionApplicationService', NavisionApplicationServiceImpl, NavisionApplicationServiceMock),
        serviceWithMock('NotificationService', NotificationServiceImpl, NotificationServiceMock),
        serviceWithMock('ProjectAppStoreItemService', ProjectAppStoreItemServiceImpl, ProjectAppStoreItemMock),
        serviceWithMock('ProjectLabelService', ProjectLabelServiceImpl, ProjectLabelServiceMock),
        serviceWithMock('ProjectMemberService', ProjectMemberServiceImpl, ProjectMemberServiceMock),
        serviceWithMock('ProjectService', ProjectServiceImpl, ProjectServiceMock),
        serviceWithMock('ProjectSightService', ProjectSightServiceImpl, ProjectSightServiceMock),
        serviceWithMock('ProjectUserPreferencesService', ProjectUserPreferencesServiceImpl, ProjectUserPreferencesServiceMock),
        serviceWithMock('TemplateService', TemplateServiceImpl, TemplateServiceMock),
        serviceWithMock('TrimbleUserService', TrimbleUserServiceImpl, TrimbleUserServiceMock),
        serviceWithMock('UserService', UserServiceImpl, UserServiceMock),
        BlobImageDirective,
        ...(environment.production ? [{
            provide: ErrorHandler,
            useClass: ErrorHandlerService
        }] : []),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalIdTokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DateInterceptor,
            multi: true
        },
        {
            provide: 'BASE_URL',
            useFactory() {
                const baseTag = document.getElementsByTagName('base');
                if (baseTag.length) {
                    return document.getElementsByTagName('base')[0].href;
                }

                const url = new URL(document.location.href);
                return `${url.protocol}://${url.host}`;
            }
        }
    ],
    exports: [
        TrimbleUserSelectComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
