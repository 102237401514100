import {Injectable} from '@angular/core';
import {NavisionApplicationService} from './navision-application.service';
import {Observable} from 'rxjs';
import {Notification} from '../models/notification';
import {HttpClient} from '@angular/common/http';

export interface NotificationService {
    list(): Observable<Notification[]>;
    acknowledge(id: number): Observable<void>;
}

@Injectable()
export class NotificationServiceImpl implements NotificationService {
    constructor(private http: HttpClient) {
    }

    list(): Observable<Notification[]> {
        return this.http.get<Notification[]>('/api/v1/notification');
    }

    acknowledge(id: number): Observable<void> {
        return this.http.post<void>(`/api/v1/notification/acknowledge/${id}`, []);
    }
}
