import {Component, Inject, OnDestroy} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Project} from '../../models/project';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {lastValueFrom, Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {ProjectAppStoreItemService} from '../../services/project-app-store-item.service';
import {AppStoreItem} from '../../models/app-store';
import {CreateTeamsApplication, ProjectAppStoreItem} from '../../models/application';
import {ProjectMemberService} from '../../services/project-member.service';
import {UserService} from '../../services/user.service';
import {AppStoreItemService} from '../../services/app-store-item.service';
import {User} from '../../models/user';

@Component({
    selector: 'app-application-teams-modal',
    templateUrl: './application-teams-modal.component.html'
})
export class ApplicationTeamsModalComponent implements OnDestroy {
    form = new UntypedFormGroup({
        owner: new UntypedFormControl(null),
        projectId: new UntypedFormControl(null),
        status: new UntypedFormControl(null),
        template: new UntypedFormControl(null, Validators.required),
        applicationNumber: new UntypedFormControl(''),
        existingApplication: new UntypedFormControl('false')
    })
    project: Project;
    subscriptions: Subscription[] = [];
    app: AppStoreItem;
    submitPending = false;

    availableTemplates = [
        'Standaard (leeg)',
        'BVGO Aannemers'
    ];

    constructor(
        @Inject('ProjectAppStoreItemService') private projectAppStoreItemService: ProjectAppStoreItemService,
        @Inject('ProjectMemberService') private projectMemberService: ProjectMemberService,
        @Inject('UserService') private userService: UserService,
        @Inject('AppStoreItemService') private appStoreItemService: AppStoreItemService,
        private toastr: ToastrService,
        public modalRef: VwuiModalRef,
        modalParams: VwuiModalConfig<{ project: Project, app: AppStoreItem }>
    ) {
        this.project = modalParams.data.project;
        this.app = modalParams.data.app;
        this.createForm();
    }

    private createForm() {
        this.subscriptions.push(
            this.form.get('existingApplication').valueChanges.subscribe( existingApplication => {
                // make the validation conditional based on if it is an existing application
                if (existingApplication === 'true') {
                    this.form.controls.template.removeValidators(Validators.required);
                    this.form.controls.applicationNumber.setValidators(Validators.required);
                } else {
                    this.form.controls.applicationNumber.removeValidators(Validators.required);
                    this.form.controls.template.setValidators(Validators.required);
                }

                this.form.controls.applicationNumber.updateValueAndValidity();
                this.form.controls.template.updateValueAndValidity();
            })
        )
        this.subscriptions.push(
            this.form.get('applicationNumber').valueChanges.subscribe( applicationNumber => {
                try {
                    const parsedUrl = new URL(applicationNumber);
                    const groupId = parsedUrl.searchParams.get('groupId');
                    if (groupId != null){
                        this.form.controls.applicationNumber.setValue(groupId);
                    }
                } catch (err) {
                    // Unable to parse URL, so leave the value as is
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
    }

    async saveTeams() {
        try {
            this.submitPending = true;

            const formValue = this.form.value;
            const companyName = formValue.owner.companyName || 'Onbekend';
            let teamsUrl;

            if (formValue.existingApplication === 'true') {
                teamsUrl = await lastValueFrom<string>(this.appStoreItemService.getTeamsUrl(formValue.applicationNumber));
                if (teamsUrl === null) {
                    this.toastr.error('Teams omgeving valt niet binnen BVGO');
                }
            }

            if (teamsUrl !== null) {
                const user = await lastValueFrom<User>(this.userService.findByEmailOrCreate({
                    email: formValue.owner.userPrincipalName,
                    displayName: formValue.owner.displayName || '',
                    companyName
                }));

                const teamsApplication: CreateTeamsApplication = {
                    status: (formValue.existingApplication === 'true') ? 'COMPLETED' : 'CREATED',
                    type: 'teams',
                    applicationNumber: (formValue.existingApplication === 'true') ? formValue.applicationNumber : this.project.code,
                    existingApplication: formValue.existingApplication === 'true',
                    owner: user._links.self.href,
                    project: this.project._links.self.href,
                    appStoreItem: this.app._links.self.href,
                    template: formValue.template
                };
                await lastValueFrom<ProjectAppStoreItem>(this.projectAppStoreItemService.create(teamsApplication));

                this.modalRef.close(true);
            }
        } catch (error) {
            let errorMessage = 'Opslaan mislukt.';
            if (error instanceof HttpErrorResponse) {
                if (error.status === 409) {
                    errorMessage = `Opslaan mislukt, dit type app is al geactiveerd voor dit project.`;
                } else if (error.status === 400) {
                    if (error.error.message) {
                        errorMessage = `Opslaan mislukt, ${error.error.message}`;
                    }
                } else if (error.status === 504) {
                    errorMessage = 'Opslaan mislukt, kon geen verbinding maken met de ESB.';
                }
            }

            console.error('Error occurred while saving application', error);
            this.toastr.error(errorMessage);
        } finally {
            this.submitPending = false;
        }
    }
}
