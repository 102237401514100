import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Project} from '../../models/project';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {lastValueFrom, Observable, Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {ProjectAppStoreItemService} from '../../services/project-app-store-item.service';
import {AppStoreItem} from '../../models/app-store';
import {CreateProjectSightApplication, ProjectAppStoreItem, ProjectAppStoreItemWithProject} from '../../models/application';
import {ProjectMemberService} from '../../services/project-member.service';
import {UserService} from '../../services/user.service';
import {ProjectSightPortfolio, ProjectSightUser} from '../../models/project-sight.portfolio';

@Component({
    selector: 'app-application-project-sight-modal',
    templateUrl: './application-project-sight-modal.component.html'
})
export class ApplicationProjectSightModalComponent implements OnInit, OnDestroy{

    form = new FormGroup({
        portfolio: new FormControl<ProjectSightPortfolio>(null, [Validators.required]),
        projectAdmin: new FormControl<ProjectSightUser>(null, [Validators.required]),
        trimbleConnectTemplate: new FormControl<string>(null, [Validators.required]),
        copyFromProject: new FormControl<ProjectAppStoreItemWithProject>(null),
        copyProjectSettings: new FormControl<boolean>(false),
        copyUsers: new FormControl<boolean>(false),
    })

    trimbleConnectApplications$: Observable<ProjectAppStoreItem[]>;
    project: Project;
    subscriptions: Subscription[] = [];
    app: AppStoreItem;
    submitPending = false;

    constructor(
        @Inject('ProjectAppStoreItemService') private projectAppStoreItemService: ProjectAppStoreItemService,
        @Inject('ProjectMemberService') private projectMemberService: ProjectMemberService,
        @Inject('UserService') private userService: UserService,
        private toastr: ToastrService,
        public modalRef: VwuiModalRef,
        modalParams: VwuiModalConfig<{ project: Project, app: AppStoreItem }>
    ) {
        if (!modalParams.data.project || !modalParams.data.app) {
            throw new Error('Missing project or app');
        }

        this.project = modalParams.data.project;
        this.app = modalParams.data.app;

        this.trimbleConnectApplications$ = this.projectAppStoreItemService.getCollectionRelation(this.project._links.projectAppStoreItems);

        this.toggleTemplateControls();
        this.form.controls.copyFromProject.valueChanges.subscribe(it => this.toggleTemplateControls());
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.form.controls.portfolio.valueChanges.subscribe(it => this.form.controls.projectAdmin.reset())
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
    }

    async save() {
        try {
            this.submitPending = true;

            const projectSightApplication: CreateProjectSightApplication = {
                type: 'projectSight',
                status: 'CREATED',
                applicationNumber: '',
                portfolioId: this.form.value.portfolio.id,
                projectAdmin: this.form.value.projectAdmin.id,
                trimbleConnectTemplate: this.form.value.trimbleConnectTemplate,
                copyFromProject: +this.form.value.copyFromProject?.applicationNumber,
                copyProjectSettings: this.form.value.copyProjectSettings,
                copyUsers: this.form.value.copyUsers,
                project: this.project._links.self.href,
                appStoreItem: this.app._links.self.href
            };
            await lastValueFrom<ProjectAppStoreItem>(this.projectAppStoreItemService.create(projectSightApplication));
            this.toastr.success("Opgeslagen");
            this.modalRef.close(true);
        } catch (error) {
            let errorMessage = 'Opslaan mislukt.';
            if (error instanceof HttpErrorResponse) {
                if (error.status === 400) {
                    if (error.error.message) {
                        errorMessage = `Opslaan mislukt, ${error.error.message}`;
                    }
                } else if (error.status === 504) {
                    errorMessage = 'Opslaan mislukt, kon geen verbinding maken met de ESB.';
                }
            }

            console.error('Error occurred while saving application', error);
            this.toastr.error(errorMessage);
        } finally {
            this.submitPending = false;
        }
    }

    private toggleTemplateControls() {
        if (this.form.controls.copyFromProject.value) {
            this.form.controls.copyUsers.enable();
            this.form.controls.copyProjectSettings.enable();
        } else {
            this.form.controls.copyUsers.disable();
            this.form.controls.copyProjectSettings.disable();
        }
    }
}
