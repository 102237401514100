import {Injectable} from '@angular/core';

declare global {
    interface Window {
        pendo: any;
    }
}

@Injectable({
    providedIn: 'root'
})
export class PendoService {
    private pendoReady = false;

    constructor() {
        window.pendo = window.pendo || null;
    }

    initialize(config: any) {
        if (!window.pendo) {
            console.error('Pendo is not available for this environment');

            return;
        }

        if (this.pendoReady) {
            return;
        }

        // Pendo script is loaded. Initialize pendo.io
        window.pendo.initialize({
            visitor: {
                ...config
            },
            events: {
                ready: () => this.onPendoIsReady()
            }
        });
    }

    private onPendoIsReady() {
        this.pendoReady = true;
    }
}
