export function cleanFilter(filter: {[key: string]: any}): {[key: string]: string[]} {
    const cleanedValue: { [key: string]: string[] } = {};

    Object.keys(filter).forEach(key => {
        if (filter[key] && filter[key].length > 0) {
            cleanedValue[key] = filter[key];
        }
    });

    return cleanedValue;
}
