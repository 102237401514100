import {Injectable} from '@angular/core';
import {AbstractRestService, AbstractRestServiceInterface, RestHttpParams, SortParams} from './abstract-rest.service';
import {AppStoreItem, AppStoreItemActivationType} from '../models/app-store';
import {HttpClient} from '@angular/common/http';
import {RestItem} from '../models/rest/rest-item';
import {Observable} from 'rxjs';
import {PageResponse} from '../models/page-response';
import {RestCollection} from '../models/rest/rest-collection';
import {map} from 'rxjs/operators';
import {RestUtils} from '../../../../app-launcher/src/app/utils/rest-utils';

export type AppActivationData = { navAppId?: number, projectId: number };

export interface AppStoreItemService extends AbstractRestServiceInterface<AppStoreItem> {
    searchAppStoreItems(searchFunction: string, params?: RestHttpParams, sort?: SortParams | SortParams[]): Observable<PageResponse<AppStoreItem>>;

    getTrimble(): Observable<AppStoreItem>;

    activateForProject(appStoreItem: AppStoreItem, data: AppActivationData): Observable<void>;

    getAvailableDependencies(): Observable<RestCollection<AppStoreItem>>;

    existsByInternalTitle(internalTitle: string): Observable<boolean>;

    countUsage(appStoreItemId: number): Observable<number>;

    listActivationTypes(): Observable<AppStoreItemActivationType[]>;

    getTeamsUrl(id: string): Observable<string | null>

}

@Injectable({
    providedIn: 'root'
})
export class AppStoreItemServiceImpl extends AbstractRestService<AppStoreItem> implements AppStoreItemService {

    constructor(
        protected httpClient: HttpClient
    ) {
        super(httpClient, 'app-store-items');
    }

    listActivationTypes(): Observable<AppStoreItemActivationType[]> {
        return this.httpClient.get<AppStoreItemActivationType[]>(`/api/v1/${this.resourcePath}/activation-types`);
    }

    findAll(params?: RestHttpParams, sort?: SortParams): Observable<PageResponse<AppStoreItem>> {
        return this.httpClient.get<RestCollection<AppStoreItem>>(`/api/v1/${this.resourcePath}/search/list`, {params}).pipe(map(it => ({
            content: it._embedded[this.resourceName],
            page: it.page
        })));
    }

    existsByInternalTitle(internalTitle: string): Observable<boolean> {
        return this.httpClient.get<boolean>(`/api/v1/${this.resourcePath}/search/existsByInternalTitle`, {params: {internalTitle}});
    }

    getAvailableDependencies(): Observable<RestCollection<AppStoreItem>> {
        return this.httpClient.get<RestCollection<AppStoreItem>>(`/api/v1/${this.resourcePath}/search/findAvailableDependencies`);
    }

    patch(restItem: Required<RestItem<AppStoreItem>> & Partial<AppStoreItem>): Observable<AppStoreItem> {
        // Workaround for the WAF
        const url = btoa(restItem.url);
        const description = btoa(restItem.description);

        restItem = {...restItem, url, description};

        return super.patch(restItem);
    }

    searchAppStoreItems(searchFunction: string, params: RestHttpParams, sort?: SortParams): Observable<PageResponse<AppStoreItem>> {
        const sortParams: SortParams[] = sort
            ? sort instanceof Array
                ? sort
                : [sort]
            : []
        return this.httpClient.get<RestCollection<AppStoreItem>>(`/api/v1/${this.resourcePath}/search/${searchFunction}`, {
            params: {
                ...params,
                ...(sortParams.length ? {sort: sortParams.map(it => it.field + ',' + it.direction)} : {}),
            }
        }).pipe(map(it => ({
            content: it._embedded[this.resourceName],
            page: it.page
        })));
    }

    getTrimble(): Observable<AppStoreItem> {
        return this.httpClient.get<AppStoreItem>(`/api/v1/${this.resourcePath}/search/findByTypeAndTitle`, {
            params: {
                type: 'Primary',
                title: 'Trimble Connect'
            }
        });
    }

    activateForProject(appStoreItem: AppStoreItem, data: AppActivationData) {
        const url = `/api/v1/${this.resourcePath}/${RestUtils.getIdFromSelfLink(appStoreItem._links.self)}/activate-for`;

        return this.httpClient.post<void>(url, data).pipe();
    }

    countUsage(appStoreItemId: number): Observable<number> {
        return this.httpClient.get<number>(`/api/v1/${this.resourcePath}/search/countUsage`, {
            params: {
                appStoreItem: `${appStoreItemId}`
            }
        });
    }

    getTeamsUrl(teamId: string): Observable<string|null> {
        return this.httpClient.get(`/api/v1/${this.resourcePath}/teams/url`, {
            responseType: 'text',
            params: {
                teamId: `${teamId}`
            }
        }).pipe(
            map(base64Encoded => base64Encoded !== null && base64Encoded.length ? atob(base64Encoded) : null)
        );
    }
}
