import {Inject, Injectable} from '@angular/core';
import {MsalAuthWrapperService} from './msal-auth-wrapper.service';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {filter, map, shareReplay, take} from 'rxjs/operators';
import {Role} from '../models/role';
import {NavisionAdministrationService} from './navision-administration.service';
import {PendoService} from '../integrations/pendo/pendo.service';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {InteractionStatus} from '@azure/msal-browser';
import {environment} from '../../environments/environment';
import {filterAccountsByAudience} from '../utils/msal';
import {Project} from '../models/project';
import {CompanyService} from './company.service';

export interface AuthorizationService {
    roles$: Observable<Role[]>;
    isSuperAdmin$: Observable<boolean>;
    isBedrijfsAdmin$: Observable<boolean>;
    canCreateProject$: Observable<boolean>;
    canUpdateDeleteProject$(project: Project): Observable<boolean>;
}

@Injectable({
    providedIn: 'root'
})
export class AuthorizationServiceImpl implements AuthorizationService {

    private rolesSubject = new BehaviorSubject<Role[]>(null);

    myCompanies$ = this.companyService.listMine();

    roles$: Observable<Role[]> = this.rolesSubject.asObservable().pipe(
        filter(value => value !== null),
        take(1)
    );
    isSuperAdmin$ = this.roles$.pipe(
        map(roles => roles.includes(Role.superAdmin)),
    );
    isBedrijfsAdmin$ = this.roles$.pipe(
        map(roles => roles.includes(Role.bedrijfsAdmin)),
    );
    canCreateProject$ = combineLatest([
        this.roles$,
        this.navisionAdministrationService.hasAdministrations(),
        this.isSuperAdmin$,
        this.isBedrijfsAdmin$
    ]).pipe(
        map(([roles, hasAdministrations, isSuperAdmin, isBedrijfsAdmin]) => {
            return roles.includes(Role.trimbleManager) || isSuperAdmin || isBedrijfsAdmin || hasAdministrations;
        }),
        shareReplay({bufferSize: 1, refCount: true})
    );

    constructor(
        @Inject('MsalAuthWrapperService') private  msalAuthWrapperService: MsalAuthWrapperService,
        @Inject('NavisionAdministrationService') private navisionAdministrationService: NavisionAdministrationService,
        @Inject('CompanyService') private companyService: CompanyService,
        private broadcastService: MsalBroadcastService,
        private msalService: MsalService,
        private pendoService: PendoService
    ) {
        this.broadcastService.inProgress$.pipe(
            filter(it => it === InteractionStatus.None),
            take(1)
        ).subscribe(it => {
            const account = filterAccountsByAudience(this.msalService.instance.getAllAccounts())[0]
            const idToken = account.idTokenClaims as any;

            this.rolesSubject.next(idToken?.roles || []);

            this.pendoService.initialize({
                id: idToken.upn,
                email: idToken.upn,
                name: idToken.name,
                app: environment.pendoAppName,
                roles: idToken.roles,
            });
        });
    }

    canUpdateDeleteProject$(project: Project): Observable<boolean> {
        return combineLatest([
            this.roles$,
            this.navisionAdministrationService.hasAdministrations(),
            this.isSuperAdmin$,
            this.myCompanies$,
            this.isBedrijfsAdmin$
        ]).pipe(
            map(([roles, hasAdministrations, isSuperAdmin, myCompanies, isBedrijfsAdmin]) => {
                return (
                        (roles.includes(Role.trimbleManager) || hasAdministrations || isBedrijfsAdmin) &&
                        (myCompanies.some(myCompany => myCompany.kvwNumber === project.kvwNumber))
                    ) ||
                    isSuperAdmin;
            }),
            shareReplay({bufferSize: 1, refCount: true})
        );
    }
}
