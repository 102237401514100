export enum AppStoreItemStatus {
    Concept = 'Concept',
    Active = 'Active',
    Archived = 'Archived'
}

export const appStoreItemStatusTranslation = {
    Concept: 'Concept',
    Active: 'Actief',
    Archived: 'Gearchiveerd'
};
