import {Component, Inject, OnDestroy} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Project} from '../../models/project';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {lastValueFrom, Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {ProjectAppStoreItemService} from '../../services/project-app-store-item.service';
import {NavisionAdministration} from '../../models/navision-administration';
import {AppStoreItem} from '../../models/app-store';
import {ProjectAppStoreItem} from '../../models/application';

export interface ApplicationNavModalResult {
    project: Project;
    navAdministration: NavisionAdministration;
    existingApplication: 'true' | 'false';
    applicationNumber?: string;
}

interface FormResult {
    navAdministration: NavisionAdministration;
    existingApplication: 'true' | 'false';
    applicationNumber: string;
}

@Component({
    selector: 'app-application-nav-modal',
    templateUrl: './application-nav-modal.component.html'
})
export class ApplicationNavModalComponent implements OnDestroy {
    form: UntypedFormGroup = new UntypedFormGroup({
        navAdministration: new UntypedFormControl(null, Validators.required),
        existingApplication: new UntypedFormControl('false'),
        applicationNumber: new UntypedFormControl(null, Validators.required)
    });

    project: Project;
    subscriptions: Subscription[] = [];
    submitPending = false;
    app: AppStoreItem;

    constructor(
        @Inject('ProjectAppStoreItemService') private projectAppService: ProjectAppStoreItemService,
        private toastr: ToastrService,
        public modalRef: VwuiModalRef,
        modalParams: VwuiModalConfig<{ project: Project, app: AppStoreItem }>
    ) {
        this.project = modalParams.data.project;
        this.app = modalParams.data.app;

        this.syncProjectCodeToApplicationNumber();
        this.subscriptions.push(
            this.form.get('existingApplication').valueChanges.subscribe(existingApplication => {
                if (existingApplication === 'true') {
                    if (this.form.get('applicationNumber').value === this.project.code) {
                        this.form.get('applicationNumber').setValue('');
                    }
                } else {
                    this.syncProjectCodeToApplicationNumber();
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    async submit() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            throw new Error('Couldn\'t save form, it\'s invalid');
        } else if (this.submitPending) {
            throw new Error('Couldn\'t save form, it\'s pending a submission');
        }

        const formValue = this.form.value as FormResult;
        try {
            this.submitPending = true;
            await lastValueFrom<ProjectAppStoreItem>(this.projectAppService.create({
                type: 'nav',
                navAdministration: formValue.navAdministration.administration,
                applicationNumber: formValue.applicationNumber,
                existingApplication: formValue.existingApplication === 'true',
                status: (formValue.existingApplication === 'true') ? 'COMPLETED' : 'CREATED',
                project: this.project._links.self.href,
                appStoreItem: this.app._links.self.href
            }));

            this.modalRef.close(true);
        } catch (error) {
            let errorMessage = 'Opslaan mislukt.';
            if (error instanceof HttpErrorResponse) {
                if (error.status === 400) {
                    if (error.error.errors && error.error.errors[0].message === 'Already in use') {
                        errorMessage = 'Opslaan mislukt, de combinatie administratie + projectnummer moet uniek zijn.';
                    } else if (error.error.message) {
                        errorMessage = `Opslaan mislukt, ${error.error.message}`;
                    }
                } else if (error.status === 504) {
                    errorMessage = 'Opslaan mislukt, kon geen verbinding maken met de ESB.';
                }
            }

            console.error('Error occurred while saving application', error);
            this.toastr.error(errorMessage);
        } finally {
            this.submitPending = false;
        }
    }

    private syncProjectCodeToApplicationNumber() {
        this.form.get('applicationNumber').setValue(this.project.code);
    }
}
