<vwui-header>
    Projecten
    <vwui-button appShowIfCanCreateProjects slot="action" class="is-primary"
                 [disabled]="modalOpen"
                 (click)="openCreateProjectModal()">Nieuw project
    </vwui-button>
</vwui-header>

<app-recent-projects-list (projectClick)="onProjectClick($event)"></app-recent-projects-list>

<vwui-header-divider-bar>
    <vwui-search
        [formControl]="searchControl"
    ></vwui-search>

    <div slot="right" class="d-flex align-items-center pl-2">
        <app-project-list-filter [facets$]="facets$" [formControl]="filterControl"
                                 [defaultFiltersApplied$]="defaultFiltersApplied$"></app-project-list-filter>
    </div>
</vwui-header-divider-bar>

<div *ngIf="projectPaginator$ | async as paginator">
    <ng-container *ngIf="paginator.content$ | async as content">
        <div
            infinite-scroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            (scrolled)="paginator.loadMore()"
        >
            <vwui-grid class="grid" id="project-list-grid" [fillItems]="true" *ngIf="selectedContentViewMode === 'grid'">
                <h3 slot="grid-title">Projecten</h3>

                <div slot="grid-pagination" class="d-flex align-items-center">
                    <vwui-btn-option-group (vwuiOptionsSelect)="setContentViewMode($any($event.detail))" [value]="selectedContentViewMode">
                        <vwui-btn-option icon="vwui-grid" value="grid"></vwui-btn-option>
                        <vwui-btn-option icon="vwui-table-normal" value="table"></vwui-btn-option>
                    </vwui-btn-option-group>
                </div>

                <div *ngFor="let item of content" class="projects-tile">
                    <vwui-grid-image
                        appBlobImage
                        [image]="item.image"
                        imageSize="medium"
                        [imageTitle]="item.name"
                        [imageExtraSubtitle]="item.commercialName"
                        [imageSubtitle]="item.projectLabel?.name || 'Productie'"
                        [style.--label-color]="item.projectLabel?.color || noLabelColor"
                        (click)="onProjectClick(item)">
                    </vwui-grid-image>
                    <vwui-menu class="projects-tile__menu">
                        <vwui-menu-option *ngIf="!item.hidden" (click)="updateProjectActive(item,false)">
                            <span class="d-inline-flex align-items-center">
                                <vwui-icon class="project-archive-icon" iconName="eye-off"></vwui-icon>
                                Verbergen
                            </span>
                        </vwui-menu-option>
                        <vwui-menu-option *ngIf="item.hidden" (click)="updateProjectActive(item,true)">
                            <span class="d-inline-flex align-items-center">
                                <vwui-icon class="project-archive-icon" iconName="eye"></vwui-icon>
                                Zichtbaar maken
                            </span>
                        </vwui-menu-option>
                        <vwui-menu-option appShowIfCanUpdateProject [project]="item"
                                          (click)="toggleLabelSelector(item); $event.stopPropagation()">
                        <span class="d-flex align-items-center">
                                                        <vwui-icon class="project-archive-icon"
                                                                   iconName="dot"
                                                                   [style.color]="item.projectLabel?.color || 'transparent'"></vwui-icon>
                            {{item.projectLabel?.name || 'Kies een label'}}
                            <vwui-icon
                                class="project-archive-icon ml-5"
                                iconName="vwui-chevron-right"
                                [class.rot-r]="labelSelectorOpen"
                                [class.rot-l]="!labelSelectorOpen"
                                [class.solid-bottom-border]="labelSelectorOpen">
                            </vwui-icon>
                        </span>

                        </vwui-menu-option>
                        <ng-container *ngIf="labelSelectorOpen">
                            <div class="border-top pt-2">
                                <vwui-menu-option
                                    (click)="saveProjectLabel(item, null)">
                                    <span class="d-inline-flex align-items-center">
                                        <vwui-icon class="project-archive-icon text-vwui-success"
                                                   iconName="dot"></vwui-icon>
                                        <span>Productie</span>
                                    </span>
                                </vwui-menu-option>
                            </div>

                            <ng-container *ngIf="projectLabels$ | async as labels">
                                <vwui-menu-option *ngFor="let label of labels"
                                                  (click)="saveProjectLabel(item, label)">
                                    <span class="d-inline-flex align-items-center">
                                        <vwui-icon class="project-archive-icon" iconName="dot"
                                                   [style.color]="label.color"></vwui-icon>
                                        <span>{{label.name}}</span>
                                    </span>
                                </vwui-menu-option>
                            </ng-container>
                        </ng-container>
                    </vwui-menu>
                </div>
            </vwui-grid>

            <vwui-table breakpoint="lg" *ngIf="selectedContentViewMode === 'table'" [compactable]="false">
                <vwui-btn-option-group slot="table-pagination" (vwuiOptionsSelect)="setContentViewMode($any($event.detail))"
                                       [value]="selectedContentViewMode">
                    <vwui-btn-option icon="vwui-grid" value="grid"></vwui-btn-option>
                    <vwui-btn-option icon="vwui-table-normal" value="table"></vwui-btn-option>
                </vwui-btn-option-group>

                <vwui-table-row [hasMenu]="true">
                    <vwui-table-header class="project-list-table-column project-list-table-header"></vwui-table-header>
                    <app-sorting-table-header class="project-list-table-header" sortKey="id" (sortChange)="changeSort($event)">Project-ID</app-sorting-table-header>
                    <app-sorting-table-header  class="project-list-table-header" sortKey="name" (sortChange)="changeSort($event)">Projectnaam</app-sorting-table-header>
                    <vwui-table-header class="project-list-table-column project-list-table-header">Werksoort</vwui-table-header>
                    <app-sorting-table-header  class="project-list-table-header" sortKey="city" (sortChange)="changeSort($event)">Plaats</app-sorting-table-header>
                    <app-sorting-table-header  class="project-list-table-header" sortKey="companyName" (sortChange)="changeSort($event)">Onderneming</app-sorting-table-header>
                    <app-sorting-table-header  class="project-list-table-header" sortKey="projectLabel" (sortChange)="changeSort($event)">Label</app-sorting-table-header>
                </vwui-table-row>

                <vwui-table-row *ngFor="let item of content" (click)="onProjectClick(item)">
                    <vwui-table-column class="project-list-table-left-column project-list-table-column">
                        <img *ngIf="item.image" class="project-thumbnail" alt="vw-logo" appBlobImage [image]="item.image"
                             imageSize="thumbnail" fallbackSrc="./assets/images/vwicon.svg">
                        <img *ngIf="item.image === null" class="project-thumbnail" alt="vw-logo" src="./assets/images/vwicon.svg">
                    </vwui-table-column>
                    <vwui-table-column class="project-list-table-column">{{ item.code }}</vwui-table-column>
                    <vwui-table-column class="project-list-table-column">
                        {{ item.name }}<br>
                        <small class="faded">{{ item.commercialName }}</small>
                    </vwui-table-column>
                    <vwui-table-column class="project-list-table-column">{{ item.typeGUID | projectTypePipe | async }}</vwui-table-column>
                    <vwui-table-column class="project-list-table-column">{{ item.city }}</vwui-table-column>
                    <vwui-table-column class="project-list-table-column">{{ item.kvwNumber ? (item.kvwNumber + ' - ' + item.companyName) : item.companyName }}</vwui-table-column>
                    <vwui-table-column class="project-list-table-column">
                        <div class="d-flex">
                            <vwui-tooltip position="top" class="d-inline-flex"
                                          [label]="item.projectLabel ? item.projectLabel.name : 'Productie'">
                                <vwui-icon iconName="dot" class="small-icon"
                                           [style.color]="item.projectLabel?.color || noLabelColor"></vwui-icon>
                            </vwui-tooltip>
                        </div>
                    </vwui-table-column>
                    <vwui-table-column class="project-list-table-column">
                        <vwui-menu>
                            <vwui-menu-option *ngIf="!item.hidden" (click)="updateProjectActive(item, false)">
                        <span class="d-inline-flex align-items-center">
                            <vwui-icon class="project-archive-icon" iconName="eye-off"></vwui-icon>
                            Verbergen
                        </span>
                            </vwui-menu-option>
                            <vwui-menu-option *ngIf="item.hidden" (click)="updateProjectActive(item,true)">
                        <span class="d-inline-flex align-items-center">
                            <vwui-icon class="project-archive-icon" iconName="eye"></vwui-icon>
                            Zichtbaar maken
                        </span>
                            </vwui-menu-option>
                            <vwui-menu-option appShowIfCanUpdateProject [project]="item"
                                              (click)="toggleLabelSelector(item); $event.stopPropagation()">
                        <span class="d-flex align-items-center">
                            <vwui-icon class="project-archive-icon" iconName="dot"
                                       [style.color]="item.projectLabel?.color || 'transparent'"></vwui-icon>
                            {{item.projectLabel?.name || 'Kies een label'}}
                            <vwui-icon
                                class="project-archive-icon ml-5"
                                iconName="vwui-chevron-right"
                                [class.rot-r]="labelSelectorOpen"
                                [class.rot-l]="!labelSelectorOpen"
                                [class.solid-bottom-border]="labelSelectorOpen">
                            </vwui-icon>
                        </span>

                            </vwui-menu-option>
                            <ng-container *ngIf="labelSelectorOpen">
                                <div class="border-top pt-2">
                                    <vwui-menu-option
                                        (click)="saveProjectLabel(item, null)">
                                    <span class="d-inline-flex align-items-center">
                                        <vwui-icon class="project-archive-icon text-vwui-success"
                                                   iconName="dot"></vwui-icon>
                                        <span>Productie</span>
                                    </span>
                                    </vwui-menu-option>
                                </div>

                                <ng-container *ngIf="projectLabels$ | async as labels">
                                    <vwui-menu-option *ngFor="let label of labels"
                                                      (click)="saveProjectLabel(item, label)">
                                    <span class="d-inline-flex align-items-center">
                                        <vwui-icon class="project-archive-icon" iconName="dot"
                                                   [style.color]="label.color"></vwui-icon>
                                        <span>{{label.name}}</span>
                                    </span>
                                    </vwui-menu-option>
                                </ng-container>
                            </ng-container>
                        </vwui-menu>
                    </vwui-table-column>
                </vwui-table-row>
            </vwui-table>
        </div>
    </ng-container>
</div>

<vwui-modal *ngIf="notifications$|async as notifications" [visible]="notifications.length">
    <vwui-modal-header>Berichten</vwui-modal-header>
    <vwui-modal-body>
        <vwui-card *ngFor="let notification of notifications">
            <vwui-card-body class="d-flex flex-j-sb">
                {{notification.message}}
                <vwui-button (click)="acknowledgeNotification(notification.id)">Gelezen</vwui-button>
            </vwui-card-body>
        </vwui-card>
    </vwui-modal-body>
</vwui-modal>
