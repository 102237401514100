<vwui-root>
    <vwui-nav>
        <vwui-logo slot="logo" logoSrc="/assets/images/vwlogo.svg"></vwui-logo>

        <vwui-nav-item
            href="projects"
            routerLink="projects"
            routerLinkActive="is-active"
            icon="projects"
        >Projecten</vwui-nav-item>

        <vwui-nav-item
            *ngIf="true"
            href="dashboard"
            routerLink="dashboard"
            routerLinkActive="is-active"
            icon="vwui-dashboard"
        >Dashboard</vwui-nav-item>

        <div appShowIfSuperAdmin>
            <vwui-nav-header>Beheer</vwui-nav-header>
            <vwui-nav-item
                href="app-store"
                routerLink="app-store"
                routerLinkActive="is-active"
                icon="vwui-grid"
            >App Beheer</vwui-nav-item>
            <vwui-nav-item
                href="project-labels"
                routerLink="project-labels"
                routerLinkActive="is-active"
                icon="dot"
            >Projectlabels</vwui-nav-item>
        </div>

        <div slot="nav-footer">
            <div class="footer-version" (click)="showKvwNumbers()">
                Versie {{ version }}
            </div>
        </div>
    </vwui-nav>
    <vwui-main>
        <router-outlet></router-outlet>
    </vwui-main>
</vwui-root>
