<ng-container *ngIf="project$ | async as project">
    <ng-container *ngIf="logs$ | async as logs">
        <div
            infinite-scroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            (scrolled)="logs.loadMore()"
        >
            <ng-container *ngIf="logs.content$ | async as content">
                <vwui-table
                    tableTitle="Geschiedenis"
                    compactable="false"
                    breakpoint="lg">
                    <vwui-table-row role="row">
                        <vwui-table-header>Wie?</vwui-table-header>
                        <vwui-table-header>Wanneer?</vwui-table-header>
                        <vwui-table-header>Wat is gewijzigd</vwui-table-header>
                        <vwui-table-header>Oude waarde</vwui-table-header>
                        <vwui-table-header>></vwui-table-header>
                        <vwui-table-header>Nieuwe waarde</vwui-table-header>
                    </vwui-table-row>

                    <ng-container *ngFor="let log of content">
                        <vwui-table-row role="row" *ngFor="let changes of log.changedProperties; let first = first">
                            <vwui-table-column>{{ first ? log.username : ''}}</vwui-table-column>
                            <vwui-table-column>{{ first ? dateFormat(log.revisionDate) : ''}}</vwui-table-column>
                            <vwui-table-column>
                                <p>
                                    {{translationFieldName(changes.propertyName)}}
                                </p>
                            </vwui-table-column>
                            <vwui-table-column>
                                <p>
                                    {{propertyValueTranslation(changes.oldValue, changes.propertyName)}}
                                </p>
                            </vwui-table-column>
                            <vwui-table-column>
                                <p>></p>
                            </vwui-table-column>
                            <vwui-table-column>
                                <p>
                                    {{propertyValueTranslation(changes.newValue, changes.propertyName)}}
                                </p>
                            </vwui-table-column>
                        </vwui-table-row>

                    </ng-container>
                </vwui-table>

            </ng-container>
        </div>
    </ng-container>
</ng-container>

<ng-template #emptyList>
</ng-template>
