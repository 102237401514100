<vwui-button icon="filter" (click)="overlayOpen = true">
    <span class="whitespace-nowrap block">Filteren</span>
    <vwui-badge small variant="primary" *ngIf="numFilters$|async as numFilters">{{numFilters}}</vwui-badge>
</vwui-button>
<div class="position-fixed inset-y-0 bottom-0 right-0 w-24r mw-100 bg-white shadow z-10 d-flex flex-column"
     [formGroup]="form"
     [class.invisible]="!overlayOpen"
     [class.translate-x-full]="!overlayOpen">
    <div class="pt-nav"></div>
    <div class="d-flex items-center mb-1 flex-none pt-3 px-3">
        <h2 class="font-medium text-xl m-0">Filters</h2>
        <vwui-btn-icon class="ml-auto" icon="vwui-close" (click)="overlayOpen = false"></vwui-btn-icon>
    </div>
    <vwui-button class="flex-none px-3" (click)="resetFilters()">Filters resetten</vwui-button>

    <div class="overflow-y-scroll px-3 pb-3 mt-1">
        <div *ngIf="facets$|async as facets">
            <ng-container *ngFor="let collection of facets">
                <ng-container *ngFor="let facet of collection.items; last as isLast">
                    <vwui-spacer-group spacing="xxs">
                        <app-search-filter-group-simple
                            class="block py-1 border-bottom"
                            [class.border-b]="!isLast"
                            [group]="$any(facet)"
                            [formControlName]="facet.field"
                        ></app-search-filter-group-simple>
                    </vwui-spacer-group>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
