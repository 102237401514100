<app-azure-map class="project-location-form-group__map"
               [coordinates]="initialLocation ?? projectCoordinates"
               [projectId]="project?.id"
               [disabled]="form.disabled"
               (clickedAddress)="onClickedAddress($event)">
</app-azure-map>
<vwui-search *ngIf="form.enabled" appShowIfCanUpdateProject [project]="project" class="project-location__search"
             (vwuiChange)="search($event.detail.value)"
             (keydown.enter)="$event.preventDefault()"
             placeholder="Locatie zoeken"
             [required]="true">
</vwui-search>
<ng-container *ngIf="showLocationForm" [formGroup]="form">
    <div class="project-location-detail">
        <div class="project-location-detail__header">Locatie</div>
        <div class="project-location-detail__body">
            <vwui-form-group inputId="latitude"
                             [class.no-spacing]="(canUpdateDeleteProject$|async) === false">
                <vwui-input [readonly]="true" inputId="latitude" placeholder="Coördinaten" [disabled]="true"
                            [value]="formatCoordinates()"></vwui-input>
            </vwui-form-group>
            <vwui-form-group inputId="street">
                <vwui-input appReadonlyIfCannotManageProject [project]="project" inputId="street" placeholder="Straatnaam"
                            formControlName="street"></vwui-input>
            </vwui-form-group>
            <div class="project-location-detail__body__address-inline">
                <div class="project-location-detail__body__house-number">
                    <vwui-form-group inputId="houseNumber">
                        <vwui-input appReadonlyIfCannotManageProject [project]="project" inputId="houseNumber" placeholder="Huisnummer"
                                    formControlName="houseNumber"></vwui-input>
                    </vwui-form-group>
                </div>
                <vwui-form-group inputId="postCode" class="required-without-label">
                    <vwui-input appReadonlyIfCannotManageProject [project]="project" [required]="true" inputId="postCode"
                                placeholder="Postcode" formControlName="postCode"></vwui-input>
                </vwui-form-group>
            </div>
            <vwui-form-group inputId="city" class="required-without-label">
                <vwui-input appReadonlyIfCannotManageProject [project]="project" [required]="true" inputId="city" placeholder="Plaats"
                            formControlName="city"></vwui-input>
            </vwui-form-group>
            <div appShowIfCanUpdateProject [project]="project" class="project-location-detail__actions">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="azureSearchResponse$ | async as fuzzySearchResponse">
    <div *ngIf="searchString$.value" class="project-location__search-results">
        <div class="project-location__search-results__item" *ngFor="let result of fuzzySearchResponse.results"
             (click)="selectSearchAddress(result)">
            <ng-container *ngIf="formatSearchResult(result) as formatted">
                <div class="project-location__search-results__item__name"
                     [innerHTML]="formatted.name | searchHighlight:(searchString$|async)"></div>
                <div class="project-location__search-results__item__location">{{ formatted.location }}</div>
            </ng-container>
        </div>
        <div *ngIf="fuzzySearchResponse.results.length < 1">Geen resultaten gevonden.</div>
    </div>
</ng-container>

