import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {Project} from '../../models/project';
import {ProjectService} from '../../services/project.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-recent-projects-list',
    templateUrl: './recent-projects-list.component.html'
})
export class RecentProjectsListComponent {
    @Output() projectClick = new EventEmitter<Project>();

    projectAccessList$ = this.projectService.findRecent({size: '4'}).pipe(
        map(response => response.content)
    );

    constructor(
        @Inject('ProjectService') private projectService: ProjectService
    ) {}
}
