import {Observable, of} from 'rxjs';
import {BouwpasContractor} from 'src/app/models/bouwpas-contractor';
import {BouwpasTemplate} from 'src/app/models/bouwpas-template';
import {BouwpasService} from '../bouwpas.service';

export class BouwpasServiceMock implements BouwpasService {

    getTemplates(): Observable<BouwpasTemplate[]> {
        return of([
                {
                    shortDescription: 'Mock template',
                    projectId: 1
                }
            ]
        )
    }

    getContractorsByKvw(kvwNumbers: string[]): Observable<BouwpasContractor[]> {
        return of([
                {
                    contractorName: 'Romme Knol',
                    isBouwCombi: 'true',
                    contractorId: 1,
                    kvwNumber: 555
                }
            ]
        )
    }
}
