import {Pipe, PipeTransform} from '@angular/core';
import {ProjectAppStoreItem} from '../models/application';
import {appTypeTranslation, secondaryTypeTranslation} from '../models/app-store';

@Pipe({
    name: 'projectAppStoreItemType'
})
export class ProjectAppStoreItemTypePipe implements PipeTransform {
    transform(app: ProjectAppStoreItem): string {
        if (!app) {
            return '';
        }

        return (app as ProjectAppStoreItem).appType === 'Secondary'
            ? secondaryTypeTranslation[app.secondaryType]
            : appTypeTranslation[(app as ProjectAppStoreItem).appType]
    }
}
