import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ProjectSightPortfolio} from '../models/project-sight.portfolio';
import {ProjectAppStoreItem} from '../models/application';

export interface ProjectSightService {
    getPortfolios(projectId: number): Observable<ProjectSightPortfolio[]>;
}

@Injectable()
export class ProjectSightServiceImpl implements ProjectSightService {

    public constructor(private http: HttpClient) {
    }

    getPortfolios(projectId: number): Observable<ProjectSightPortfolio[]> {
        const params = new HttpParams({fromObject: {project: projectId.toString()}});

        return this.http.get<ProjectSightPortfolio[]>(`/api/v1/project-sight/portfolios`, {params});
    }
}
