import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Project} from '../../models/project';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectService} from '../../services/project.service';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, lastValueFrom, merge, Subscription} from 'rxjs';
import {MDMService} from '../../services/mdm.service';
import {filter, map, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-project-features-tab',
  templateUrl: './project-features-tab.component.html'
})
export class ProjectFeaturesTabComponent implements OnChanges, OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    @Input() project: Project;
    @Input() readonly = false
    @Output() projectUpdated = new EventEmitter<void>();

    form = new UntypedFormGroup({
        typeGUID: new UntypedFormControl([], [Validators.required]),
        buildingClassificationGUID: new UntypedFormControl([]),
        buildingSubClassificationGUID: new UntypedFormControl([]),
        vwConceptGUID: new UntypedFormControl([]),
        sustainabilityClassGUID: new UntypedFormControl([])
    });
    submitPending = false;

    types$ = this.mdmService.getWorkTypesOptions()
    usageFunctions$ = this.mdmService.getUsageFunctionsOptions()
    vwConcepts$ = this.mdmService.getVolkerWesselsConceptsOptions()
    sustainabilityRatings$ = this.mdmService.getSustainabilityRatingsOptions()
    initialBuildingClassificationSubject = new BehaviorSubject<string[]>([]);

    availableSubClassifications$ = merge(
        this.initialBuildingClassificationSubject.asObservable(),
        this.form.get('buildingClassificationGUID').valueChanges
    ).pipe(
        filter(it => it),
        switchMap((classification) => this.mdmService.getSubUsageFunctionsOptions(classification))
    );

    constructor(
        @Inject('MDMService') private mdmService: MDMService,
        @Inject('ProjectService') private projectService: ProjectService,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.project && changes.project.currentValue) {
            this.form.patchValue(changes.project.currentValue);
            this.initialBuildingClassificationSubject.next(changes.project.currentValue.buildingClassificationGUID)
        }
    }

    ngOnInit() {
        if (this.readonly) {
            this.form.disable();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }

    async saveAndContinue() {
        try {
            await this.save();
            await this.router.navigate([], {relativeTo: this.route, queryParams: {tab: 'applications'}});
        } catch (e) {
            console.error('saveAndContinue failed', e);
        }
    }

    async save() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            throw new Error('Couldn\'t save form, it\'s invalid');
        } else if (this.submitPending) {
            throw new Error('Couldn\'t save form, it\'s pending a submission');
        }

        const {projectLabel, ...projectData} = this.project;

        try {
            this.submitPending = true;
            this.project = await lastValueFrom<Project>(this.projectService.patch({
                ...projectData,
                ...this.form.value
            }));
            this.form.patchValue(this.project);
            this.toastr.success('Opgeslagen');
            this.projectUpdated.emit();
        } catch (error) {
            console.error('Unable to save project', error);
            this.toastr.error('Opslaan mislukt.');
            throw error;
        } finally {
            this.submitPending = false;
        }
    }
}
