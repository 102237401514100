<vwui-modal-header>
    Disclaimer
</vwui-modal-header>
<vwui-modal-body>
    <strong>Wanneer een onderneming via het ProjectenPortaal een project heeft aangemaakt, dient men direct te controleren of de rechten, groepen
        en instellingen goed zijn ingesteld.<br> Met het aanmaken van het project zijn de ondernemingen per direct zelf verantwoordelijk
        voor het project en haar instellingen tijdens de gehele looptijd van een project.</strong>
    <br>
    <br>
    Is een project niet volgens onderneming standaard aangemaakt of dient een wijziging in de standaard te worden doorgevoerd, meld dit dan
    aan de BVGO Servicedesk via <a href="mailto:bvgoservicedesk@volkerwessels.com">bvgoservicedesk@volkerwessels.com.</a>
</vwui-modal-body>
<vwui-modal-footer>
    <vwui-button class="is-secondary" (click)="modalRef.close()">Accepteer</vwui-button>
</vwui-modal-footer>
