<app-project-location-form-group [formGroup]="form" [project]="_project" [readonly$]="readonly$">
    <ng-container *ngIf="(readonly$ | async) === false">
        <vwui-button
            [block]="true"
            label="save"
            type="submit"
            class="is-secondary"
            (click)="save()"
            [disabled]="!form.valid || submitPending"
        >
            Opslaan
        </vwui-button>
        <vwui-button
            [block]="true"
            label="saveAndContinue"
            type="submit"
            class="is-secondary"
            (click)="saveAndContinue()"
            [disabled]="!form.valid || submitPending"
            [loading]="submitPending"
        >
            Opslaan en verder
        </vwui-button>
    </ng-container>
</app-project-location-form-group>
