import {Injectable} from '@angular/core';
import {AbstractRestServiceMock} from './abstract-rest.service.mock';
import {Client} from '../../models/client';
import {ClientService} from '../client.service';

export const mockClients: Client[] = [
    {
        name: 'Digibase B.V.',
        postCode: '9999AA',
        houseNumber: '1',
        street: 'Plein',
        city: 'Nieuwegein',
        kvkNumber: '32423',
        clientNumber: 23,
        _links: {
            self: { href: '/api/v1/clients/1' }
        }
    },
    {
        name: 'Recognize',
        postCode: '6666BB',
        houseNumber: '54',
        street: 'Straat',
        city: 'Almelo',
        kvkNumber: '999111',
        clientNumber: 666,
        _links: {
            self: { href: '/api/v1/clients/2' }
        }
    }
];

@Injectable()
export class ClientServiceMock extends AbstractRestServiceMock<Client> implements ClientService {
    constructor() {
        super(mockClients);
    }
}
