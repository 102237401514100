import {Injectable} from '@angular/core';
import {MsalAuthWrapperService} from '../msal-auth-wrapper.service';
import {AccountInfo} from '@azure/msal-common';

export const mockMsalUser: AccountInfo = {
    homeAccountId: '',
    environment: null,
    tenantId: '',
    username: 'j.hobert@recognize.nl',
    localAccountId: '',
    name: 'Julius de Heerser',
    idTokenClaims: null
};

export const idToken: string =
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJST0xFX0FETUlOIl0sImp0aSI6IjAzN2Y5NmI1LT' +
    'NkNGItNDdmZC04ZTUwLTg5MzQ3YTkwMmFkMSIsImlhdCI6MTU4MzQ4OTYwNywiZXhwIjoxNTgzNDkzMjA3fQ.Y39lfg' +
    'qu62CgW9XTTH9GZ-sCz2GaGvTG2piFUjxWAwM';

@Injectable()
export class MsalAuthWrapperServiceMock implements MsalAuthWrapperService {
    data = mockMsalUser;

    constructor() {
    }

    getAccount(): AccountInfo {
        return this.data;
    }
}
