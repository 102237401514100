import {AbstractRestService, AbstractRestServiceInterface} from './abstract-rest.service';
import {Client} from '../models/client';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

export interface ClientService extends AbstractRestServiceInterface<Client> {
}

@Injectable()
export class ClientServiceImpl extends AbstractRestService<Client> implements ClientService {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, 'clients');
    }
}
