export const environment = {
    version: process.env.NG_APP_VERSION,
    pendoAppName: 'projectenportaal_prod',
    production: true,
    hmr: false,
    azureMapsKey: '3H0KeDu_AXunqi9q1_pQ5q75CyPia81wxrVjKAVYwMc',
    appInsightsKey: null,
    useMock: false,
    authClientId: '15fd4c7a-18ce-4c72-a702-cdbd08d18d81',
    authRedirectUri: 'https://projectenportaal.volkerwessels.app/oauth2/authorize',
    tenantId: '0ef544fb-3d5c-4c60-932e-735bcef00405', // BVGO
    bouwpasUrl: 'https://portal.bouwpas.nl/',
    prmUrl: 'https://prm.volkerwessels.app/project/',
    projectSightUrl: 'https://prodeu.projectsightapp.trimble.com/Web/dashboard.aspx',
};
