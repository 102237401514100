import {Inject, Pipe, PipeTransform} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {MDMService} from '../services/mdm.service';

@Pipe({
    name: 'projectTypePipe'
})
export class ProjectTypePipe implements PipeTransform {
    constructor(@Inject('MDMService') private mdmService: MDMService) {
    }

    async transform(type: string[]|string): Promise<string> {
        if (!type) {
            return '';
        }

        const projectTypes = await lastValueFrom(this.mdmService.getWorkTypesOptions())
        const translateProjectType = (key: string) => {
            return projectTypes.find(it => it.key === key).value ?? key;
        }

        if (Array.isArray(type)) {
            return type
                .map((t) => translateProjectType(t))
                .sort((a, b) => a.localeCompare(b))
                .join(', ');
        } else {
            return translateProjectType(type);
        }
    }
}
