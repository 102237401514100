import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {NAVApplication} from '../models/application';
import {RestUtils} from '../utils/rest-utils';
import {Observable} from 'rxjs';

export interface NavisionApplicationService {
    remove(app: NAVApplication): Observable<void>;
}

@Injectable()
export class NavisionApplicationServiceImpl implements NavisionApplicationService {
    constructor(protected httpClient: HttpClient) {
    }

    remove(app: NAVApplication) {
        return this.httpClient.delete<void>(`/api/v1/migrations/applications/navision/${RestUtils.getIdFromSelfLink(app._links.self)}`);
    }
}
